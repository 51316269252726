import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import IgbModelSearchRequest from "../IgbModelSearchRequest";
import ModelProxyClass from "./ModelProxyClass";
import ResultParameter from "../ResultParameter";


/**
 * @class IgbModelSearchRequestBase
 * @extends ModelBaseClass
 * @property {string} smartFilter
 * @property {ResultParameter} resultParameter [ModelCode, Name]
 */
class IgbModelSearchRequestBase extends ModelBaseClass {
	/**
	 * @type {string} smartFilter
	 */
	smartFilter;
	/**
	 * [ModelCode, Name]
	 * @type {ResultParameter} resultParameter
	 */
	resultParameter;

	/**
	 * Instantiates a new instance of IgbModelSearchRequest based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {IgbModelSearchRequest}
	 */
	static create(genericObject) {
		const newIgbModelSearchRequest = new IgbModelSearchRequest();
		newIgbModelSearchRequest.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newIgbModelSearchRequest;
	}

	/**
	 * Instantiates a new array of IgbModelSearchRequest based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[IgbModelSearchRequest]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newIgbModelSearchRequestArray = [];
		genericArray.forEach(genericObject => {
			newIgbModelSearchRequestArray.push(IgbModelSearchRequest.create(genericObject));
		});
		return newIgbModelSearchRequestArray;
	}
}

/**
 * @type {string} OrderByModelCode
 */
IgbModelSearchRequestBase.OrderByModelCode = 'modelcode';

/**
 * @type {string} OrderByName
 */
IgbModelSearchRequestBase.OrderByName = 'name';

const _modelDefinition = [
	ModelBaseClass.createModelProperty('smartFilter', 'string'),
	ModelBaseClass.createModelProperty('resultParameter', 'ResultParameter'),
];

export default IgbModelSearchRequestBase;
