import IgbModelSearchRequestBase from './base/IgbModelSearchRequestBase';

/**
 * @class IgbModelSearchRequest
 * @extends IgbModelSearchRequestBase
 */
class IgbModelSearchRequest extends IgbModelSearchRequestBase {

}

export default IgbModelSearchRequest;
