import { Listbox, ListboxProps, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { cva, cx } from 'class-variance-authority'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

const variants = cva(
	/* base style */
	'relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6',
	{
		variants: {
			invalid: {
				true: '!text-danger-900 !ring-danger-300 focus:!ring-danger-500'
			},
			disabled: {
				true: 'cursor-not-allowed'
			}
		},
		defaultVariants: {}
	}
)

const labelVariants = cva('block truncate', {
	variants: {
		invalid: {
			true: 'text-danger-900'
		},
		placeholder: {
			true: 'text-gray-400'
		}
	},
	compoundVariants: [
		{
			invalid: true,
			placeholder: true,
			className: '!text-danger-300'
		}
	]
})

/**
 * @typedef SelectProps
 * @property {string} [className]
 * @property {string} [label]
 * @property {any} [value]
 * @property {Array<{ value: any, label: string }>} [options]
 * @property {boolean} [disabled]
 * @property {boolean} [invalid]
 * @property {string} [errorMessage]
 * @property {string} [placeholder]
 * @property {(value: any) => void} [onChange]
 * @property {(e: React.FocusEvent<HTMLButtonElement>) => void} [onBlur]
 * @property {boolean} [enableClear] Add ability to clear the selected value
 */

/**
 * @param {SelectProps & ListboxProps} props
 */
const Select = ({
	className,
	label,
	value,
	options = [],
	disabled,
	invalid,
	errorMessage,
	placeholder = 'Please Select',
	onChange = () => {},
	onBlur = () => {},
	enableClear,
	...props
}) => {
	const currentOption = options?.find(o => o.value === value)
	const currentLabel = currentOption?.label || placeholder
	const hasLabel = !!label
	const hasError = invalid && errorMessage

	return (
		<div className={cx('w-full', className)}>
			<Listbox value={value} onChange={onChange} disabled={disabled} {...props}>
				{({ open }) => (
					<>
						{hasLabel && (
							<Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
								{label}
							</Listbox.Label>
						)}
						<div className={cx('relative', hasLabel && 'mt-2')}>
							<Listbox.Button
								className={variants({ disabled, invalid })}
								onBlur={e => !open && onBlur?.(e)}
							>
								<span
									className={labelVariants({
										invalid,
										placeholder: !currentOption
									})}
								>
									{currentLabel}
								</span>
								<span
									className={cx(
										'absolute inset-y-0 right-0 flex items-center pr-2',
										enableClear && currentOption ? 'cursor-pointer' : 'pointer-events-none'
									)}
								>
									{enableClear && currentOption ? (
										// clear button icon
										<XCircleIcon
											className="w-5 h-5 text-gray-400"
											aria-hidden="true"
											onClick={e => {
												e.preventDefault()
												onChange(null)
											}}
										/>
									) : (
										<ChevronUpDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
									)}
								</span>
							</Listbox.Button>

							<Transition
								show={open}
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
									{options.map((option, index) => {
										const { value: optionValue, label: optionLabel } = option
										return (
											<Listbox.Option
												key={index}
												className={({ active }) =>
													cx(
														active ? 'bg-primary-600 text-white' : 'text-gray-900',
														'relative cursor-default select-none py-2 pl-3 pr-9'
													)
												}
												value={optionValue}
											>
												{({ selected, active }) => (
													<>
														<span
															className={cx(
																selected ? 'font-semibold' : 'font-normal',
																'block truncate'
															)}
														>
															{optionLabel}
														</span>

														{selected ? (
															<span
																className={cx(
																	active ? 'text-white' : 'text-primary-600',
																	'absolute inset-y-0 right-0 flex items-center pr-4'
																)}
															>
																<CheckIcon className="w-5 h-5" aria-hidden="true" />
															</span>
														) : null}
													</>
												)}
											</Listbox.Option>
										)
									})}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>

			{hasError && <p className="mt-2 text-sm text-danger-600">{errorMessage}</p>}
		</div>
	)
}

Select.propTypes = {
	className: PropTypes.string,
	label: PropTypes.node,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any,
			label: PropTypes.string
		})
	),
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	invalid: PropTypes.bool,
	errorMessage: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	enableClear: PropTypes.bool
}

Select.defaultProps = {
	options: [],
	placeholder: 'Please select'
}

export default Select
