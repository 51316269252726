import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import RevenueReimbursementSearchRequest from "../RevenueReimbursementSearchRequest";
import ModelProxyClass from "./ModelProxyClass";
import ResultParameter from "../ResultParameter";


/**
 * @class RevenueReimbursementSearchRequestBase
 * @extends ModelBaseClass
 * @property {number} locationId (int64) required for Owners (ignored otherwise)
 * @property {number} tenantId (int64) required for Users (ignored otherwise)
 * @property {number} paymentId (int64) optional for Users
 * @property {['Requested'|'Declined'|'Approved'|'Paid']} filterByStatuses
 * @property {ResultParameter} resultParameter [DateRequested,Description,Amount,Status,DateEvaluated,DatePaid]
 */
class RevenueReimbursementSearchRequestBase extends ModelBaseClass {
	/**
	 * required for Owners (ignored otherwise)
	 * @type {number} locationId (int64)
	 */
	locationId;
	/**
	 * required for Users (ignored otherwise)
	 * @type {number} tenantId (int64)
	 */
	tenantId;
	/**
	 * optional for Users
	 * @type {number} paymentId (int64)
	 */
	paymentId;
	/**
	 * @type {['Requested'|'Declined'|'Approved'|'Paid']} filterByStatuses
	 */
	filterByStatuses;
	/**
	 * [DateRequested,Description,Amount,Status,DateEvaluated,DatePaid]
	 * @type {ResultParameter} resultParameter
	 */
	resultParameter;

	/**
	 * Instantiates a new instance of RevenueReimbursementSearchRequest based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {RevenueReimbursementSearchRequest}
	 */
	static create(genericObject) {
		const newRevenueReimbursementSearchRequest = new RevenueReimbursementSearchRequest();
		newRevenueReimbursementSearchRequest.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newRevenueReimbursementSearchRequest;
	}

	/**
	 * Instantiates a new array of RevenueReimbursementSearchRequest based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[RevenueReimbursementSearchRequest]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newRevenueReimbursementSearchRequestArray = [];
		genericArray.forEach(genericObject => {
			newRevenueReimbursementSearchRequestArray.push(RevenueReimbursementSearchRequest.create(genericObject));
		});
		return newRevenueReimbursementSearchRequestArray;
	}
}

/**
 * @type {string} OrderByDateRequested
 */
RevenueReimbursementSearchRequestBase.OrderByDateRequested = 'daterequested';

/**
 * @type {string} OrderByDescription
 */
RevenueReimbursementSearchRequestBase.OrderByDescription = 'description';

/**
 * @type {string} OrderByAmount
 */
RevenueReimbursementSearchRequestBase.OrderByAmount = 'amount';

/**
 * @type {string} OrderByStatus
 */
RevenueReimbursementSearchRequestBase.OrderByStatus = 'status';

/**
 * @type {string} OrderByDateEvaluated
 */
RevenueReimbursementSearchRequestBase.OrderByDateEvaluated = 'dateevaluated';

/**
 * @type {string} OrderByDatePaid
 */
RevenueReimbursementSearchRequestBase.OrderByDatePaid = 'datepaid';

const _modelDefinition = [
	ModelBaseClass.createModelProperty('locationId', 'integer'),
	ModelBaseClass.createModelProperty('tenantId', 'integer'),
	ModelBaseClass.createModelProperty('paymentId', 'integer'),
	ModelBaseClass.createModelProperty('filterByStatuses', '[string]'),
	ModelBaseClass.createModelProperty('resultParameter', 'ResultParameter'),
];

export default RevenueReimbursementSearchRequestBase;
