import './configs/dayjsSetup'
import './configs/yupLocale'
import './index.scss'

import ReactDOM from 'react-dom/client'
import { unstable_HistoryRouter as Router } from 'react-router-dom'
import history from 'shared/history'

import App from './App'
import CoreProvider from './contexts/core-context'
import GlobalProvider from './contexts/global-context'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Router history={history}>
		<GlobalProvider>
			<CoreProvider>
				<App />
			</CoreProvider>
		</GlobalProvider>
	</Router>,
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
