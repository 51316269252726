import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { cva, cx } from 'class-variance-authority'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

const panelVariants = cva(
	/* base style */
	'w-screen pointer-events-auto',
	{
		variants: {
			size: {
				md: 'max-w-md',
				lg: 'max-w-lg',
				xl: 'max-w-xl',
				'2xl': 'max-w-2xl',
				'3xl': 'max-w-3xl',
				'4xl': 'max-w-4xl',
				'5xl': 'max-w-5xl'
			}
		},
		defaultVariants: {
			size: 'md'
		}
	}
)

/**
 * @typedef SlideOverProps
 * @property {boolean} visible
 * @property {() => void} onClose
 * @property {string | React.ReactNode} title
 * @property {string | React.ReactNode} [subTitle]
 * @property {boolean} [clickOutsideToClose] close the modal when clicking outside of it
 * @property {'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl'} size default is 'md'
 */

/**
 * @param {SlideOverProps} props
 */
const SlideOver = ({
	visible,
	onClose = () => {},
	title,
	subTitle,
	clickOutsideToClose = false,
	size = 'md',
	children
}) => {
	const body = React.Children.toArray(children).filter(child => child.type === SlideOver.Body)
	const footer = React.Children.toArray(children).filter(child => child.type === SlideOver.Footer)

	return (
		<Transition.Root show={visible} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-50"
				static={!clickOutsideToClose}
				onClose={clickOutsideToClose ? onClose : () => {}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className={panelVariants({ size })}>
									<div className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
										<div className="flex flex-col flex-1 min-h-0 overflow-y-auto">
											<div className="px-4 py-6 bg-gray-100 sm:px-6">
												<div
													className={cx(
														subTitle ? 'items-start' : 'items-center',
														'flex justify-between space-x-3'
													)}
												>
													<div className="space-y-1">
														<Dialog.Title
															className={cx(
																subTitle ? 'text-base' : 'text-xl',
																'font-semibold leading-6 text-gray-900'
															)}
														>
															{title}
														</Dialog.Title>
														{subTitle && <p className="text-sm text-gray-500">{subTitle}</p>}
													</div>
													<div className="flex items-center h-7">
														<button
															type="button"
															className="relative text-gray-400 hover:text-gray-500"
															onClick={onClose}
														>
															<span className="absolute -inset-2.5" />
															<span className="sr-only">Close panel</span>
															<XMarkIcon className="w-6 h-6" aria-hidden="true" />
														</button>
													</div>
												</div>
											</div>

											{body}
										</div>

										{footer}
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

/**
 * @typedef BodyProps
 * @property {string} className
 */

/**
 * @param {BodyProps} props
 */
const Body = ({ className, children, ...props }) => (
	<div
		className={cx('relative flex-1 px-4 py-4 overflow-y-scroll sm:px-6 sm:py-6', className)}
		{...props}
	>
		{children}
	</div>
)
Body.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node
}

const Footer = ({ children }) => (
	<div className="flex justify-end flex-shrink-0 gap-4 px-4 py-4">{children}</div>
)
Footer.propTypes = {
	children: PropTypes.node
}

SlideOver.Body = Body
SlideOver.Footer = Footer

SlideOver.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	clickOutsideToClose: PropTypes.bool,
	size: PropTypes.oneOf(['md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl']),
	children: PropTypes.node
}

SlideOver.defaultProps = {
	clickOutsideToClose: false,
	size: 'md'
}

export default SlideOver
