import ForgotPasswordRequestBase from './base/ForgotPasswordRequestBase';

/**
 * @class ForgotPasswordRequest
 * @extends ForgotPasswordRequestBase
 */
class ForgotPasswordRequest extends ForgotPasswordRequestBase {

}

export default ForgotPasswordRequest;
