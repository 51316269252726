/* eslint-disable react/prop-types */
import { createContext, useContext, useMemo, useRef, useState } from 'react'
import { Alert } from 'ui-toolkit-tailwind/src/components'

const defaultConfirmationSettings = {
	show: false,
	title: null,
	content: null,
	confirmLabel: 'OK',
	dismissLabel: 'Cancel',
}

export const GlobalContext = createContext({
	showSpinner: false,
	/**
	 * @param {boolean} show
	 */
	setShowSpinner: show => {},
	/**
	 * @param {{ title: React.ReactNode, content: React.ReactNode, confirmLabel: string, dismissLabel: string }} params
	 * @returns {Promise}
	 */
	showConfirmation: ({ title, content, confirmLabel, dismissLabel }) => {},
})

const GlobalProvider = props => {
	const [showSpinner, setShowSpinner] = useState(false)
	const [confirmationSettings, setConfirmationSettings] = useState(
		/** @type {{ show: boolean, title: React.ReactNode, content: React.ReactNode, confirmLabel: string, dismissLabel: string }} */ (
			defaultConfirmationSettings
		),
	)

	const confirmationResolver = useRef(/** @type {function} */ (null))

	/**
	 * @param {{ title: React.ReactNode, content: React.ReactNode, confirmLabel: string, dismissLabel: string }} params
	 */
	const handleShowConfirmation = ({ title, content, confirmLabel, dismissLabel }) => {
		setConfirmationSettings({ show: true, title, content, confirmLabel, dismissLabel })
		return new Promise(resolve => {
			confirmationResolver.current = resolve
		})
	}

	const handleConfirmationOk = () => {
		if (confirmationResolver.current) confirmationResolver.current(true)
		setConfirmationSettings(prev => ({ ...prev, show: false }))
	}

	const handleConfirmationDismiss = () => {
		if (confirmationResolver.current) confirmationResolver.current(false)
		setConfirmationSettings(prev => ({ ...prev, show: false }))
	}

	const value = useMemo(() => {
		return {
			showSpinner,
			setShowSpinner,
			showConfirmation: handleShowConfirmation,
		}
	}, [showSpinner])

	return (
		<GlobalContext.Provider value={value}>
			{props.children}

			<Alert
				type="confirmation"
				intent="danger"
				visible={confirmationSettings.show}
				title={confirmationSettings.title}
				onConfirm={handleConfirmationOk}
				onDismiss={handleConfirmationDismiss}
				confirmLabel={confirmationSettings.confirmLabel}
				dismissLabel={confirmationSettings.dismissLabel}
			>
				{confirmationSettings.content}
			</Alert>
		</GlobalContext.Provider>
	)
}

export const useGlobal = () => useContext(GlobalContext)
export default GlobalProvider
