import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import LoginRequest from "../LoginRequest";
import ModelProxyClass from "./ModelProxyClass";


/**
 * @class LoginRequestBase
 * @extends ModelBaseClass
 * @property {string} email (email)
 * @property {string} password the password in plain text
 * @property {number} tenantId (int64) required for when logging in to a module that requires a specific tenant specification (like Owner's portal).  otherwise, this is ignored.
 */
class LoginRequestBase extends ModelBaseClass {
	/**
	 * @type {string} email (email)
	 */
	email;
	/**
	 * the password in plain text
	 * @type {string} password
	 */
	password;
	/**
	 * required for when logging in to a module that requires a specific tenant specification (like Owner's portal).  otherwise, this is ignored.
	 * @type {number} tenantId (int64)
	 */
	tenantId;

	/**
	 * Instantiates a new instance of LoginRequest based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {LoginRequest}
	 */
	static create(genericObject) {
		const newLoginRequest = new LoginRequest();
		newLoginRequest.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newLoginRequest;
	}

	/**
	 * Instantiates a new array of LoginRequest based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[LoginRequest]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newLoginRequestArray = [];
		genericArray.forEach(genericObject => {
			newLoginRequestArray.push(LoginRequest.create(genericObject));
		});
		return newLoginRequestArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('email', 'string'),
	ModelBaseClass.createModelProperty('password', 'string'),
	ModelBaseClass.createModelProperty('tenantId', 'integer'),
];

export default LoginRequestBase;
