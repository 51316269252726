import FileAsset from 'shared/models/FileAsset'

/**
 * @param {File} file
 * @returns {Promise<FileAsset> | null}
 */
const convertToFileAsset = file => {
	if (!file) return null

	return new Promise((resolve, reject) => {
		const fileAsset = new FileAsset()
		// setup
		const reader = new FileReader()
		reader.onload = () => {
			fileAsset.uploadBase64Data = reader.result.substring(reader.result.indexOf(',') + 1)
			fileAsset.filename = file.name
			fileAsset.mimeType = file.type
			resolve(fileAsset)
		}
		reader.onerror = error => reject(error)

		// Go
		reader.readAsDataURL(file)
	})
}

export default convertToFileAsset
