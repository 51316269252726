import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import LocationGroupSearchRequest from "../LocationGroupSearchRequest";
import ModelProxyClass from "./ModelProxyClass";
import ResultParameter from "../ResultParameter";


/**
 * @class LocationGroupSearchRequestBase
 * @extends ModelBaseClass
 * @property {number} tenantId (int64) required
 * @property {string} smartFilter
 * @property {boolean} includeInactiveFlag
 * @property {ResultParameter} resultParameter [Name]
 */
class LocationGroupSearchRequestBase extends ModelBaseClass {
	/**
	 * required
	 * @type {number} tenantId (int64)
	 */
	tenantId;
	/**
	 * @type {string} smartFilter
	 */
	smartFilter;
	/**
	 * @type {boolean} includeInactiveFlag
	 */
	includeInactiveFlag;
	/**
	 * [Name]
	 * @type {ResultParameter} resultParameter
	 */
	resultParameter;

	/**
	 * Instantiates a new instance of LocationGroupSearchRequest based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {LocationGroupSearchRequest}
	 */
	static create(genericObject) {
		const newLocationGroupSearchRequest = new LocationGroupSearchRequest();
		newLocationGroupSearchRequest.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newLocationGroupSearchRequest;
	}

	/**
	 * Instantiates a new array of LocationGroupSearchRequest based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[LocationGroupSearchRequest]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newLocationGroupSearchRequestArray = [];
		genericArray.forEach(genericObject => {
			newLocationGroupSearchRequestArray.push(LocationGroupSearchRequest.create(genericObject));
		});
		return newLocationGroupSearchRequestArray;
	}
}

/**
 * @type {string} OrderByName
 */
LocationGroupSearchRequestBase.OrderByName = 'name';

const _modelDefinition = [
	ModelBaseClass.createModelProperty('tenantId', 'integer'),
	ModelBaseClass.createModelProperty('smartFilter', 'string'),
	ModelBaseClass.createModelProperty('includeInactiveFlag', 'boolean'),
	ModelBaseClass.createModelProperty('resultParameter', 'ResultParameter'),
];

export default LocationGroupSearchRequestBase;
