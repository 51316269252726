import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import RevenuePerformanceOverTimeResponse from "../RevenuePerformanceOverTimeResponse";
import ModelProxyClass from "./ModelProxyClass";
import LineGraph from "../LineGraph";


/**
 * @class RevenuePerformanceOverTimeResponseBase
 * @extends ModelBaseClass
 * @property {LineGraph} performanceOverTime
 */
class RevenuePerformanceOverTimeResponseBase extends ModelBaseClass {
	/**
	 * @type {LineGraph} performanceOverTime
	 */
	performanceOverTime;

	/**
	 * Instantiates a new instance of RevenuePerformanceOverTimeResponse based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {RevenuePerformanceOverTimeResponse}
	 */
	static create(genericObject) {
		const newRevenuePerformanceOverTimeResponse = new RevenuePerformanceOverTimeResponse();
		newRevenuePerformanceOverTimeResponse.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newRevenuePerformanceOverTimeResponse;
	}

	/**
	 * Instantiates a new array of RevenuePerformanceOverTimeResponse based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[RevenuePerformanceOverTimeResponse]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newRevenuePerformanceOverTimeResponseArray = [];
		genericArray.forEach(genericObject => {
			newRevenuePerformanceOverTimeResponseArray.push(RevenuePerformanceOverTimeResponse.create(genericObject));
		});
		return newRevenuePerformanceOverTimeResponseArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('performanceOverTime', 'LineGraph'),
];

export default RevenuePerformanceOverTimeResponseBase;
