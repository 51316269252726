import { XCircleIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

const PageNoPermission = () => {
	return (
		<main className="grid min-h-full px-6 py-24 bg-white place-items-center sm:py-32 lg:px-8">
			<div className="text-center">
				<div className="flex items-center justify-center">
					<XCircleIcon className="w-20 h-20 sm:w-40 sm:h-40 text-danger-600" />
				</div>
				<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
					Access Denied
				</h1>
				<p className="max-w-lg mx-auto mt-6 text-base leading-7 text-gray-600">
					You currently do not have permission to view this page. If you have any questions, please{' '}
					<a
						href="mailto:support@terminalintelligence.com"
						className="italic hover:underline text-amber-600 hover:text-amber-500"
					>
						contact us
					</a>
					.
				</p>
				<div className="flex items-center justify-center mt-10 gap-x-6">
					<Link
						to="/"
						className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
					>
						<span aria-hidden="true">&larr;</span> Back to home
					</Link>
				</div>
			</div>
		</main>
	)
}

export default PageNoPermission
