import RevenuePerformanceOverTimeResponseBase from './base/RevenuePerformanceOverTimeResponseBase';

/**
 * @class RevenuePerformanceOverTimeResponse
 * @extends RevenuePerformanceOverTimeResponseBase
 */
class RevenuePerformanceOverTimeResponse extends RevenuePerformanceOverTimeResponseBase {

}

export default RevenuePerformanceOverTimeResponse;
