import { Field, useField } from 'formik'
import PropTypes from 'prop-types'

import ExistingFile from '../../../ExistingFile'
import ImageUpload from '../../Forms/ImageUpload'

/**
 * @param {import('../../Forms/ImageUpload').ImageUploadProps & {endpointUrl: string}} props
 */
const FormikImageUpload = ({ endpointUrl, ...props }) => {
	const [field, meta, helpers] = useField(props)
	const { error, touched } = meta
	const hasError = error && touched

	const isExistingFile = field.value instanceof ExistingFile
	const initialImageUrl = isExistingFile ? `${endpointUrl}${field.value?.viewUrl}` : null

	/** @param {File} file */
	const handleChange = file => {
		helpers.setValue(file)
	}

	return (
		<Field
			as={ImageUpload}
			{...field}
			{...props}
			onChange={handleChange}
			initialImageUrl={initialImageUrl}
			invalid={hasError}
			errorMessage={error}
		/>
	)
}

FormikImageUpload.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func,
	endpointUrl: PropTypes.string,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.node,
	helperText: PropTypes.string,
	cornerHint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	disabled: PropTypes.bool,
	accept: PropTypes.string,
	defaultImageUrl: PropTypes.string,
	buttonLabel: PropTypes.string
}

FormikImageUpload.defaultProps = {}

export default FormikImageUpload
