import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import ReportedVgtMachine from "../ReportedVgtMachine";
import ModelProxyClass from "./ModelProxyClass";


/**
 * @class ReportedVgtMachineBase
 * @extends ModelBaseClass
 * @property {number} id (int64)
 * @property {number} tenantId (int64)
 * @property {number} locationId (int64)
 * @property {string} licenseNumber
 * @property {string} locationLicenseNumber
 * @property {string} spotName
 * @property {string} displayName
 * @property {string} serialNumber
 * @property {Date} dateFirstPerformanceLogged (date only)
 * @property {Date} dateLastPerformanceLogged (date only)
 * @property {number} lastMonthNetTerminalIncome
 * @property {number} monthToDateNetTerminalIncome
 * @property {number} yesterdayNetTerminalIncome
 * @property {Date} dateCreated (date and time)
 */
class ReportedVgtMachineBase extends ModelBaseClass {
	/**
	 * @type {number} id (int64)
	 */
	id;
	/**
	 * @type {number} tenantId (int64)
	 */
	tenantId;
	/**
	 * @type {number} locationId (int64)
	 */
	locationId;
	/**
	 * @type {string} licenseNumber
	 */
	licenseNumber;
	/**
	 * @type {string} locationLicenseNumber
	 */
	locationLicenseNumber;
	/**
	 * @type {string} spotName
	 */
	spotName;
	/**
	 * @type {string} displayName
	 */
	displayName;
	/**
	 * @type {string} serialNumber
	 */
	serialNumber;
	/**
	 * @type {Date} dateFirstPerformanceLogged (date only)
	 */
	dateFirstPerformanceLogged;
	/**
	 * @type {Date} dateLastPerformanceLogged (date only)
	 */
	dateLastPerformanceLogged;
	/**
	 * @type {number} lastMonthNetTerminalIncome
	 */
	lastMonthNetTerminalIncome;
	/**
	 * @type {number} monthToDateNetTerminalIncome
	 */
	monthToDateNetTerminalIncome;
	/**
	 * @type {number} yesterdayNetTerminalIncome
	 */
	yesterdayNetTerminalIncome;
	/**
	 * @type {Date} dateCreated (date and time)
	 */
	dateCreated;

	/**
	 * Instantiates a new instance of ReportedVgtMachine based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {ReportedVgtMachine}
	 */
	static create(genericObject) {
		const newReportedVgtMachine = new ReportedVgtMachine();
		newReportedVgtMachine.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newReportedVgtMachine;
	}

	/**
	 * Instantiates a new array of ReportedVgtMachine based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[ReportedVgtMachine]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newReportedVgtMachineArray = [];
		genericArray.forEach(genericObject => {
			newReportedVgtMachineArray.push(ReportedVgtMachine.create(genericObject));
		});
		return newReportedVgtMachineArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('id', 'integer'),
	ModelBaseClass.createModelProperty('tenantId', 'integer'),
	ModelBaseClass.createModelProperty('locationId', 'integer'),
	ModelBaseClass.createModelProperty('licenseNumber', 'string'),
	ModelBaseClass.createModelProperty('locationLicenseNumber', 'string'),
	ModelBaseClass.createModelProperty('spotName', 'string'),
	ModelBaseClass.createModelProperty('displayName', 'string'),
	ModelBaseClass.createModelProperty('serialNumber', 'string'),
	ModelBaseClass.createModelProperty('dateFirstPerformanceLogged', 'datetime'),
	ModelBaseClass.createModelProperty('dateLastPerformanceLogged', 'datetime'),
	ModelBaseClass.createModelProperty('lastMonthNetTerminalIncome', 'float'),
	ModelBaseClass.createModelProperty('monthToDateNetTerminalIncome', 'float'),
	ModelBaseClass.createModelProperty('yesterdayNetTerminalIncome', 'float'),
	ModelBaseClass.createModelProperty('dateCreated', 'datetime'),
];

export default ReportedVgtMachineBase;
