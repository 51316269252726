import { ArrowUpTrayIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline'
import { cva, cx } from 'class-variance-authority'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import ExistingFile from 'ui-toolkit-tailwind/src/ExistingFile'

const variants = cva('w-full', {
	variants: {},
	defaultVariants: {}
})

/**
 * @typedef MultiFileUploadProps
 * @property {string} [label]
 * @property {File[] | ExistingFile[]} [values]
 * @property {string} [className]
 * @property {boolean} [disabled]
 * @property {boolean} [invalid]
 * @property {string} [errorMessage]
 * @property {function} [onChange]
 * @property {React.ReactNode} [helperText] Helper text to display inside the drop zone
 * @property {string} [cornerHint] Hint text to display on the right side of the label
 * @property {string} [accept] File types to accept
 * @property {string} [endpointUrl] Base URL for existing files, must be used if values are ExistingFile instances
 */

/**
 * @param {MultiFileUpload} props
 */
const MultiFileUpload = ({
	label,
	cornerHint,
	helperText,
	value,
	disabled,
	invalid,
	errorMessage,
	className,
	onChange,
	accept,
	endpointUrl,
	...props
}) => {
	const [files, setFiles] = useState(/** @type {File[]} */ ([]))

	const hasLabel = !!label
	const hasError = invalid && errorMessage

	useEffect(() => {
		setFiles(value || [])
	}, [value])

	const handleAddFiles = newFiles => {
		setFiles(prev => [...prev, ...newFiles])
		if (onChange) {
			onChange([...files, ...newFiles])
		}
	}

	const handleFileUpload = event => {
		const newFiles = Array.from(event.target.files)
		handleAddFiles(newFiles)
	}

	const handleRemoveFile = indexToRemove => {
		setFiles(prev => {
			const newFiles = prev.filter((_, index) => index !== indexToRemove)
			if (onChange) {
				onChange(newFiles)
			}
			return newFiles
		})
	}

	/**
	 * @param {File | ExistingFile} file
	 */
	const handleViewFile = file => {
		let url
		if (file instanceof ExistingFile) url = `${endpointUrl}${file.viewUrl}`
		else url = URL.createObjectURL(file)
		window.open(url, '_blank')
	}

	/**
	 * @param {DragEvent} e
	 */
	const handleDropFile = e => {
		e.preventDefault()
		const droppedFiles = e.dataTransfer.files
		if (droppedFiles.length > 0) {
			const newFiles = Array.from(droppedFiles)
			handleAddFiles(newFiles)
		}
	}

	return (
		<div className={variants({ className })} {...props}>
			{/* Label */}
			{!!hasLabel && (
				<div className="flex justify-between mb-2">
					<label className="block text-sm font-medium leading-6 text-gray-900">{label}</label>
					{cornerHint && <span className="mr-1 text-xs leading-6 text-gray-500">{cornerHint}</span>}
				</div>
			)}
			<div
				className={cx(
					'w-full p-4 ring-1 ring-inset rounded-lg',
					hasError ? ' ring-danger-300' : 'ring-gray-200'
				)}
			>
				{/* File Upload Input */}
				<div
					className="flex items-center justify-center w-full"
					onDrop={handleDropFile}
					onDragOver={e => e.preventDefault()}
				>
					<label
						htmlFor="browse-files"
						className={cx(
							'flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer ',
							hasError
								? ' border-danger-300 bg-danger-50 hover:bg-danger-100'
								: 'border-gray-300 bg-gray-50 hover:bg-gray-100'
						)}
					>
						<div className="flex flex-col items-center justify-center pt-5 pb-6">
							<ArrowUpTrayIcon className="mb-2 text-gray-500 size-8" />
							<p className="mb-2 text-sm text-center text-gray-500">
								<div className={cx(hasError && 'text-danger-900')}>
									<span className="font-semibold">Click to upload</span> or drag and drop
								</div>
								{helperText && <i className="text-xs text-gray-400">{helperText}</i>}
							</p>
						</div>
						<input
							id="browse-files"
							type="file"
							className="hidden"
							multiple
							onChange={handleFileUpload}
							accept={accept}
						/>
					</label>
				</div>

				{/* File List */}
				{files.length > 0 && (
					<div className="px-2 mt-6">
						<h3 className="text-sm font-medium text-gray-700">Uploaded files</h3>
						<ul className="divide-y divide-gray-200">
							{files.map((file, index) => {
								const isExistingFile = file instanceof ExistingFile

								return (
									<li
										key={`${file.name}-${index}`}
										className="flex items-center justify-between py-3"
									>
										<span className="flex-1 text-sm text-gray-500 truncate">
											{isExistingFile ? file.filename : file.name}
										</span>
										<div className="flex space-x-2">
											<button
												type="button"
												aria-label="View File"
												onClick={() => handleViewFile(file)}
												className="p-1.5  !text-gray-500 hover:text-gray-700 rounded-md hover:bg-gray-100"
											>
												<EyeIcon className="w-4 h-4" />
											</button>
											<button
												type="button"
												aria-label="Remove File"
												onClick={() => handleRemoveFile(index)}
												className="p-1.5 text-gray-500 hover:text-red-600 rounded-md hover:bg-gray-100"
											>
												<TrashIcon className="w-4 h-4" />
											</button>
										</div>
									</li>
								)
							})}
						</ul>
					</div>
				)}
			</div>
			{hasError && <p className={cx('mt-2 text-sm text-danger-600')}>{errorMessage}</p>}
		</div>
	)
}

MultiFileUpload.propTypes = {
	label: PropTypes.string,
	cornerHint: PropTypes.string,
	helperText: PropTypes.string,
	value: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.instanceOf(ExistingFile)])
	),
	disabled: PropTypes.bool,
	invalid: PropTypes.bool,
	errorMessage: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func,
	accept: PropTypes.string,
	endpointUrl: PropTypes.string
}

export default MultiFileUpload
