import GraphItemBase from './base/GraphItemBase';

/**
 * @class GraphItem
 * @extends GraphItemBase
 */
class GraphItem extends GraphItemBase {

}

export default GraphItem;
