import { useGlobal } from 'contexts/global-context'
import { Form, Formik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { Button } from 'shared/catalyst-components/button'
import { Dialog, DialogActions, DialogBody, DialogTitle } from 'shared/catalyst-components/dialog'
import { Divider } from 'shared/catalyst-components/divider'
import Client from 'shared/clients/base/Client'
import DefaultClientOptions from 'shared/clients/DefaultClientOptions'
import { ReimbursementStatusBadge } from 'shared/components'
import ReimbursementStatusEnum from 'shared/models/enum/ReimbursementStatusEnum'
import TenantModulesEnum from 'shared/models/enum/TenantModulesEnum'
import FileAsset from 'shared/models/FileAsset'
import Location from 'shared/models/Location'
import Reimbursement from 'shared/models/Reimbursement'
import convertToFileAsset from 'shared/utilities/convertToFileAsset'
import { displayAmount, displayDate } from 'shared/utilities/helpers'
import {
	FormikMultiFileUpload,
	FormikRadioGroup,
	FormikText,
	ReadOnlyField,
	Spinner,
} from 'ui-toolkit-tailwind/src/components'
import { Col, Grid } from 'ui-toolkit-tailwind/src/components/FormLayouts'
import { showToast } from 'utilities/showToast'
import * as Yup from 'yup'

const TypeEnum = {
	VIEW: 'View', // [Owner's Portal, Revenue Module]
	CREATE: 'Create', // [Owner's Portal, Revenue Module]
	EDIT: 'Edit', // [Owner's Portal]
	REVIEW: 'Review', // [Revenue Module]
}

/** @type {Reimbursement} */
const defaultValues = {
	// for Owner's Portal
	amount: '',
	description: '',
	receiptFileAssets: [],
	// for Revenue Module
	approvalFlag: null,
	evaluationReason: '',
}

/**
 * @typedef ReimbursementFormModalProps
 * @property {Location} location required when creating a new reimbursement
 * @property {Reimbursement} [reimbursement]
 * @property {boolean} visible
 * @property {() => void} onClose
 * @property {(reimbursement: Reimbursement) => void} onFinish
 */

/**
 * @param {ReimbursementFormModalProps} props
 */
const ReimbursementFormModal = ({
	location,
	reimbursement: originalReimbursement,
	visible,
	onClose,
	onFinish,
}) => {
	const isOwnerPortal = process.env.REACT_APP_MODULE === TenantModulesEnum.OWNERS_PORTAL
	const isRevenueModule = process.env.REACT_APP_MODULE === TenantModulesEnum.REVENUE

	const { setShowSpinner, showConfirmation } = useGlobal()

	const [reimbursement, setReimbursement] = useState(/** @type {Reimbursement} */ (null))

	// get the type of the current form modal based on the what module we are in
	// and the current state of the reimbursement
	const type = useMemo(() => {
		if (!reimbursement) return null
		if (!reimbursement.id) return TypeEnum.CREATE

		if (isOwnerPortal) {
			return reimbursement.approvalFlag ? TypeEnum.VIEW : TypeEnum.EDIT
		}

		if (isRevenueModule) {
			return [ReimbursementStatusEnum.APPROVED, ReimbursementStatusEnum.DECLINED].includes(
				reimbursement.status,
			)
				? TypeEnum.VIEW
				: TypeEnum.REVIEW
		}

		return null
	}, [isOwnerPortal, isRevenueModule, reimbursement])

	const editRequestValidationSchema = Yup.object({
		amount: Yup.number()
			.typeError('Amount has to be a number')
			.moreThan(0, 'Amount has to be greater than 0')
			.required(),
		description: Yup.string().required(),
		receiptFileAssets: Yup.array().min(1, 'At least one attachment is required'),
	})
	const evaluateRequestValidationSchema = Yup.object({
		approvalFlag:
			type === TypeEnum.CREATE ? Yup.boolean().nullable() : Yup.boolean().nullable().required(),
		evaluationReason: Yup.string()
			.nullable()
			.when('approvalFlag', {
				is: false,
				then: schema => schema.required(),
			}),
	})

	const validationSchema = [TypeEnum.EDIT, TypeEnum.CREATE].includes(type)
		? editRequestValidationSchema
		: evaluateRequestValidationSchema

	const getActionButtonText = () => {
		if (type === TypeEnum.CREATE) return 'Submit'
		if (type === TypeEnum.EDIT) {
			if (reimbursement?.status === ReimbursementStatusEnum.DECLINED) return 'Re-Submit'
			else return 'Save'
		}
		if (type === TypeEnum.REVIEW) return 'Submit'
		return ''
	}

	useEffect(() => {
		if (visible) {
			if (originalReimbursement) {
				Client.RevenueApi.getReimbursement(String(originalReimbursement.id), {
					status200: setReimbursement,
				})
			} else {
				setReimbursement(defaultValues)
			}
		} else {
			setReimbursement(null)
		}
	}, [visible, originalReimbursement])

	/** @param {Reimbursement} values */
	const handleEditRequestSubmit = async values => {
		const request = Reimbursement.create(values)

		// convert the file assets to FileAsset objects
		const fileAssets = values.receiptFileAssets.map(async f => {
			const isExistingFileAsset = f instanceof FileAsset
			if (isExistingFileAsset) return f
			else return await convertToFileAsset(f)
		})
		request.receiptFileAssets = await Promise.all(fileAssets)

		if (type === TypeEnum.CREATE) {
			request.location = Location.create({ id: location.id })
		}

		Client.RevenueApi.saveReimbursement(
			request,
			{
				status200: response => {
					showToast({
						intent: 'success',
						title: 'Reimbursement Request',
						content: `The reimbursement request has been ${
							type === TypeEnum.CREATE ? 'created' : 'updated'
						}	successfully.`,
					})
					onClose()
					onFinish(response)
				},
			},
			{
				onApiCall: () => setShowSpinner(true),
				onApiResponse: () => setShowSpinner(false),
			},
		)
	}
	/** @param {Reimbursement} values */
	const handleRevenueModuleSubmit = async values => {
		const request = new Reimbursement()
		request.id = originalReimbursement.id
		request.approvalFlag = values.approvalFlag
		if (values.approvalFlag === false) request.evaluationReason = values.evaluationReason

		Client.RevenueApi.evaluateReimbursement(
			request,
			{
				status200: response => {
					showToast({
						intent: 'success',
						title: 'Reimbursement Request',
						content: `Review has been submitted.`,
					})
					onClose()
					onFinish(response)
				},
			},
			{
				onApiCall: () => setShowSpinner(true),
				onApiResponse: () => setShowSpinner(false),
			},
		)
	}

	/** @param {Reimbursement} values */
	const handleFormSubmit = values => {
		if ([TypeEnum.EDIT, TypeEnum.CREATE].includes(type)) handleEditRequestSubmit(values)
		if (type === TypeEnum.REVIEW) handleRevenueModuleSubmit(values)
	}

	const handleDelete = async () => {
		const confirm = await showConfirmation({
			title: 'Delete Reimbursement Request',
			content: 'Are you sure you want to delete this reimbursement request?',
		})
		if (confirm) {
			Client.RevenueApi.deleteReimbursement(
				String(reimbursement.id),
				{
					status200: () => {
						showToast({
							intent: 'success',
							title: 'Reimbursement Request',
							content: 'The reimbursement request has been deleted successfully.',
						})
						onClose()
						onFinish()
					},
				},
				{
					onApiCall: () => setShowSpinner(true),
					onApiResponse: () => setShowSpinner(false),
				},
			)
		}
	}

	const handleUndoApproval = async () => {
		const confirm = await showConfirmation({
			title: 'Undo Approval',
			content: 'Are you sure you want to undo the approval of this reimbursement request?',
		})

		const request = new Reimbursement()
		request.id = originalReimbursement.id
		request.approvalFlag = null

		if (confirm) {
			Client.RevenueApi.evaluateReimbursement(
				request,
				{
					status200: () => {
						showToast({
							intent: 'success',
							title: 'Undo Approval',
							content: 'The reimbursement approval has been undone successfully.',
						})
						onClose()
						onFinish()
					},
				},
				{
					onApiCall: () => setShowSpinner(true),
					onApiResponse: () => setShowSpinner(false),
				},
			)
		}
	}

	if (!reimbursement && visible) return <Spinner visible />

	return (
		<Formik
			enableReinitialize
			initialValues={reimbursement}
			onSubmit={handleFormSubmit}
			validationSchema={validationSchema}
		>
			{({ values, errors, touched, setValues, setFieldValue, resetForm, handleSubmit }) => {
				return (
					<Dialog size="3xl" open={visible} onClose={onClose}>
						<Form>
							<DialogTitle>
								{type === TypeEnum.CREATE ? 'New' : type} Reimbursement Request
							</DialogTitle>
							<DialogBody>
								<div className="space-y-3 md:space-y-6">
									<ReimbursementInformation
										location={location}
										reimbursement={reimbursement}
										type={type}
									/>
									{values && (
										<>
											<Divider />
											<ReimbursementForm values={values} type={type} />
										</>
									)}
								</div>
							</DialogBody>
							<DialogActions>
								{type === TypeEnum.EDIT &&
									reimbursement?.status === ReimbursementStatusEnum.REQUESTED && (
										<Button plain className="mr-auto !text-danger-700" onClick={handleDelete}>
											Delete Request
										</Button>
									)}

								{isRevenueModule && reimbursement?.status === ReimbursementStatusEnum.APPROVED && (
									<Button plain className="mr-auto !text-danger-700" onClick={handleUndoApproval}>
										Undo Approval
									</Button>
								)}

								<Button plain outline={type === TypeEnum.VIEW} onClick={onClose}>
									{type === TypeEnum.VIEW ? 'Close' : 'Cancel'}
								</Button>
								{type !== TypeEnum.VIEW && <Button type="submit">{getActionButtonText()}</Button>}
							</DialogActions>
						</Form>
					</Dialog>
				)
			}}
		</Formik>
	)
}

/**
 * @typedef ReimbursementInformationProps
 * @property {Location} location
 * @property {Reimbursement} [reimbursement]
 * @property {string} type
 */

/**
 * @param {ReimbursementFormModalProps} props
 */
const ReimbursementInformation = ({ location, reimbursement, type }) => {
	return (
		<Grid tighten>
			<Col span={3}>
				<ReadOnlyField label="Location" value={reimbursement?.location?.name || location?.name} />
			</Col>

			{type !== TypeEnum.CREATE && reimbursement && (
				<>
					<Col span={3}>
						<ReadOnlyField
							label="Status"
							value={<ReimbursementStatusBadge status={reimbursement.status} />}
						/>
					</Col>

					<Col span={3}>
						<ReadOnlyField
							label="Date Requested"
							value={`${displayDate(reimbursement.dateRequested)} by ${
								reimbursement.requestedByPerson?.name
							}`}
						/>
					</Col>

					{reimbursement.status !== ReimbursementStatusEnum.REQUESTED && (
						<>
							<Col span={3}>
								<ReadOnlyField
									label={
										reimbursement?.status === ReimbursementStatusEnum.DECLINED
											? 'Date Declined'
											: 'Date Approved'
									}
									value={`${displayDate(reimbursement.dateEvaluated)} by ${
										reimbursement.evaluatedByPerson?.name
									}`}
								/>
							</Col>

							{reimbursement.status === ReimbursementStatusEnum.DECLINED && (
								<Col span={6}>
									<ReadOnlyField label="Reason" value={reimbursement.evaluationReason || '-'} />
								</Col>
							)}

							{[ReimbursementStatusEnum.APPROVED, ReimbursementStatusEnum.PAID].includes(
								reimbursement.status,
							) && (
								<Col span={3}>
									<ReadOnlyField
										label="Date Paid"
										value={displayDate(reimbursement.datePaid) || '-'}
									/>
								</Col>
							)}
						</>
					)}
				</>
			)}
		</Grid>
	)
}

/**
 * @typedef ReimbursementFormProps
 * @property {string} type
 * @property {Reimbursement} values
 */

/**
 * @param {ReimbursementFormProps} props
 */
const ReimbursementForm = ({ values, type }) => {
	return (
		<>
			{[TypeEnum.CREATE, TypeEnum.EDIT].includes(type) ? (
				<Grid tighten>
					<Col span={2}>
						<FormikText leadingAddon="$" name="amount" label="Amount" />
					</Col>
					<Col span={4}>
						<FormikText name="description" label="Description" />
					</Col>
					<Col span={6}>
						<FormikMultiFileUpload
							endpointUrl={DefaultClientOptions.getEndpointUrl()}
							name="receiptFileAssets"
							label="Attachment(s)"
							helperText="Supported file types: .jpg, .jpeg, .png, .pdf"
							accept=".jpg,.jpeg,.png,.pdf"
						/>
					</Col>
				</Grid>
			) : (
				<Grid tighten>
					<Col span={3}>
						<ReadOnlyField label="Amount" value={displayAmount(values.amount)} />
					</Col>
					<Col span={3}>
						<ReadOnlyField label="Description" value={values.description || '-'} />
					</Col>
					<Col span={6}>
						<ReadOnlyField
							label="Attachment(s)"
							value={
								<ul>
									{values.receiptFileAssets?.map(file => (
										<li key={file.id}>
											<a
												className="link"
												href={`${DefaultClientOptions.getEndpointUrl()}/${file.viewUrl}`}
												target="_blank"
												rel="noreferrer"
											>
												{file.filename}
											</a>
										</li>
									))}
								</ul>
							}
						/>
					</Col>
				</Grid>
			)}

			{type === TypeEnum.REVIEW && (
				<Grid tighten>
					<Col span={3}>
						<FormikRadioGroup
							inline
							name="approvalFlag"
							label="Response"
							options={[
								{ label: 'Approve', value: true },
								{ label: 'Decline', value: false },
							]}
						/>
					</Col>
					{values.approvalFlag === false && (
						<Col span={6}>
							<FormikText name="evaluationReason" label="Reason" />
						</Col>
					)}
				</Grid>
			)}
		</>
	)
}

export default ReimbursementFormModal
