import TenantPreferencesBase from './base/TenantPreferencesBase';

/**
 * @class TenantPreferences
 * @extends TenantPreferencesBase
 */
class TenantPreferences extends TenantPreferencesBase {

}

export default TenantPreferences;
