import LocationGroupSearchRequestBase from './base/LocationGroupSearchRequestBase';

/**
 * @class LocationGroupSearchRequest
 * @extends LocationGroupSearchRequestBase
 */
class LocationGroupSearchRequest extends LocationGroupSearchRequestBase {

}

export default LocationGroupSearchRequest;
