import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import LocationSearchRequest from "../LocationSearchRequest";
import ModelProxyClass from "./ModelProxyClass";
import ResultParameter from "../ResultParameter";


/**
 * @class LocationSearchRequestBase
 * @extends ModelBaseClass
 * @property {number} tenantId (int64) required
 * @property {string} smartFilter
 * @property {boolean} includeInactiveFlag
 * @property {ResultParameter} resultParameter [Name, City, LicenseNumber]
 */
class LocationSearchRequestBase extends ModelBaseClass {
	/**
	 * required
	 * @type {number} tenantId (int64)
	 */
	tenantId;
	/**
	 * @type {string} smartFilter
	 */
	smartFilter;
	/**
	 * @type {boolean} includeInactiveFlag
	 */
	includeInactiveFlag;
	/**
	 * [Name, City, LicenseNumber]
	 * @type {ResultParameter} resultParameter
	 */
	resultParameter;

	/**
	 * Instantiates a new instance of LocationSearchRequest based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {LocationSearchRequest}
	 */
	static create(genericObject) {
		const newLocationSearchRequest = new LocationSearchRequest();
		newLocationSearchRequest.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newLocationSearchRequest;
	}

	/**
	 * Instantiates a new array of LocationSearchRequest based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[LocationSearchRequest]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newLocationSearchRequestArray = [];
		genericArray.forEach(genericObject => {
			newLocationSearchRequestArray.push(LocationSearchRequest.create(genericObject));
		});
		return newLocationSearchRequestArray;
	}
}

/**
 * @type {string} OrderByName
 */
LocationSearchRequestBase.OrderByName = 'name';

/**
 * @type {string} OrderByCity
 */
LocationSearchRequestBase.OrderByCity = 'city';

/**
 * @type {string} OrderByLicenseNumber
 */
LocationSearchRequestBase.OrderByLicenseNumber = 'licensenumber';

const _modelDefinition = [
	ModelBaseClass.createModelProperty('tenantId', 'integer'),
	ModelBaseClass.createModelProperty('smartFilter', 'string'),
	ModelBaseClass.createModelProperty('includeInactiveFlag', 'boolean'),
	ModelBaseClass.createModelProperty('resultParameter', 'ResultParameter'),
];

export default LocationSearchRequestBase;
