import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import Tenant from "../Tenant";
import ModelProxyClass from "./ModelProxyClass";
import FileAsset from "../FileAsset";


/**
 * @class TenantBase
 * @extends ModelBaseClass
 * @property {number} id (int64) use 0 for the single ETI System Management tenant
 * @property {string} name
 * @property {['SystemManagement'|'ToManagement'|'Revenue'|'OwnersPortal']} modules
 * @property {FileAsset} logoFileAsset
 * @property {string} lnwUsername only set when calling TenantApi::get()
 * @property {string} lnwPassword this is never set.  only used when TenantApi::edit()
 * @property {string} igbUsername only set when calling TenantApi::get()
 * @property {string} igbPassword this is never set.  only used when TenantApi::edit()
 * @property {string} ownersPortalName
 * @property {string} ownersPortalDomain
 * @property {boolean} activeFlag
 * @property {Date} dateCreated (date and time)
 */
class TenantBase extends ModelBaseClass {
	/**
	 * use 0 for the single ETI System Management tenant
	 * @type {number} id (int64)
	 */
	id;
	/**
	 * @type {string} name
	 */
	name;
	/**
	 * @type {['SystemManagement'|'ToManagement'|'Revenue'|'OwnersPortal']} modules
	 */
	modules;
	/**
	 * @type {FileAsset} logoFileAsset
	 */
	logoFileAsset;
	/**
	 * only set when calling TenantApi::get()
	 * @type {string} lnwUsername
	 */
	lnwUsername;
	/**
	 * this is never set.  only used when TenantApi::edit()
	 * @type {string} lnwPassword
	 */
	lnwPassword;
	/**
	 * only set when calling TenantApi::get()
	 * @type {string} igbUsername
	 */
	igbUsername;
	/**
	 * this is never set.  only used when TenantApi::edit()
	 * @type {string} igbPassword
	 */
	igbPassword;
	/**
	 * @type {string} ownersPortalName
	 */
	ownersPortalName;
	/**
	 * @type {string} ownersPortalDomain
	 */
	ownersPortalDomain;
	/**
	 * @type {boolean} activeFlag
	 */
	activeFlag;
	/**
	 * @type {Date} dateCreated (date and time)
	 */
	dateCreated;

	/**
	 * Instantiates a new instance of Tenant based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {Tenant}
	 */
	static create(genericObject) {
		const newTenant = new Tenant();
		newTenant.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newTenant;
	}

	/**
	 * Instantiates a new array of Tenant based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[Tenant]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newTenantArray = [];
		genericArray.forEach(genericObject => {
			newTenantArray.push(Tenant.create(genericObject));
		});
		return newTenantArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('id', 'integer'),
	ModelBaseClass.createModelProperty('name', 'string'),
	ModelBaseClass.createModelProperty('modules', '[string]'),
	ModelBaseClass.createModelProperty('logoFileAsset', 'FileAsset'),
	ModelBaseClass.createModelProperty('lnwUsername', 'string'),
	ModelBaseClass.createModelProperty('lnwPassword', 'string'),
	ModelBaseClass.createModelProperty('igbUsername', 'string'),
	ModelBaseClass.createModelProperty('igbPassword', 'string'),
	ModelBaseClass.createModelProperty('ownersPortalName', 'string'),
	ModelBaseClass.createModelProperty('ownersPortalDomain', 'string'),
	ModelBaseClass.createModelProperty('activeFlag', 'boolean'),
	ModelBaseClass.createModelProperty('dateCreated', 'datetime'),
];

export default TenantBase;
