import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import LocationGroup from "../LocationGroup";
import ModelProxyClass from "./ModelProxyClass";
import Person from "../Person";
import Location from "../Location";


/**
 * @class LocationGroupBase
 * @extends ModelBaseClass
 * @property {number} id (int64)
 * @property {number} tenantId (int64)
 * @property {string} name
 * @property {number} locationCount (integer)
 * @property {number} ownerCount (integer)
 * @property {[Person]} owners only returned when calling LocationGroupApi::get and ::search.  Can be optionally set with owners to setup when calling ::edit
 * @property {[Location]} locations only returned when calling LocationGroupApi::get.
 * @property {boolean} activeFlag
 * @property {Date} dateCreated (date and time)
 */
class LocationGroupBase extends ModelBaseClass {
	/**
	 * @type {number} id (int64)
	 */
	id;
	/**
	 * @type {number} tenantId (int64)
	 */
	tenantId;
	/**
	 * @type {string} name
	 */
	name;
	/**
	 * @type {number} locationCount (integer)
	 */
	locationCount;
	/**
	 * @type {number} ownerCount (integer)
	 */
	ownerCount;
	/**
	 * only returned when calling LocationGroupApi::get and ::search.  Can be optionally set with owners to setup when calling ::edit
	 * @type {[Person]} owners
	 */
	owners;
	/**
	 * only returned when calling LocationGroupApi::get.
	 * @type {[Location]} locations
	 */
	locations;
	/**
	 * @type {boolean} activeFlag
	 */
	activeFlag;
	/**
	 * @type {Date} dateCreated (date and time)
	 */
	dateCreated;

	/**
	 * Instantiates a new instance of LocationGroup based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {LocationGroup}
	 */
	static create(genericObject) {
		const newLocationGroup = new LocationGroup();
		newLocationGroup.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newLocationGroup;
	}

	/**
	 * Instantiates a new array of LocationGroup based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[LocationGroup]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newLocationGroupArray = [];
		genericArray.forEach(genericObject => {
			newLocationGroupArray.push(LocationGroup.create(genericObject));
		});
		return newLocationGroupArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('id', 'integer'),
	ModelBaseClass.createModelProperty('tenantId', 'integer'),
	ModelBaseClass.createModelProperty('name', 'string'),
	ModelBaseClass.createModelProperty('locationCount', 'integer'),
	ModelBaseClass.createModelProperty('ownerCount', 'integer'),
	ModelBaseClass.createModelProperty('owners', '[Person]'),
	ModelBaseClass.createModelProperty('locations', '[Location]'),
	ModelBaseClass.createModelProperty('activeFlag', 'boolean'),
	ModelBaseClass.createModelProperty('dateCreated', 'datetime'),
];

export default LocationGroupBase;
