import * as yup from 'yup'

/**
 * @param {number} fileSizeLimit - The maximum file size in bytes
 * @returns {yup.Schema} The Yup schema for file size validation
 */
const logoImageSchema = yup
	.mixed()
	.test('fileSize', `File size must be less than 4MB`, value => {
		if (value instanceof File) {
			return value.size <= 4 * 1024 * 1024
		}
		return true
	})
	.test('fileType', `File must be an image`, value => {
		if (value instanceof File) {
			return value.type.startsWith('image/')
		}
		return true
	})
export default logoImageSchema
