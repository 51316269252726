import LocationSearchResponseBase from './base/LocationSearchResponseBase';

/**
 * @class LocationSearchResponse
 * @extends LocationSearchResponseBase
 */
class LocationSearchResponse extends LocationSearchResponseBase {

}

export default LocationSearchResponse;
