import TenantBase from './base/TenantBase';

/**
 * @class Tenant
 * @extends TenantBase
 */
class Tenant extends TenantBase {

}

export default Tenant;
