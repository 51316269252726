import RevenuePaymentSearchRequestBase from './base/RevenuePaymentSearchRequestBase';

/**
 * @class RevenuePaymentSearchRequest
 * @extends RevenuePaymentSearchRequestBase
 */
class RevenuePaymentSearchRequest extends RevenuePaymentSearchRequestBase {

}

export default RevenuePaymentSearchRequest;
