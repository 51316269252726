import { Field, useField } from 'formik'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import RadioGroup from '../../Forms/RadioGroup'

/**
 * @param {import('../../Forms/RadioGroup').RadioGroupProps} props
 */
const FormikRadioGroup = props => {
	const [field, meta, helpers] = useField(props)
	const { error, touched } = meta
	const hasError = error && touched

	const handleChange = useCallback(
		value => {
			helpers.setValue(value)
		},
		[helpers]
	)

	return (
		<Field
			as={RadioGroup}
			{...props}
			{...field}
			onChange={handleChange}
			invalid={hasError}
			errorMessage={error}
		/>
	)
}

FormikRadioGroup.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.node,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any,
			label: PropTypes.string
		})
	),
	helperText: PropTypes.string,
	inline: PropTypes.bool,
	disabled: PropTypes.bool
}

FormikRadioGroup.defaultProps = {}

export default FormikRadioGroup
