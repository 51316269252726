const sessionCookieName = 'currentSession'

const setSessionCookie = () => {
	document.cookie = `${sessionCookieName}=true; path=/`
}

/**
 * Check if the session cookie is set
 * @returns {boolean} - true if the session cookie is set, false otherwise
 */
const checkCookieSession = () => {
	const hasCurrentSession = document.cookie
		.split(';')
		.some(cookie => cookie.trim().startsWith(`${sessionCookieName}=true`))

	// renew session cookie
	setSessionCookie()

	return hasCurrentSession
}

export default checkCookieSession
