import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import Reimbursement from "../Reimbursement";
import ModelProxyClass from "./ModelProxyClass";
import Location from "../Location";
import Person from "../Person";
import FileAsset from "../FileAsset";


/**
 * @class ReimbursementBase
 * @extends ModelBaseClass
 * @property {number} id (int64)
 * @property {Location} location
 * @property {number} paymentId (int64)
 * @property {'Requested'|'Declined'|'Approved'|'Paid'} status
 * @property {number} amount
 * @property {string} description
 * @property {Date} dateRequested (date and time)
 * @property {Person} requestedByPerson
 * @property {Date} dateEvaluated (date and time)
 * @property {boolean} approvalFlag
 * @property {string} evaluationReason
 * @property {Person} evaluatedByPerson
 * @property {Date} datePaid (date and time)
 * @property {Date} dateCreated (date and time)
 * @property {[FileAsset]} receiptFileAssets only set in the response after calling get() or edit().  Can also be used when calling edit() to set/update/upload file assets
 */
class ReimbursementBase extends ModelBaseClass {
	/**
	 * @type {number} id (int64)
	 */
	id;
	/**
	 * @type {Location} location
	 */
	location;
	/**
	 * @type {number} paymentId (int64)
	 */
	paymentId;
	/**
	 * @type {'Requested'|'Declined'|'Approved'|'Paid'} status
	 */
	status;
	/**
	 * @type {number} amount
	 */
	amount;
	/**
	 * @type {string} description
	 */
	description;
	/**
	 * @type {Date} dateRequested (date and time)
	 */
	dateRequested;
	/**
	 * @type {Person} requestedByPerson
	 */
	requestedByPerson;
	/**
	 * @type {Date} dateEvaluated (date and time)
	 */
	dateEvaluated;
	/**
	 * @type {boolean} approvalFlag
	 */
	approvalFlag;
	/**
	 * @type {string} evaluationReason
	 */
	evaluationReason;
	/**
	 * @type {Person} evaluatedByPerson
	 */
	evaluatedByPerson;
	/**
	 * @type {Date} datePaid (date and time)
	 */
	datePaid;
	/**
	 * @type {Date} dateCreated (date and time)
	 */
	dateCreated;
	/**
	 * only set in the response after calling get() or edit().  Can also be used when calling edit() to set/update/upload file assets
	 * @type {[FileAsset]} receiptFileAssets
	 */
	receiptFileAssets;

	/**
	 * Instantiates a new instance of Reimbursement based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {Reimbursement}
	 */
	static create(genericObject) {
		const newReimbursement = new Reimbursement();
		newReimbursement.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newReimbursement;
	}

	/**
	 * Instantiates a new array of Reimbursement based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[Reimbursement]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newReimbursementArray = [];
		genericArray.forEach(genericObject => {
			newReimbursementArray.push(Reimbursement.create(genericObject));
		});
		return newReimbursementArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('id', 'integer'),
	ModelBaseClass.createModelProperty('location', 'Location'),
	ModelBaseClass.createModelProperty('paymentId', 'integer'),
	ModelBaseClass.createModelProperty('status', 'string'),
	ModelBaseClass.createModelProperty('amount', 'float'),
	ModelBaseClass.createModelProperty('description', 'string'),
	ModelBaseClass.createModelProperty('dateRequested', 'datetime'),
	ModelBaseClass.createModelProperty('requestedByPerson', 'Person'),
	ModelBaseClass.createModelProperty('dateEvaluated', 'datetime'),
	ModelBaseClass.createModelProperty('approvalFlag', 'boolean'),
	ModelBaseClass.createModelProperty('evaluationReason', 'string'),
	ModelBaseClass.createModelProperty('evaluatedByPerson', 'Person'),
	ModelBaseClass.createModelProperty('datePaid', 'datetime'),
	ModelBaseClass.createModelProperty('dateCreated', 'datetime'),
	ModelBaseClass.createModelProperty('receiptFileAssets', '[FileAsset]'),
];

export default ReimbursementBase;
