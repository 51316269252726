import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import TenantSearchRequest from "../TenantSearchRequest";
import ModelProxyClass from "./ModelProxyClass";
import ResultParameter from "../ResultParameter";


/**
 * @class TenantSearchRequestBase
 * @extends ModelBaseClass
 * @property {string} smartFilter
 * @property {boolean} includeInactiveFlag
 * @property {ResultParameter} resultParameter [Name]
 */
class TenantSearchRequestBase extends ModelBaseClass {
	/**
	 * @type {string} smartFilter
	 */
	smartFilter;
	/**
	 * @type {boolean} includeInactiveFlag
	 */
	includeInactiveFlag;
	/**
	 * [Name]
	 * @type {ResultParameter} resultParameter
	 */
	resultParameter;

	/**
	 * Instantiates a new instance of TenantSearchRequest based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {TenantSearchRequest}
	 */
	static create(genericObject) {
		const newTenantSearchRequest = new TenantSearchRequest();
		newTenantSearchRequest.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newTenantSearchRequest;
	}

	/**
	 * Instantiates a new array of TenantSearchRequest based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[TenantSearchRequest]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newTenantSearchRequestArray = [];
		genericArray.forEach(genericObject => {
			newTenantSearchRequestArray.push(TenantSearchRequest.create(genericObject));
		});
		return newTenantSearchRequestArray;
	}
}

/**
 * @type {string} OrderByName
 */
TenantSearchRequestBase.OrderByName = 'name';

const _modelDefinition = [
	ModelBaseClass.createModelProperty('smartFilter', 'string'),
	ModelBaseClass.createModelProperty('includeInactiveFlag', 'boolean'),
	ModelBaseClass.createModelProperty('resultParameter', 'ResultParameter'),
];

export default TenantSearchRequestBase;
