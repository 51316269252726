/* eslint-disable no-use-before-define */
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { cva, cx } from 'class-variance-authority'
import PropTypes from 'prop-types'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'

import Pagination from './Pagination'

/**
 * @typedef Column
 * @property {string} [label]
 * @property {string} [name] if not declared, return entire row data to the formatter
 * @property {string} [headerClassName]
 * @property {React.CSSProperties} [headerStyle]
 * @property {string} [colClassName]
 * @property {React.CSSProperties} [colStyle]
 * @property {boolean} [sortable]
 * @property {string} [sortToken] if not declared, will use name as the sort token
 * @property {boolean} [rowData] if true, will pass the entire row data to the formatter
 * @property {(value: any) => void} [formatter] if not declared, will use the value as is
 */

/**
 * @typedef ResultParameter
 * @property {string} [resultsOrderBy] optional, should conform to the enum that should be defined within the Description of the resultParameter for the request object that is using this
 * @property {boolean} [resultsOrderAscending] optional, will default to Ascending
 * @property {number} [resultsLimitOffset] optional, will default to 0
 * @property {number} [resultsLimitCount] optional, will default to unlimited
 */

/**
 * @typedef Table
 * @property {(items: Array, totalItemCount: number) => void} bindData
 * @property {() => ResultParameter} getResultParameter
 * @property {() => void} reload function to re-query the data
 */

const headerVariants = cva(
	/* base style */
	'px-3 py-3.5 whitespace-nowrap text-left text-sm font-semibold text-gray-900',
	{
		variants: {
			isFirst: {
				true: 'pl-4 pr-3 sm:pl-3'
			},
			isLast: {
				true: 'pl-3 pr-4 sm:pr-3'
			}
		},
		defaultVariants: {}
	}
)

const colVariants = cva(
	/* base style */
	'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
	{
		variants: {
			isFirst: {
				true: 'pl-4 pr-3 sm:pl-3'
			},
			isLast: {
				true: 'pl-3 pr-4 sm:pr-3'
			}
		},
		defaultVariants: {}
	}
)

/**
 * @typedef DataGridRef
 * @property {{ reload: () => void }} current
 */

/**
 * @typedef DataGridProps
 * @property {Column[]} columns
 * @property {(table: Table) => void} queryData
 * @property {string} [smartFilter] for triggering re-query
 * @property {string} [defaultOrderBy]
 * @property {boolean} [defaultOrderAscending]
 * @property {number} [itemsPerPage]
 * @property {boolean} [striped] add striped rows
 * @property {(rowData: any) => void | string} [rowClassName] styling apply to the row, can be a callback function that passes the rowData to determine the class name. For example, (rowData) => rowData?.status === 'active' ? 'bg-green-100' : 'bg-red-100'
 * @property {(rowData: any) => void | React.CSSProperties} [rowStyle] styling apply to the row, can be a callback function that passes the rowData to determine the style. For example, (rowData) => ({ backgroundColor: rowData?.status === 'active' ? 'green' : 'red' })
 * @property {React.ReactNode} [additionalRows] additional rows to be appended at the end of the table
 */

/**
 * @type {React.ForwardRefRenderFunction<DataGridRef, DataGridProps>}
 */
const DataGrid = forwardRef(
	(
		{
			columns,
			queryData,
			smartFilter,
			defaultOrderBy,
			defaultOrderAscending,
			itemsPerPage = 25,
			striped,
			rowClassName,
			rowStyle,
			additionalRows
		},
		/** @type {DataGridRef} */
		ref
	) => {
		const [items, setItems] = useState(/** @type {Array} */ (null))
		const [totalCount, setTotalCount] = useState(0)
		const [currentPage, setCurrentPage] = useState(1)
		const [resultParameter, setResultParameter] = useState(/** @type {ResultParameter} */ (null))

		const getResultParameter = useCallback(() => {
			return resultParameter
		}, [resultParameter])

		/**
		 * @param {Array} newItems
		 * @param {number} newTotalCount
		 */
		const bindData = useCallback((newItems, newTotalCount) => {
			setItems(newItems)
			setTotalCount(newTotalCount)
		}, [])

		const reload = useCallback(() => {
			if (queryData) queryData({ bindData, getResultParameter })
		}, [bindData, getResultParameter, queryData])

		useEffect(() => {
			if (resultParameter) reload()
		}, [reload, resultParameter, smartFilter])

		useEffect(() => {
			setResultParameter(prev => ({
				...prev,
				resultsLimitCount: itemsPerPage,
				resultsLimitOffset: (currentPage - 1) * itemsPerPage,
				resultsOrderBy: defaultOrderBy,
				resultsOrderAscending:
					defaultOrderAscending !== undefined || defaultOrderAscending !== null
						? defaultOrderAscending
						: Boolean(defaultOrderBy)
			}))
		}, [currentPage, defaultOrderAscending, defaultOrderBy, itemsPerPage])

		/**
		 * @param {string} name the sorted property name
		 */
		const handleClickSorting = name => {
			/** @type {ResultParameter} */
			const newParameter = {
				...resultParameter,
				resultsOrderBy: name,
				resultsOrderAscending: !resultParameter?.resultsOrderAscending
			}
			setResultParameter(newParameter)
		}

		useImperativeHandle(ref, () => ({
			reload
		}))

		return (
			<div>
				<div className="flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table className="min-w-full divide-y divide-gray-300">
								<thead>
									<tr>
										{columns?.map((header, index) => {
											const { name, headerClassName, headerStyle, label, sortable, sortToken } =
												header
											const isFirst = index === 0
											const isLast = index === header.length - 1
											const isCurrentlyOrdered =
												(sortToken || name) === resultParameter?.resultsOrderBy

											const getSortingIcon = () => {
												if (isCurrentlyOrdered)
													return resultParameter?.resultsOrderAscending ? (
														<ChevronDownIcon className="w-4 h-4 ml-1" />
													) : (
														<ChevronUpIcon className="w-4 h-4 ml-1" />
													)
												return <ChevronUpDownIcon className="w-5 h-5 ml-1 " />
											}

											return (
												<th
													key={index}
													scope="col"
													className={headerVariants({
														className: headerClassName,
														isFirst,
														isLast
													})}
													style={headerStyle}
												>
													{!sortable ? (
														label
													) : (
														<div
															className="flex items-center cursor-pointer"
															onClick={() => handleClickSorting(sortToken || name)}
														>
															{label}
															{getSortingIcon()}
														</div>
													)}
												</th>
											)
										})}
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200">
									{items?.map((item, itemIndex) => (
										<tr
											key={itemIndex}
											className={cx(
												striped && 'even:bg-gray-50',
												typeof rowClassName === 'function' ? rowClassName(item) : rowClassName
											)}
											style={typeof rowStyle === 'function' ? rowStyle(item) : rowStyle}
										>
											{columns?.map((col, colIndex) => {
												const { formatter, name, rowData, colClassName, colStyle } = col
												const value = !name || rowData ? item : item[name]
												const content = formatter ? formatter(value) : value
												const isFirst = colIndex === 0
												const isLast = colIndex === columns.length - 1

												return (
													<td
														key={colIndex}
														className={colVariants({ className: colClassName, isFirst, isLast })}
														style={colStyle}
													>
														{content}
													</td>
												)
											})}
										</tr>
									))}

									{additionalRows}

									{(!items || items.length === 0) && (
										<tr className="h-[100px] sm:h-[150px] border-b-2 border-gray-100">
											<td
												colSpan={columns.length + 1}
												className="py-4 text-sm font-light text-center text-gray-500"
											>
												{!items ? 'Loading...' : 'No data available.'}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>

				{totalCount > 0 && (
					<Pagination
						currentPage={currentPage}
						onPageChange={setCurrentPage}
						totalCount={totalCount}
						itemsPerPage={itemsPerPage}
					/>
				)}
			</div>
		)
	}
)

DataGrid.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			name: PropTypes.string,
			headerClassName: PropTypes.string,
			headerStyle: PropTypes.object,
			colClassName: PropTypes.string,
			colStyle: PropTypes.object,
			sortable: PropTypes.bool,
			sortToken: PropTypes.string,
			rowData: PropTypes.bool,
			formatter: PropTypes.func
		})
	).isRequired,
	queryData: PropTypes.func.isRequired,
	smartFilter: PropTypes.string,
	defaultOrderBy: PropTypes.string,
	defaultOrderAscending: PropTypes.bool,
	itemsPerPage: PropTypes.number,
	striped: PropTypes.bool,
	rowClassName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	rowStyle: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	additionalRows: PropTypes.node
}

DataGrid.defaultProps = {
	itemsPerPage: 25
}

export default React.memo(DataGrid)
