import { Menu, Transition } from '@headlessui/react'
import { cva, cx } from 'class-variance-authority'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

const variants = cva(
	/* base style */
	'',
	{
		variants: {},
		defaultVariants: {}
	}
)

/**
 * @typedef Item
 * @property {React.ReactNode} label
 * @property {() => void} onClick
 * @property {boolean} [disabled]
 */

/**
 * @typedef DropdownMenuProps
 * @property {React.ReactNode} button the button that triggers show/hide the dropdown menu
 * @property {Item[]} [items]
 */

/**
 * @param {DropdownMenuProps} props
 */
const DropdownMenu = ({ button, items = [] }) => {
	return (
		<Menu as="div" className="relative ml-3">
			<div className="flex">
				<Menu.Button as="div">{button}</Menu.Button>
			</div>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100 z-10"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="px-1 py-1">
						{items?.map((item, index) => (
							<Menu.Item key={index} disabled={item.disabled}>
								{({ active }) => (
									<button
										className={cx(
											active ? 'bg-primary-600 text-white' : 'text-gray-900',
											'group flex w-full items-center rounded-md px-2 py-2 text-sm'
										)}
										onClick={item.onClick}
									>
										{item.label}
									</button>
								)}
							</Menu.Item>
						))}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	)
}

DropdownMenu.propTypes = {
	button: PropTypes.node,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.node,
			onClick: PropTypes.func,
			disabled: PropTypes.bool
		})
	)
}

DropdownMenu.defaultProps = {}

export default DropdownMenu
