import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import OwnerDetails from "../OwnerDetails";
import ModelProxyClass from "./ModelProxyClass";
import Location from "../Location";
import LocationGroup from "../LocationGroup";


/**
 * @class OwnerDetailsBase
 * @extends ModelBaseClass
 * @property {number} tenantId (int64)
 * @property {boolean} ownerReceivesEmailReportsFlag
 * @property {string} phone
 * @property {[Location]} locations only set when calling PersonApi::ownerGetViaEmail() or PersonApi::get().  This will return ALL Locations that the owner is part of -- both via direct association, as well as via any locationGroups that the owner is associated with
 * @property {[Location]} directLocations only set when calling PersonApi::ownerGetViaEmail() or PersonApi::get().  This will ONLY return Locations that the owner is DIRECTLY associated with.  It will not include Locations that are associated via associated LocationGroups.  Also can be used by PersonApi::ownerSetLocationsAndGroups().
 * @property {[LocationGroup]} locationGroups only set when calling PersonApi::ownerGetViaEmail() or PersonApi::get().  Also can be used by PersonApi::ownerSetLocationsAndGroups()
 */
class OwnerDetailsBase extends ModelBaseClass {
	/**
	 * @type {number} tenantId (int64)
	 */
	tenantId;
	/**
	 * @type {boolean} ownerReceivesEmailReportsFlag
	 */
	ownerReceivesEmailReportsFlag;
	/**
	 * @type {string} phone
	 */
	phone;
	/**
	 * only set when calling PersonApi::ownerGetViaEmail() or PersonApi::get().  This will return ALL Locations that the owner is part of -- both via direct association, as well as via any locationGroups that the owner is associated with
	 * @type {[Location]} locations
	 */
	locations;
	/**
	 * only set when calling PersonApi::ownerGetViaEmail() or PersonApi::get().  This will ONLY return Locations that the owner is DIRECTLY associated with.  It will not include Locations that are associated via associated LocationGroups.  Also can be used by PersonApi::ownerSetLocationsAndGroups().
	 * @type {[Location]} directLocations
	 */
	directLocations;
	/**
	 * only set when calling PersonApi::ownerGetViaEmail() or PersonApi::get().  Also can be used by PersonApi::ownerSetLocationsAndGroups()
	 * @type {[LocationGroup]} locationGroups
	 */
	locationGroups;

	/**
	 * Instantiates a new instance of OwnerDetails based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {OwnerDetails}
	 */
	static create(genericObject) {
		const newOwnerDetails = new OwnerDetails();
		newOwnerDetails.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newOwnerDetails;
	}

	/**
	 * Instantiates a new array of OwnerDetails based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[OwnerDetails]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newOwnerDetailsArray = [];
		genericArray.forEach(genericObject => {
			newOwnerDetailsArray.push(OwnerDetails.create(genericObject));
		});
		return newOwnerDetailsArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('tenantId', 'integer'),
	ModelBaseClass.createModelProperty('ownerReceivesEmailReportsFlag', 'boolean'),
	ModelBaseClass.createModelProperty('phone', 'string'),
	ModelBaseClass.createModelProperty('locations', '[Location]'),
	ModelBaseClass.createModelProperty('directLocations', '[Location]'),
	ModelBaseClass.createModelProperty('locationGroups', '[LocationGroup]'),
];

export default OwnerDetailsBase;
