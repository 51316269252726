const routePaths = {
	auth: {
		login: '/login',
		forgotPassword: '/forgot-password',
		changePassword: '/forgot-password/:id/:hash/:checksum',
		registration: '/new-registration/:id/:hash/:checksum',
		noPermission: '/no-permission',
	},
	settings: {
		base: '/settings',
		profile: '/settings/profile',
		password: '/settings/password',
	},
	dashboard: '/dashboard',
	financials: '/financials',
	reimbursements: '/reimbursements',
	performanceDetails: '/performance-details',
	machines: '/machines',
}

export default routePaths
