import { cva, cx } from 'class-variance-authority'
import PropTypes from 'prop-types'
import { useId } from 'react'

const variants = cva(
	/* base style */
	'block cursor-pointer w-full text-sm text-gray-900 file:cursor-pointer file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-primary-300 file:text-primary-900 hover:file:bg-gray-200 !outline-none',
	{
		variants: {
			invalid: {
				true: 'file:border-danger-600 file:bg-danger-100 file:text-danger-600 hover:file:bg-danger-200'
			},
			pill: {
				true: '!rounded-full px-4'
			}
		},
		defaultVariants: {}
	}
)

/**
 * @typedef FileUploadProps
 * @property {string} [className]
 * @property {string} [name]
 * @property {string} [label]
 * @property {React.ReactNode} [helperText] Helper text to display below the label (hide when there's error message)
 * @property {boolean} [disabled]
 * @property {boolean} [invalid]
 * @property {string} [errorMessage]
 * @property {boolean} [pill]
 */

/**
 * @param {FileUploadProps & React.InputHTMLAttributes} props
 */
const FileUpload = ({
	className,
	name,
	label,
	helperText,
	cornerHint,
	disabled,
	invalid,
	errorMessage,
	pill,
	...props
}) => {
	const randomId = useId()
	const descriptionId = `${name || randomId}-description`

	const hasLabel = !!label
	const hasError = invalid && errorMessage

	return (
		<div className="w-full">
			{hasLabel && (
				<div className={cx('flex justify-between', pill && 'px-4')}>
					<label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
						{label}
					</label>
					{cornerHint && (
						<span className="text-xs leading-6 text-gray-500" id={descriptionId}>
							{cornerHint}
						</span>
					)}
				</div>
			)}

			<label className={cx('block', hasLabel && 'mt-2')}>
				<span className="sr-only">Choose photo</span>
				<input
					className={variants({ invalid, pill })}
					type="file"
					disabled={disabled}
					aria-invalid={invalid}
					aria-describedby={descriptionId}
					{...props}
				/>
			</label>

			{/* hide helper text when there's error message */}
			{helperText && !hasError && (
				<p className={cx('mt-2 text-sm text-gray-500', pill && 'px-4')} id={descriptionId}>
					{helperText}
				</p>
			)}

			{hasError && (
				<p className={cx('mt-2 text-sm text-danger-600', pill && 'px-4')} id={descriptionId}>
					{errorMessage}
				</p>
			)}
		</div>
	)
}

FileUpload.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.node,
	helperText: PropTypes.string,
	cornerHint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	disabled: PropTypes.bool,
	invalid: PropTypes.bool,
	errorMessage: PropTypes.string,
	pill: PropTypes.bool
}

FileUpload.defaultProps = {}

export default FileUpload
