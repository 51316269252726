import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import UserDetails from "../UserDetails";
import ModelProxyClass from "./ModelProxyClass";
import TenantMembership from "../TenantMembership";


/**
 * @class UserDetailsBase
 * @extends ModelBaseClass
 * @property {[TenantMembership]} tenantMemberships
 */
class UserDetailsBase extends ModelBaseClass {
	/**
	 * @type {[TenantMembership]} tenantMemberships
	 */
	tenantMemberships;

	/**
	 * Instantiates a new instance of UserDetails based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {UserDetails}
	 */
	static create(genericObject) {
		const newUserDetails = new UserDetails();
		newUserDetails.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newUserDetails;
	}

	/**
	 * Instantiates a new array of UserDetails based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[UserDetails]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newUserDetailsArray = [];
		genericArray.forEach(genericObject => {
			newUserDetailsArray.push(UserDetails.create(genericObject));
		});
		return newUserDetailsArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('tenantMemberships', '[TenantMembership]'),
];

export default UserDetailsBase;
