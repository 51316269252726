import RevenueReimbursementSearchRequestBase from './base/RevenueReimbursementSearchRequestBase';

/**
 * @class RevenueReimbursementSearchRequest
 * @extends RevenueReimbursementSearchRequestBase
 */
class RevenueReimbursementSearchRequest extends RevenueReimbursementSearchRequestBase {

}

export default RevenueReimbursementSearchRequest;
