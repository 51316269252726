/**
 * @param {number | string} number
 * @param {{ minDigits?: number, maxDigits?: number }} [options] default to { minDigits: 2, maxDigits: 2 }
 * @returns format number as USD format. ex: $1,234
 */
export default function numberToUsd(number, options) {
	if (number === undefined || number === null) return ''
	const { maxDigits = 2, minDigits = 2 } = options ?? {}
	return Number(number).toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: minDigits,
		maximumFractionDigits: maxDigits,
	})
}
