import ClientBaseClass from "./ClientBaseClass";
import RevenuePerformanceOverTimeResponse from "../../models/RevenuePerformanceOverTimeResponse";
import RevenueSummaryResponse from "../../models/RevenueSummaryResponse";
import RevenuePaymentSearchResponse from "../../models/RevenuePaymentSearchResponse";
import Payment from "../../models/Payment";
import RevenuePayPeriodSearchResponse from "../../models/RevenuePayPeriodSearchResponse";
import PayPeriod from "../../models/PayPeriod";
import RevenueReimbursementSearchResponse from "../../models/RevenueReimbursementSearchResponse";
import Reimbursement from "../../models/Reimbursement";

export default class RevenueApi extends ClientBaseClass {
	/**
	 * [RevenueUser, OwnerPerson] gets the dashboard items for a location given the query
	 * @param {string} locationId
	 * @param {string} dateFrom
	 * @param {string} dateTo
	 * @param {{status200: function(RevenuePerformanceOverTimeResponse), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	getPerformanceOverTime(locationId, dateFrom, dateTo, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/dashboard/performance_over_time/' +
			(locationId ? encodeURI(locationId) : '') + '/' +
			(dateFrom ? encodeURI(dateFrom) : '') + '/' +
			(dateTo ? encodeURI(dateTo) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(RevenuePerformanceOverTimeResponse.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueUser, OwnerPerson] gets the dashboard items for a location given the query
	 * @param {string} locationId
	 * @param {string} dateFrom
	 * @param {string} dateTo
	 * @param {{status200: function(RevenueSummaryResponse), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	getRevenueSummary(locationId, dateFrom, dateTo, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/dashboard/revenue_summary/' +
			(locationId ? encodeURI(locationId) : '') + '/' +
			(dateFrom ? encodeURI(dateFrom) : '') + '/' +
			(dateTo ? encodeURI(dateTo) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(RevenueSummaryResponse.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueUser, OwnerPerson] to search for payments
	 * @param {RevenuePaymentSearchRequest} request
	 * @param {{status200: function(RevenuePaymentSearchResponse), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	searchPayments(request, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/payment/search';

		// noinspection Duplicates
		this.executeApiCall(url, 'post', request, 'json', options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(RevenuePaymentSearchResponse.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueUser, OwnerPerson] gets the payment
	 * @param {string} paymentId
	 * @param {{status200: function(Payment), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	getPayment(paymentId, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/payment/get/' +
			(paymentId ? encodeURI(paymentId) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(Payment.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueAdmin] edits date paid info for a payment.  id is required.  Date Paid can be set or null (to clear)
	 * @param {Payment} request
	 * @param {{status200: function(Payment), status403: function(string), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	editPayment(request, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/payment/edit';

		// noinspection Duplicates
		this.executeApiCall(url, 'post', request, 'json', options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(Payment.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 403:
					if (responseHandler.status403) {
						response.text()
							.then(responseText => {
								responseHandler.status403(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueAdmin, OwnerPerson] to search for pay periods
	 * @param {RevenuePayPeriodSearchRequest} request
	 * @param {{status200: function(RevenuePayPeriodSearchResponse), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	searchPayPeriods(request, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/pay_period/search';

		// noinspection Duplicates
		this.executeApiCall(url, 'post', request, 'json', options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(RevenuePayPeriodSearchResponse.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueAdmin] gets the pay period
	 * @param {string} payPeriodId
	 * @param {{status200: function(PayPeriod), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	getPayPeriod(payPeriodId, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/pay_period/get/' +
			(payPeriodId ? encodeURI(payPeriodId) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(PayPeriod.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueUser, OwnerPerson] to search for reimbursements
	 * @param {RevenueReimbursementSearchRequest} request
	 * @param {{status200: function(RevenueReimbursementSearchResponse), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	searchReimbursements(request, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/reimbursement/search';

		// noinspection Duplicates
		this.executeApiCall(url, 'post', request, 'json', options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(RevenueReimbursementSearchResponse.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueUser, OwnerPerson] gets the reimbursement
	 * @param {string} reimbursementId
	 * @param {{status200: function(Reimbursement), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	getReimbursement(reimbursementId, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/reimbursement/get/' +
			(reimbursementId ? encodeURI(reimbursementId) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(Reimbursement.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [OwnerPerson] deletes a reimbursement (only if in Requested status)
	 * @param {string} reimbursementId
	 * @param {{status200: function(string), status404: function(string), status409: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	deleteReimbursement(reimbursementId, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/reimbursement/delete/' +
			(reimbursementId ? encodeURI(reimbursementId) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'delete', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.text()
							.then(responseText => {
								responseHandler.status200(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 409:
					if (responseHandler.status409) {
						response.text()
							.then(responseText => {
								responseHandler.status409(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [OwnerPerson] creates or updates a reimbursement.  Can only be done on new reimbursements (if id is null), or if existing, id must be set and Reimbursement must already be in either Declined or Requested status _only_.  Calling this will always set the Reimbursement to Requested status.
	 * @param {Reimbursement} request
	 * @param {{status200: function(Reimbursement), status403: function(string), status404: function(string), status409: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	saveReimbursement(request, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/reimbursement/save';

		// noinspection Duplicates
		this.executeApiCall(url, 'post', request, 'json', options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(Reimbursement.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 403:
					if (responseHandler.status403) {
						response.text()
							.then(responseText => {
								responseHandler.status403(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 409:
					if (responseHandler.status409) {
						response.text()
							.then(responseText => {
								responseHandler.status409(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueUser] sets a reimbursement's approval flag.  Can only be done on existing reimbursements with a status of Requested.  id and approvalFlag is required.  evaluationReason is optional.  All other fields are ignored.  To undo a prior approval, set approvalFlag to null.
	 * @param {Reimbursement} request
	 * @param {{status200: function(Reimbursement), status403: function(string), status404: function(string), status409: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	evaluateReimbursement(request, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/revenue/reimbursement/evaluate';

		// noinspection Duplicates
		this.executeApiCall(url, 'post', request, 'json', options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(Reimbursement.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 403:
					if (responseHandler.status403) {
						response.text()
							.then(responseText => {
								responseHandler.status403(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 409:
					if (responseHandler.status409) {
						response.text()
							.then(responseText => {
								responseHandler.status409(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

}
