import { Field, useField } from 'formik'
import PropTypes from 'prop-types'

import FileUpload from '../../Forms/FileUpload'

/**
 * @param {import('../../Forms/FileUpload').FileUploadProps} props
 */
const FormikFileUpload = props => {
	const [field, meta, helpers] = useField(props)
	const { error, touched } = meta
	const hasError = error && touched

	// TODO: fileAsset default value handling

	return <Field as={FileUpload} {...props} type="file" invalid={hasError} errorMessage={error} />
}

FormikFileUpload.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.node,
	helperText: PropTypes.string,
	cornerHint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	disabled: PropTypes.bool,
	pill: PropTypes.bool
}

FormikFileUpload.defaultProps = {}

export default FormikFileUpload
