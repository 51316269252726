import { useGlobal } from 'contexts/global-context'
import { Formik, FormikHelpers } from 'formik'
import { Button } from 'shared/catalyst-components/button'
import Client from 'shared/clients/base/Client'
import SettingPageLayout from 'shared/layout/SettingPageLayout'
import UpdatePasswordRequest from 'shared/models/UpdatePasswordRequest'
import { FormikText } from 'ui-toolkit-tailwind/src/components'
import { showToast } from 'utilities/showToast'
import * as Yup from 'yup'

const { Form, Col, Actions, Section } = SettingPageLayout

const ChangePasswordForm = () => {
	const { setShowSpinner } = useGlobal()

	const validateSchema = Yup.object().shape({
		currentPassword: Yup.string().required('Current password is required'),
		newPassword: Yup.string().required('New password is required'),
		confirmPassword: Yup.string()
			.required('Confirm password is required')
			.oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
	})

	/**
	 * @param {{ currentPassword: string, newPassword: string }} values
	 * @param {FormikHelpers} formikHelpers
	 */
	const handleSubmit = (values, { resetForm }) => {
		const request = UpdatePasswordRequest.create({
			currentPassword: values.currentPassword,
			newPassword: values.newPassword,
		})
		Client.PersonApi.updatePassword(
			request,
			{
				status200: () => {
					showToast({
						intent: 'success',
						title: 'Success',
						content: 'Password updated successfully',
					})
					resetForm()
				},
				status409: error => {
					showToast({
						intent: 'error',
						title: 'Error',
						content: error,
					})
				},
			},
			{
				onApiCall: () => setShowSpinner(true),
				onApiResponse: () => setShowSpinner(false),
			},
		)
	}

	return (
		<Section title="Change Password">
			<Formik
				initialValues={{ currentPassword: '', newPassword: '', confirmPassword: '' }}
				validationSchema={validateSchema}
				onSubmit={handleSubmit}
			>
				<Form>
					<Col>
						<FormikText
							name="currentPassword"
							label="Current Password"
							type="password"
							autoComplete="current-password"
						/>
					</Col>
					<Col>
						<FormikText
							name="newPassword"
							label="New Password"
							type="password"
							autoComplete="new-password"
						/>
					</Col>
					<Col>
						<FormikText
							name="confirmPassword"
							label="Confirm Password"
							type="password"
							autoComplete="new-password"
						/>
					</Col>
					<Actions>
						<Button type="submit" className="text-xs">
							Save
						</Button>
					</Actions>
				</Form>
			</Formik>
		</Section>
	)
}

export default ChangePasswordForm
