import { cva, cx } from 'class-variance-authority'
import PropTypes from 'prop-types'
import { useEffect, useId, useRef } from 'react'

const variants = cva(
	/* base style */
	'h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600',
	{
		variants: {
			invalid: {
				true: '!text-danger-600 !border-danger-500 focus:!ring-danger-500',
			},
			disabled: {
				true: 'cursor-not-allowed opacity-60',
			},
		},
		defaultVariants: {},
	},
)

const updateInput = (ref, checked) => {
	const input = ref.current
	if (input) {
		input.checked = checked
		input.indeterminate = checked == null
	}
}

/**
 * @typedef CheckboxProps
 * @property {string} [className]
 * @property {string} name
 * @property {string} label
 * @property {boolean | 'indeterminate'} [value]
 * @property {string} [helperText]
 * @property {boolean} [disabled]
 * @property {boolean} [invalid]
 * @property {string} [errorMessage]
 * @property {(value: boolean | 'indeterminate') => void} [onChange]
 */

/**
 * @param {CheckboxProps & React.InputHTMLAttributes} props
 */
const ThreeStateCheckbox = ({
	className,
	name,
	label,
	value,
	helperText,
	disabled,
	invalid,
	errorMessage,
	onChange,
	...props
}) => {
	const randomId = useId()
	const hasError = invalid && errorMessage

	const inputRef = useRef(null)
	const checkedRef = useRef(value)

	useEffect(() => {
		checkedRef.current = value
		updateInput(inputRef, value)
	}, [value])

	const handleClick = () => {
		switch (checkedRef.current) {
			case true:
				checkedRef.current = false
				break
			case false:
				checkedRef.current = null
				break
			default: // null
				checkedRef.current = true
				break
		}
		updateInput(inputRef, checkedRef.current)
		if (onChange) {
			onChange(checkedRef.current)
		}
	}

	return (
		<div className={cx('w-full', className)}>
			<div className="relative flex items-start">
				<div className="flex items-center h-6">
					<input
						ref={inputRef}
						id={name}
						aria-describedby={randomId}
						name={name}
						type="checkbox"
						className={variants({ disabled, invalid })}
						disabled={disabled}
						checked={value}
						onChange={handleClick}
						{...props}
					/>
				</div>
				<div className="ml-3 text-sm leading-6">
					<label htmlFor={name} className="font-medium text-gray-900">
						{label}
					</label>

					{helperText && !hasError && (
						<p id={randomId} className="text-gray-500">
							{helperText}
						</p>
					)}

					{hasError && <p className="mt-1 text-sm text-danger-600">{errorMessage}</p>}
				</div>
			</div>
		</div>
	)
}

ThreeStateCheckbox.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.node,
	helperText: PropTypes.string,
	value: PropTypes.bool,
	disabled: PropTypes.bool,
	invalid: PropTypes.bool,
	errorMessage: PropTypes.string,
	onChange: PropTypes.func,
}

ThreeStateCheckbox.defaultProps = {}

export default ThreeStateCheckbox
