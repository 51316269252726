import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import RevenuePayPeriodSearchRequest from "../RevenuePayPeriodSearchRequest";
import ModelProxyClass from "./ModelProxyClass";
import ResultParameter from "../ResultParameter";


/**
 * @class RevenuePayPeriodSearchRequestBase
 * @extends ModelBaseClass
 * @property {number} tenantId (int64) required
 * @property {number} locationId (int64) optional
 * @property {ResultParameter} resultParameter [DatePeriodStart, DatePeriodEnd]
 */
class RevenuePayPeriodSearchRequestBase extends ModelBaseClass {
	/**
	 * required
	 * @type {number} tenantId (int64)
	 */
	tenantId;
	/**
	 * optional
	 * @type {number} locationId (int64)
	 */
	locationId;
	/**
	 * [DatePeriodStart, DatePeriodEnd]
	 * @type {ResultParameter} resultParameter
	 */
	resultParameter;

	/**
	 * Instantiates a new instance of RevenuePayPeriodSearchRequest based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {RevenuePayPeriodSearchRequest}
	 */
	static create(genericObject) {
		const newRevenuePayPeriodSearchRequest = new RevenuePayPeriodSearchRequest();
		newRevenuePayPeriodSearchRequest.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newRevenuePayPeriodSearchRequest;
	}

	/**
	 * Instantiates a new array of RevenuePayPeriodSearchRequest based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[RevenuePayPeriodSearchRequest]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newRevenuePayPeriodSearchRequestArray = [];
		genericArray.forEach(genericObject => {
			newRevenuePayPeriodSearchRequestArray.push(RevenuePayPeriodSearchRequest.create(genericObject));
		});
		return newRevenuePayPeriodSearchRequestArray;
	}
}

/**
 * @type {string} OrderByDatePeriodStart
 */
RevenuePayPeriodSearchRequestBase.OrderByDatePeriodStart = 'dateperiodstart';

/**
 * @type {string} OrderByDatePeriodEnd
 */
RevenuePayPeriodSearchRequestBase.OrderByDatePeriodEnd = 'dateperiodend';

const _modelDefinition = [
	ModelBaseClass.createModelProperty('tenantId', 'integer'),
	ModelBaseClass.createModelProperty('locationId', 'integer'),
	ModelBaseClass.createModelProperty('resultParameter', 'ResultParameter'),
];

export default RevenuePayPeriodSearchRequestBase;
