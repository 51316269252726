import { Tooltip } from 'ui-toolkit-tailwind/src/components'

/**
 * @typedef TooltipProps
 * @property {string} message
 */

/**
 * @param {TooltipProps} props
 */
const ButtonTooltip = ({ message, children }) => {
	return (
		<Tooltip message={message} className="!bottom-10">
			{children}
		</Tooltip>
	)
}

export default ButtonTooltip
