import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import RevenuePayPeriodSearchResponse from "../RevenuePayPeriodSearchResponse";
import ModelProxyClass from "./ModelProxyClass";
import PayPeriod from "../PayPeriod";


/**
 * @class RevenuePayPeriodSearchResponseBase
 * @extends ModelBaseClass
 * @property {[PayPeriod]} payPeriods
 * @property {number} totalItemCount (int64)
 */
class RevenuePayPeriodSearchResponseBase extends ModelBaseClass {
	/**
	 * @type {[PayPeriod]} payPeriods
	 */
	payPeriods;
	/**
	 * @type {number} totalItemCount (int64)
	 */
	totalItemCount;

	/**
	 * Instantiates a new instance of RevenuePayPeriodSearchResponse based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {RevenuePayPeriodSearchResponse}
	 */
	static create(genericObject) {
		const newRevenuePayPeriodSearchResponse = new RevenuePayPeriodSearchResponse();
		newRevenuePayPeriodSearchResponse.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newRevenuePayPeriodSearchResponse;
	}

	/**
	 * Instantiates a new array of RevenuePayPeriodSearchResponse based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[RevenuePayPeriodSearchResponse]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newRevenuePayPeriodSearchResponseArray = [];
		genericArray.forEach(genericObject => {
			newRevenuePayPeriodSearchResponseArray.push(RevenuePayPeriodSearchResponse.create(genericObject));
		});
		return newRevenuePayPeriodSearchResponseArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('payPeriods', '[PayPeriod]'),
	ModelBaseClass.createModelProperty('totalItemCount', 'integer'),
];

export default RevenuePayPeriodSearchResponseBase;
