import { numberToUsd } from 'shared/utilities/helpers'

export default function displayAmount(amount) {
	if (typeof amount !== 'number') return '-'

	const isNegative = amount < 0

	return (
		<span className={isNegative ? 'text-danger-700' : ''}>
			{isNegative ? `(${numberToUsd(-1 * amount)})` : numberToUsd(amount)}
		</span>
	)
}
