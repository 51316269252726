/* eslint-disable react/prop-types */
import localStorageKeys from 'constants/localStorageKeys'
import useLocalStorage from 'hooks/useLocalStorage'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Client, { ignoreResponse } from 'shared/clients/base/Client'
import DefaultClientOptions from 'shared/clients/DefaultClientOptions'
import Location from 'shared/models/Location'
import Person from 'shared/models/Person'
import Session from 'shared/models/Session'
import SystemSettings from 'shared/models/SystemSettings'
import Tenant from 'shared/models/Tenant'
import checkCookieSession from 'shared/utilities/checkCookieSession'

import { useGlobal } from './global-context'

export const CoreContext = createContext({
	isAppReady: false,
	isLoggedIn: false,
	initialize: () => {},
	executeLogout: () => {},
	/** @type {Session} */
	session: null,
	/**
	 * @param {Session} session
	 */
	updateSession: session => {},
	/**
	 * @param {function} callback callback after refreshing session successfully
	 */
	refreshSession: callback => {},
	/** @type {Person} */
	person: null,
	/** @type {Tenant} */
	tenant: null,
	/** @type {HTMLImageElement} */
	tenantLogo: null,
	/** @type {Location[]} */
	locations: [],
	/** @type {Location} */
	location: null,
	/**
	 * @param {number} locationId
	 */
	updateLocationId: locationId => {},
})

const CoreProvider = props => {
	const { setShowSpinner } = useGlobal()

	const [systemSettings, setSystemSettings] = useState(/** @type {SystemSettings} */ (null))
	const [hasInitSession, setHasInitSession] = useState(false)
	const [session, setSession, removeSession] = useLocalStorage(localStorageKeys.session)

	// tenant state
	const [tenant, setTenant] = useState(/** @type {Tenant} */ (null))
	const [tenantLogo, setTenantLogo] = useState(/** @type {HTMLImageElement} */ (null))

	// location state
	const [locations, setLocations] = useState(/** @type {Location[]} */ ([]))
	const [locationId, setLocationId] = useState(/** @type {number} */ (null))
	const [location, setLocation] = useState(/** @type {Location} */ (null))

	const [devDomain, setDevDomain] = useLocalStorage(localStorageKeys.devDomain)

	const domain = window.location.hostname

	const fetchTenant = useCallback(() => {
		if (!domain) return

		let tenantDomain = domain
		if (process.env.NODE_ENV === 'development') {
			tenantDomain = window.prompt('Enter TO domain', devDomain)
			setDevDomain(tenantDomain)
		}

		Client.TenantApi.get(tenantDomain, {
			status200: response => {
				setTenant(response)
				// reload logo and save it in state
				const logoUrl = response.logoFileAsset?.viewUrl
				const logoImage = new Image()
				if (logoUrl) logoImage.src = `${DefaultClientOptions.getEndpointUrl()}/${logoUrl}`
				else logoImage.src = 'https://picsum.photos/300/200'

				logoImage.onload = () => {
					setTenantLogo(logoImage)
				}
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [domain])

	useEffect(fetchTenant, [fetchTenant])

	const updateLocationId = id => {
		window.localStorage.setItem(localStorageKeys.storedLocationId, String(id))
		setLocationId(id)
	}

	const queryLocations = useCallback(() => {
		if (tenant && session && hasInitSession) {
			Client.PersonApi.get(session.person.id, {
				status200: person => {
					const sortedLocations = person.ownerDetails.locations?.sort((a, b) =>
						a.name.localeCompare(b.name),
					)
					setLocations(sortedLocations)

					const storedLocationId = window.localStorage.getItem(localStorageKeys.storedLocationId)
					// check if saved locationId is valid, if not, set to default
					if (storedLocationId && sortedLocations.findIndex(l => l.id === +storedLocationId) > -1) {
						setLocationId(+storedLocationId)
					} else {
						const defaultLocation = sortedLocations[0]
						if (defaultLocation) {
							updateLocationId(defaultLocation.id)
						}
					}
				},
			})
		}
		// only need to check locationId the first time queryLocations is called
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenant, session, hasInitSession])

	useEffect(queryLocations, [queryLocations])

	useEffect(() => {
		if (locationId) {
			Client.LocationApi.get(
				String(locationId),
				{
					status200: setLocation,
				},
				{
					onApiCall: () => setShowSpinner(true),
					onApiResponse: () => setShowSpinner(false),
				},
			)
		}
	}, [locationId, setShowSpinner])

	const initSystemSettings = () => {
		Client.UtilityApi.getSystemSettings({
			status200: setSystemSettings,
			error: ignoreResponse,
			else: ignoreResponse,
		})
	}

	// once session is set, initialize system settings
	useEffect(() => {
		if (!systemSettings && session && hasInitSession) {
			initSystemSettings()
		}
	}, [session, systemSettings, hasInitSession])

	/**
	 * @param {function} callback callback after refreshing session successfully
	 */
	const getSession = callback => {
		// verify session
		const id = session.id
		const hash = session.hash
		if (id && hash) {
			const identifier = `${id}-${hash}`
			Client.AuthenticationApi.getSession('owners_portal', identifier, {
				status200: _session => {
					setSession(_session)
					if (callback) callback()
				},
				status404: removeSession,
			})
		}
	}

	const initSession = () => {
		if (session) {
			if (!checkCookieSession()) removeSession()
			getSession(() => setHasInitSession(true))
		} else {
			setHasInitSession(true)
		}
	}

	// app initialization
	const initialize = () => {
		initSession()
	}

	const executeLogout = () => {
		Client.AuthenticationApi.logout({
			status200: ignoreResponse,
			status403: ignoreResponse,
			error: ignoreResponse,
			else: ignoreResponse,
		})
		removeSession()
		// reset location state
		setLocationId(null)
		setLocation(null)
		setLocations([])
	}

	const contextValue = useMemo(
		() => ({
			isAppReady: hasInitSession && !!tenant && !!tenantLogo,
			session,
			person: session?.person,
			tenant,
			tenantLogo,
			locations,
			location,
			updateLocationId,
			isLoggedIn: !!session,
			initialize,
			updateSession: setSession,
			refreshSession: getSession,
			executeLogout,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[session, hasInitSession, tenant, tenantLogo, locations, location],
	)
	return <CoreContext.Provider value={contextValue}>{props.children}</CoreContext.Provider>
}

export const useCore = () => useContext(CoreContext)
export default CoreProvider
