import RevenuePayPeriodSearchRequestBase from './base/RevenuePayPeriodSearchRequestBase';

/**
 * @class RevenuePayPeriodSearchRequest
 * @extends RevenuePayPeriodSearchRequestBase
 */
class RevenuePayPeriodSearchRequest extends RevenuePayPeriodSearchRequestBase {

}

export default RevenuePayPeriodSearchRequest;
