import PersonSearchResponseBase from './base/PersonSearchResponseBase';

/**
 * @class PersonSearchResponse
 * @extends PersonSearchResponseBase
 */
class PersonSearchResponse extends PersonSearchResponseBase {

}

export default PersonSearchResponse;
