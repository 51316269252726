import { cx } from 'class-variance-authority'

const Card = ({ className, children, ...props }) => {
	return (
		<div
			className={cx(
				'relative w-full h-full p-6 bg-white rounded-lg shadow sm:p-8 lg:p-12 forced-colors:outline',
				className,
			)}
			{...props}
		>
			{children}
		</div>
	)
}

export default Card
