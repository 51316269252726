import ResultParameterBase from './base/ResultParameterBase';

/**
 * @class ResultParameter
 * @extends ResultParameterBase
 */
class ResultParameter extends ResultParameterBase {

}

export default ResultParameter;
