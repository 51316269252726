import PersonSearchRequestBase from './base/PersonSearchRequestBase';

/**
 * @class PersonSearchRequest
 * @extends PersonSearchRequestBase
 */
class PersonSearchRequest extends PersonSearchRequestBase {

}

export default PersonSearchRequest;
