import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import TenantSearchResponse from "../TenantSearchResponse";
import ModelProxyClass from "./ModelProxyClass";
import Tenant from "../Tenant";


/**
 * @class TenantSearchResponseBase
 * @extends ModelBaseClass
 * @property {[Tenant]} tenants
 * @property {number} totalItemCount (int64)
 */
class TenantSearchResponseBase extends ModelBaseClass {
	/**
	 * @type {[Tenant]} tenants
	 */
	tenants;
	/**
	 * @type {number} totalItemCount (int64)
	 */
	totalItemCount;

	/**
	 * Instantiates a new instance of TenantSearchResponse based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {TenantSearchResponse}
	 */
	static create(genericObject) {
		const newTenantSearchResponse = new TenantSearchResponse();
		newTenantSearchResponse.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newTenantSearchResponse;
	}

	/**
	 * Instantiates a new array of TenantSearchResponse based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[TenantSearchResponse]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newTenantSearchResponseArray = [];
		genericArray.forEach(genericObject => {
			newTenantSearchResponseArray.push(TenantSearchResponse.create(genericObject));
		});
		return newTenantSearchResponseArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('tenants', '[Tenant]'),
	ModelBaseClass.createModelProperty('totalItemCount', 'integer'),
];

export default TenantSearchResponseBase;
