import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { ArrowDownTrayIcon, EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Button } from 'shared/catalyst-components/button'
import {
	Dropdown,
	DropdownButton,
	DropdownItem,
	DropdownMenu,
} from 'shared/catalyst-components/dropdown'
import ButtonTooltip from '../ButtonTooltip'

/**
 * @typedef ActionButtonProps
 * @property {string} [tooltipMessage]
 */

/**
 * @param {ActionButtonProps & React.ButtonHTMLAttributes} props
 */
const Edit = ({ tooltipMessage, ...props }) => {
	return (
		<ButtonTooltip message={tooltipMessage}>
			<Button {...props} plain>
				<PencilIcon />
			</Button>
		</ButtonTooltip>
	)
}

/**
 * @param {ActionButtonProps & React.ButtonHTMLAttributes} props
 */
const View = ({ tooltipMessage, ...props }) => {
	return (
		<ButtonTooltip message={tooltipMessage}>
			<Button {...props} plain>
				<EyeIcon />
			</Button>
		</ButtonTooltip>
	)
}

/**
 * @param {ActionButtonProps & React.ButtonHTMLAttributes} props
 */
const Download = ({ tooltipMessage, ...props }) => {
	return (
		<ButtonTooltip message={tooltipMessage}>
			<Button {...props} plain>
				<ArrowDownTrayIcon />
			</Button>
		</ButtonTooltip>
	)
}

/**
 * @param {ActionButtonProps & React.ButtonHTMLAttributes} props
 */
const Delete = ({ tooltipMessage, ...props }) => {
	return (
		<ButtonTooltip message={tooltipMessage}>
			<Button {...props} plain>
				<TrashIcon />
			</Button>
		</ButtonTooltip>
	)
}

const menuItemEnum = {
	VIEW: 'view',
	EDIT: 'edit',
	DELETE: 'delete',
	DOWNLOAD: 'download',
	CUSTOM: 'custom',
}

const menuItems = [
	{ label: 'View', key: 'view', icon: EyeIcon },
	{ label: 'Edit', key: 'edit', icon: PencilIcon },
	{ label: 'Delete', key: 'delete', icon: TrashIcon },
	{ label: 'Download', key: 'download', icon: ArrowDownTrayIcon },
]

/**
 * @typedef MenuProps
 * @property {Array<{ type: menuItemEnum, onClick: () => void } | { type: menuItemEnum.CUSTOM, label: string, onClick: () => void }> items}
 */

/**
 * @param {MenuProps} props
 */
const Menu = ({ items }) => {
	return (
		<Dropdown>
			<DropdownButton plain>
				<EllipsisVerticalIcon />
			</DropdownButton>
			<DropdownMenu anchor="bottom start">
				{items.map((item, index) => {
					let menuItem
					switch (item.type) {
						case menuItemEnum.CUSTOM:
							menuItem = item
							break
						case menuItemEnum.VIEW:
						case menuItemEnum.EDIT:
						case menuItemEnum.DELETE:
						case menuItemEnum.DOWNLOAD:
							menuItem = menuItems.find(i => i.key === item.type) ?? {}
							menuItem = { ...menuItem, onClick: item.onClick }
							break
						default:
							break
					}

					if (!menuItem) return

					return (
						<DropdownItem key={index} onClick={menuItem.onClick}>
							{menuItem.icon && <menuItem.icon className="w-5 h-5 mr-2" />}
							{menuItem.label}
						</DropdownItem>
					)
				})}
			</DropdownMenu>
		</Dropdown>
	)
}

export default { Edit, View, Download, Delete, Menu, menuItemEnum }
