import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import TenantMembership from "../TenantMembership";
import ModelProxyClass from "./ModelProxyClass";
import Tenant from "../Tenant";


/**
 * @class TenantMembershipBase
 * @extends ModelBaseClass
 * @property {number} id (int64)
 * @property {number} personId (int64)
 * @property {Tenant} tenant
 * @property {string} role
 * @property {boolean} adminFlag specifically whether this user is considered a tenant admin
 * @property {['EtiAdmin'|'EtiStaff'|'ToAdmin'|'RevenueAdmin'|'RevenueStaff']} permissions
 * @property {boolean} activeFlag this is whether this person record is considered active within the tenant.  This is almost ALWAYS used instead of person.activeFlag
 * @property {Date} dateCreated (date and time)
 */
class TenantMembershipBase extends ModelBaseClass {
	/**
	 * @type {number} id (int64)
	 */
	id;
	/**
	 * @type {number} personId (int64)
	 */
	personId;
	/**
	 * @type {Tenant} tenant
	 */
	tenant;
	/**
	 * @type {string} role
	 */
	role;
	/**
	 * specifically whether this user is considered a tenant admin
	 * @type {boolean} adminFlag
	 */
	adminFlag;
	/**
	 * @type {['EtiAdmin'|'EtiStaff'|'ToAdmin'|'RevenueAdmin'|'RevenueStaff']} permissions
	 */
	permissions;
	/**
	 * this is whether this person record is considered active within the tenant.  This is almost ALWAYS used instead of person.activeFlag
	 * @type {boolean} activeFlag
	 */
	activeFlag;
	/**
	 * @type {Date} dateCreated (date and time)
	 */
	dateCreated;

	/**
	 * Instantiates a new instance of TenantMembership based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {TenantMembership}
	 */
	static create(genericObject) {
		const newTenantMembership = new TenantMembership();
		newTenantMembership.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newTenantMembership;
	}

	/**
	 * Instantiates a new array of TenantMembership based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[TenantMembership]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newTenantMembershipArray = [];
		genericArray.forEach(genericObject => {
			newTenantMembershipArray.push(TenantMembership.create(genericObject));
		});
		return newTenantMembershipArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('id', 'integer'),
	ModelBaseClass.createModelProperty('personId', 'integer'),
	ModelBaseClass.createModelProperty('tenant', 'Tenant'),
	ModelBaseClass.createModelProperty('role', 'string'),
	ModelBaseClass.createModelProperty('adminFlag', 'boolean'),
	ModelBaseClass.createModelProperty('permissions', '[string]'),
	ModelBaseClass.createModelProperty('activeFlag', 'boolean'),
	ModelBaseClass.createModelProperty('dateCreated', 'datetime'),
];

export default TenantMembershipBase;
