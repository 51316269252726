import { Badge } from 'shared/catalyst-components/badge'
import ReimbursementStatusEnum from 'shared/models/enum/ReimbursementStatusEnum'

/**
 * @typedef ReimbursementStatusBadgeProps
 * @property {ReimbursementStatusEnum} status
 */

/**
 * @param {ReimbursementStatusBadgeProps} props
 */
const ReimbursementStatusBadge = ({ status }) => {
	const getColor = () => {
		switch (status) {
			case ReimbursementStatusEnum.REQUESTED:
				return 'yellow'
			case ReimbursementStatusEnum.APPROVED:
				return 'green'
			case ReimbursementStatusEnum.DECLINED:
				return 'red'
			default:
				return 'zinc'
		}
	}
	return (
		<Badge color={getColor()} className="capitalize">
			{status}
		</Badge>
	)
}

export default ReimbursementStatusBadge
