import RevenueSummaryResponseBase from './base/RevenueSummaryResponseBase';

/**
 * @class RevenueSummaryResponse
 * @extends RevenueSummaryResponseBase
 */
class RevenueSummaryResponse extends RevenueSummaryResponseBase {

}

export default RevenueSummaryResponse;
