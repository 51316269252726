import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import SystemSettings from "../SystemSettings";
import ModelProxyClass from "./ModelProxyClass";
import County from "../County";


/**
 * @class SystemSettingsBase
 * @extends ModelBaseClass
 * @property {[County]} counties
 */
class SystemSettingsBase extends ModelBaseClass {
	/**
	 * @type {[County]} counties
	 */
	counties;

	/**
	 * Instantiates a new instance of SystemSettings based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {SystemSettings}
	 */
	static create(genericObject) {
		const newSystemSettings = new SystemSettings();
		newSystemSettings.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newSystemSettings;
	}

	/**
	 * Instantiates a new array of SystemSettings based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[SystemSettings]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newSystemSettingsArray = [];
		genericArray.forEach(genericObject => {
			newSystemSettingsArray.push(SystemSettings.create(genericObject));
		});
		return newSystemSettingsArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('counties', '[County]'),
];

export default SystemSettingsBase;
