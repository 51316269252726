import { getOrdinalColorScale } from '@nivo/colors'
import { ResponsiveLine } from '@nivo/line'
import dayjs from 'dayjs'
import useScreenSize from 'hooks/useScreenSize'
import { useMemo } from 'react'
import LineGraphDateRangeEnum from 'shared/models/enum/LineGraphDateRangeEnum'
import LineGraph from 'shared/models/LineGraph'
import { numberToUsd } from 'shared/utilities/helpers'
import colors from 'tailwindcss/colors'

const Tooltip = ({ point }) => {
	const { color, serieId } = point
	const { x: startDate, yFormatted: value, dateRangeEnd: endDate } = point.data
	return (
		<div className="p-2 bg-white rounded-lg shadow-lg text-primary-700">
			<div className="flex items-center gap-2 mb-1 text-sm">
				<div className="w-3 h-3 rounded-sm" style={{ backgroundColor: color }} />
				<label>{serieId}</label>
			</div>
			{endDate ? (
				<div>
					<div className="text-xs">
						Daily Average {dayjs(startDate).format('MMM DD')} -{' '}
						{dayjs(endDate).format('MMM DD YYYY')}
					</div>
					<div className="mt-1 text-sm font-bold">{value}</div>
				</div>
			) : (
				<div className="text-sm">
					<span>{dayjs(startDate).format('MMM DD, YY')} - </span>
					<span className="font-bold">{value}</span>
				</div>
			)}
		</div>
	)
}

/**
 * @typedef PerformanceOverTimeChartProps
 * @property {LineGraph} performanceOverTimeData
 */

/**
 * @param {PerformanceOverTimeChartProps} props
 */
const PerformanceOverTimeChart = ({ performanceOverTimeData }) => {
	const { width } = useScreenSize()
	const isMobile = width < 640
	const marginConfig = isMobile
		? { top: 40, right: 20, bottom: 60, left: 40 }
		: { top: 40, right: 80, bottom: 40, left: 40 }
	// Create a color scale using the nivo color scheme
	const colorScale = getOrdinalColorScale({ scheme: 'nivo' }, 'id')

	const { graphMetrics, dateRange } = performanceOverTimeData ?? {}

	const data = useMemo(
		() =>
			graphMetrics?.map(metric => ({
				id: metric.label,
				data:
					metric.graphItems
						?.filter(item => !!item.date)
						.map(item => ({
							x: item.date,
							y: item.amount || 0,
							dateRangeEnd: item?.dateRangeEnd,
						})) || [],
			})),
		[graphMetrics],
	)

	// format the date based on the date range
	const getXFormat = date => {
		if (dateRange === LineGraphDateRangeEnum.MONTHLY) {
			return dayjs(date).format('MMM YYYY')
		}

		return dayjs(date).format('MMM DD, YY')
	}

	// to prevent useMesh error
	if (!graphMetrics) return null

	return (
		<div className="w-full">
			<div className="w-full h-96">
				<ResponsiveLine
					theme={{
						tooltip: {
							container: {
								color: colors.zinc[700],
								fontSize: '12px',
							},
						},
					}}
					data={data || []}
					id="label"
					margin={marginConfig}
					xScale={{ type: 'point' }}
					yScale={{
						type: 'linear',
						min: 'auto',
						max: 'auto',
					}}
					xFormat={getXFormat}
					yFormat={v => numberToUsd(v, { minDigits: 2 })}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						format: getXFormat,
						tickRotation: isMobile ? -45 : 0,
					}}
					axisLeft={{
						format: v => (v > 0 ? `${(v / 1000).toFixed(0)}k` : v),
					}}
					pointSize={6}
					pointColor={{ from: 'color' }}
					pointBorderWidth={2}
					pointBorderColor={{ from: 'color' }}
					pointLabel="data.yFormatted"
					pointLabelYOffset={-12}
					tooltip={Tooltip}
					useMesh
					animate={false}
				/>
			</div>
			<div className="flex flex-wrap justify-center mt-4">
				{data?.map((item, index) => (
					<div key={index} className="flex items-center mb-2 mr-3">
						<span
							className="w-3 h-3 mr-1 rounded-full"
							style={{ backgroundColor: colorScale(item) }} // Get the color from the scale
						/>
						<span className="text-xs">{item.id}</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default PerformanceOverTimeChart
