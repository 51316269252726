import OwnerDetailsBase from './base/OwnerDetailsBase';

/**
 * @class OwnerDetails
 * @extends OwnerDetailsBase
 */
class OwnerDetails extends OwnerDetailsBase {

}

export default OwnerDetails;
