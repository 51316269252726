import PaymentBase from './base/PaymentBase';

/**
 * @class Payment
 * @extends PaymentBase
 */
class Payment extends PaymentBase {

}

export default Payment;
