class ExistingFile {
	/**
	 * @param {string} filename
	 * @param {string} mimeType
	 * @param {string} viewUrl
	 */
	constructor(filename, mimeType, viewUrl) {
		this.filename = filename
		this.mimeType = mimeType
		this.viewUrl = viewUrl
	}

	// check instance
	static [Symbol.hasInstance](obj) {
		if (obj && obj.filename && obj.mimeType && obj.viewUrl) return true
	}
}

export default ExistingFile
