import TenantSearchRequestBase from './base/TenantSearchRequestBase';

/**
 * @class TenantSearchRequest
 * @extends TenantSearchRequestBase
 */
class TenantSearchRequest extends TenantSearchRequestBase {

}

export default TenantSearchRequest;
