import getModulesRole from 'shared/utilities/getModulesRole';
import TenantMembershipBase from './base/TenantMembershipBase';

/**
 * @class TenantMembership
 * @extends TenantMembershipBase
 */
class TenantMembership extends TenantMembershipBase {
  /**
   * Get the role of the user for a specific module
   * @returns {'Admin' | 'Staff' | null}
   */
  getRole(moduleKey) {
    return getModulesRole(this.permissions)[moduleKey]
  }

	get isEtiAdmin() {
		return this.role === 'TI Admin'
	}

	/**
	 * check if owner's portal's name and domain are set
	 * @returns {boolean}
	 */
  get isOwnersPortalReady() {
    return this.tenant?.ownersPortalName && this.tenant?.ownersPortalDomain
  }
}

export default TenantMembership;
