import { Field, useField } from 'formik'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import Checkbox from '../../Forms/Checkbox'

/**
 * @param {import('../../Forms/Checkbox').CheckboxProps} props
 */
const FormikCheckbox = ({ onChange, ...props }) => {
	const [field, meta, helpers] = useField(props)
	const { error } = meta
	const hasError = error

	const handleChange = useCallback(
		e => {
			const checked = e.target.checked
			if (onChange) onChange(checked)
			helpers.setValue(checked)
		},
		[helpers, onChange]
	)

	return (
		<Field
			as={Checkbox}
			{...props}
			onChange={handleChange}
			invalid={hasError}
			errorMessage={error}
		/>
	)
}

FormikCheckbox.propTypes = {
	onChange: PropTypes.func,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.node,
	helperText: PropTypes.string,
	disabled: PropTypes.bool
}

FormikCheckbox.defaultProps = {}

export default FormikCheckbox
