import { getOrdinalColorScale } from '@nivo/colors'
import { ResponsivePie } from '@nivo/pie'
import useScreenSize from 'hooks/useScreenSize'
import { numberToUsd } from 'shared/utilities/helpers'
import colors from 'tailwindcss/colors'

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
	const total = dataWithArc.reduce((acc, curr) => acc + curr.value, 0)
	const million = 1000000
	const formattedTotal =
		total >= million
			? `${numberToUsd(total / million, { minDigits: 0, maxDigits: 2 })}M`
			: numberToUsd(total, { minDigits: 0, maxDigits: 2 })

	if (!dataWithArc || dataWithArc.length === 0)
		return (
			<text
				x={centerX}
				y={centerY}
				textAnchor="middle"
				dominantBaseline="central"
				style={{
					fontSize: '16px',
					fontWeight: 'bold',
					fill: colors.zinc[700],
				}}
			>
				Performance Data Not Available
			</text>
		)

	return (
		<>
			<text
				x={centerX}
				y={centerY - 10}
				textAnchor="middle"
				dominantBaseline="central"
				style={{
					fontSize: '24px',
					fontWeight: 'bold',
					fill: colors.zinc[700],
				}}
			>
				{formattedTotal}
			</text>
			<text
				x={centerX}
				y={centerY + 18}
				textAnchor="middle"
				dominantBaseline="central"
				style={{
					fontSize: '12px',
					fontWeight: 'bold',
					fill: colors.zinc[500],
				}}
			>
				TOTAL
			</text>
		</>
	)
}

const Tooltip = ({ datum }) => {
	const { color, label, formattedValue } = datum
	return (
		<div className="p-2 text-xs bg-white rounded-lg shadow-lg text-primary-700">
			<div className="flex items-center gap-2 mb-1 text-xs">
				<div className="w-3 h-3 rounded-sm" style={{ backgroundColor: color }} />
				<label className="max-w-[100px] md:max-w-none truncate">{label}</label>
			</div>
			<div className="text-sm font-bold">{formattedValue}</div>
		</div>
	)
}

/**
 * @typedef MachineDoughnutChartProps
 * @property {Array<{ label: string, value: number }>} data
 */

/**
 * @param {MachineDoughnutChartProps} props
 */
const MachineDoughnutChart = ({ data }) => {
	const { width } = useScreenSize()

	// Create a color scale using the nivo color scheme
	const colorScale = getOrdinalColorScale({ scheme: 'nivo' }, 'label')

	const isMobile = width < 640
	const marginConfig = isMobile
		? { top: 20, right: 20, bottom: 20, left: 20 }
		: { top: 40, right: 80, bottom: 80, left: 80 }

	const total = data.reduce((acc, curr) => acc + curr.value, 0)
	const getPercentage = value => `${((value / total) * 100).toFixed(2)}%`

	return (
		<div>
			<div className="h-96">
				<ResponsivePie
					theme={{
						tooltip: {
							container: {
								color: colors.zinc[700],
								fontSize: '12px',
							},
						},
					}}
					data={data}
					valueFormat={value => numberToUsd(value, { minDigits: 0, maxDigits: 2 })}
					id="label"
					colors={colorScale}
					margin={marginConfig}
					layers={['arcs', 'arcLinkLabels', 'arcLabels', 'legends', CenteredMetric]}
					innerRadius={0.6}
					padAngle={0.7}
					cornerRadius={3}
					activeOuterRadiusOffset={8}
					borderWidth={1}
					borderColor={{
						from: 'color',
						modifiers: [['darker', 0.2]],
					}}
					arcLabel={d => getPercentage(d.value)}
					enableArcLinkLabels={!isMobile}
					arcLinkLabelsSkipAngle={10}
					arcLinkLabelsTextColor={colors.zinc[700]}
					arcLinkLabelsThickness={2}
					arcLinkLabelsColor={{ from: 'color' }}
					arcLabelsSkipAngle={10}
					arcLabelsTextColor={{
						from: 'color',
						modifiers: [['darker', 2]],
					}}
					tooltip={Tooltip}
					defs={[
						{
							id: 'dots',
							type: 'patternDots',
							background: 'inherit',
							color: 'rgba(255, 255, 255, 0.3)',
							size: 4,
							padding: 1,
							stagger: true,
						},
						{
							id: 'lines',
							type: 'patternLines',
							background: 'inherit',
							color: 'rgba(255, 255, 255, 0.3)',
							rotation: -45,
							lineWidth: 6,
							spacing: 10,
						},
					]}
				/>
			</div>
			<div className="flex flex-wrap justify-center">
				{data.map((item, index) => (
					<div key={index} className="flex items-center mb-2 mr-3">
						<span
							className="w-3 h-3 mr-1 rounded-full"
							style={{ backgroundColor: colorScale(item) }} // Get the color from the scale
						/>
						<span className="flex-1 text-xs">{item.label}</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default MachineDoughnutChart
