import { Field, useField } from 'formik'
import PropTypes from 'prop-types'

import ExistingFile from '../../../ExistingFile'
import MultiFileUpload from '../../Forms/MultiFileUpload'

/**
 * @param {import('../../Forms/MultiFileUpload').MultiFileUploadProps} props
 */
const FormikMultiFileUpload = ({ ...props }) => {
	const [field, meta, helpers] = useField(props)
	const { error, touched } = meta
	const hasError = error && touched

	/** @param {File[] | ExistingFile[]} files */
	const handleChange = files => {
		helpers.setValue(files)
	}

	return (
		<Field
			as={MultiFileUpload}
			{...field}
			{...props}
			onChange={handleChange}
			invalid={hasError}
			errorMessage={error}
		/>
	)
}

FormikMultiFileUpload.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	cornerHint: PropTypes.string,
	helperText: PropTypes.string,
	values: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.instanceOf(ExistingFile)])
	),
	disabled: PropTypes.bool,
	invalid: PropTypes.bool,
	errorMessage: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func,
	endpointUrl: PropTypes.string
}

FormikMultiFileUpload.defaultProps = {}

export default FormikMultiFileUpload
