import CountyBase from './base/CountyBase';

/**
 * @class County
 * @extends CountyBase
 */
class County extends CountyBase {

}

export default County;
