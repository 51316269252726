import ReportedVgtMachineBase from './base/ReportedVgtMachineBase';

/**
 * @class ReportedVgtMachine
 * @extends ReportedVgtMachineBase
 */
class ReportedVgtMachine extends ReportedVgtMachineBase {

}

export default ReportedVgtMachine;
