import { cx } from 'class-variance-authority'
import { useEffect, useState } from 'react'
import { DateFilter } from 'shared/components'

export const MetricEnums = {
	FUNDS_IN: 'fundsIn',
	AMOUNT_PLAYED: 'amountPlayed',
	NET_TERMINAL_INCOME: 'netTerminalIncome',
}

const metricOptions = [
	{ value: MetricEnums.FUNDS_IN, label: 'Funds In' },
	{ value: MetricEnums.AMOUNT_PLAYED, label: 'Amount Played' },
	{ value: MetricEnums.NET_TERMINAL_INCOME, label: 'NTI' },
]

/**
 * @typedef FilterProps
 * @property {function({date: {from: string, to: string}, metric: })} onChange
 */

/**
 * @param {FilterProps} props
 */
const Filter = ({ onChange }) => {
	const [dateFilter, setDateFilter] = useState({ from: '', to: '' })
	const [metric, setMetric] = useState(MetricEnums.FUNDS_IN)

	useEffect(() => {
		onChange({ date: dateFilter, metric })
	}, [dateFilter, metric, onChange])

	return (
		<div className="flex flex-col w-full gap-3 xl:flex-row-reverse xl:items-center xl:justify-end">
			<div>
				<DateFilter onChange={setDateFilter} />
			</div>
			<div>
				<h3 className="mb-1 ml-1 text-xs font-semibold text-gray-600">Metric</h3>
				<MetricSelect value={metric} onChange={setMetric} />
			</div>
		</div>
	)
}

const MetricSelect = ({ value, onChange }) => {
	return (
		<div className="w-full">
			<div className="grid grid-cols-[auto,auto,auto] w-full rounded-md shadow-sm md:w-fit isolate">
				{metricOptions.map(option => {
					const isSelected = option.value === value
					return (
						<button
							key={option.value}
							type="button"
							className={cx(
								'first:rounded-l-md last:rounded-r-md first:ml-0 text-xs -ml-px inline-flex items-center bg-white px-3 py-2 md:px-6 md:text-sm font-semibold text-gray-600 leading-5 ring-1 ring-inset ring-primary-300 flex-1 justify-center md:flex-initial md:justify-start hover:bg-gray-50 focus:z-10',
								isSelected ? '!bg-primary-600  text-white' : 'text-gray-900',
							)}
							onClick={() => onChange(option.value)}
						>
							{option.label}
						</button>
					)
				})}
			</div>
		</div>
	)
}

export default Filter
