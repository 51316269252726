import PayPeriodBase from './base/PayPeriodBase';

/**
 * @class PayPeriod
 * @extends PayPeriodBase
 */
class PayPeriod extends PayPeriodBase {

}

export default PayPeriod;
