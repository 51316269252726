import TenantMembershipPermissionsEnum from 'shared/models/enum/TenantMembershipPermissionsEnum'
import TenantModulesEnum from 'shared/models/enum/TenantModulesEnum'

export const moduleRoleEnum = {
	admin: 'Admin',
	staff: 'Staff',
}

/**
 * get the role of the user for each module
 * @param {[typeof TenantMembershipPermissionsEnum]} permissions
 * @returns {{
 *  systemManagement: 'Admin' | 'Staff' | null,
 *  toManagement: 'Admin' | null,
 *  revenueManagement: 'Admin' | 'Staff' | null,
 * }}
 */
const getModulesRole = permissions => {
	if (!permissions) return {}

	return {
		[TenantModulesEnum.SYSTEM_MANAGEMENT]: permissions.includes(
			TenantMembershipPermissionsEnum.ETI_ADMIN,
		)
			? moduleRoleEnum.admin
			: permissions.includes(TenantMembershipPermissionsEnum.ETI_STAFF)
			? moduleRoleEnum.staff
			: null,
		[TenantModulesEnum.TO_MANAGEMENT]: permissions.includes(
			TenantMembershipPermissionsEnum.TO_ADMIN,
		)
			? moduleRoleEnum.admin
			: null,
		[TenantModulesEnum.REVENUE]: permissions.includes(TenantMembershipPermissionsEnum.REVENUE_ADMIN)
			? moduleRoleEnum.admin
			: permissions.includes(TenantMembershipPermissionsEnum.REVENUE_STAFF)
			? moduleRoleEnum.staff
			: null,
	}
}

export default getModulesRole
