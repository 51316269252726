import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import IgbModelSearchResponse from "../IgbModelSearchResponse";
import ModelProxyClass from "./ModelProxyClass";
import IgbModel from "../IgbModel";


/**
 * @class IgbModelSearchResponseBase
 * @extends ModelBaseClass
 * @property {[IgbModel]} igbModels
 * @property {number} totalItemCount (int64)
 */
class IgbModelSearchResponseBase extends ModelBaseClass {
	/**
	 * @type {[IgbModel]} igbModels
	 */
	igbModels;
	/**
	 * @type {number} totalItemCount (int64)
	 */
	totalItemCount;

	/**
	 * Instantiates a new instance of IgbModelSearchResponse based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {IgbModelSearchResponse}
	 */
	static create(genericObject) {
		const newIgbModelSearchResponse = new IgbModelSearchResponse();
		newIgbModelSearchResponse.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newIgbModelSearchResponse;
	}

	/**
	 * Instantiates a new array of IgbModelSearchResponse based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[IgbModelSearchResponse]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newIgbModelSearchResponseArray = [];
		genericArray.forEach(genericObject => {
			newIgbModelSearchResponseArray.push(IgbModelSearchResponse.create(genericObject));
		});
		return newIgbModelSearchResponseArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('igbModels', '[IgbModel]'),
	ModelBaseClass.createModelProperty('totalItemCount', 'integer'),
];

export default IgbModelSearchResponseBase;
