import { useCore } from 'contexts/core-context'
import PageHeader from 'layout/PageHeader'
import { useCallback, useEffect, useState } from 'react'
import { Subheading } from 'shared/catalyst-components/heading'
import Client from 'shared/clients/base/Client'
import { Card, SummaryStatsBlock } from 'shared/components'
import LocationDashboard from 'shared/containers/LocationDashboard'
import VgtPerformanceMetric from 'shared/models/VgtPerformanceMetric'

const { PayPeriod } = LocationDashboard

const Dashboard = () => {
	const { location } = useCore()

	const [summaryData, setSummaryData] = useState(/** @type {VgtPerformanceMetric} */ (null))

	const fetchSummary = useCallback(() => {
		if (!location) return

		const currentPayPeriod = 'current_pay_period'

		Client.RevenueApi.getRevenueSummary(String(location.id), currentPayPeriod, currentPayPeriod, {
			status200: response => setSummaryData(response.totalPerformanceMetric),
		})
	}, [location])

	useEffect(fetchSummary, [fetchSummary])

	return (
		<div>
			<PageHeader title="Dashboard" />
			<div className="space-y-3 lg:space-y-6">
				<Card>
					<Subheading>Pay Period {location ? `(${location.payPeriodType})` : ''}</Subheading>
					{summaryData && (
						<PayPeriod startDate={summaryData.dateFrom} endDate={summaryData.dateTo} />
					)}
				</Card>
				{summaryData && <SummaryStatsBlock summaryData={summaryData} border />}
			</div>
		</div>
	)
}

export default Dashboard
