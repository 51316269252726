import { Field, useField } from 'formik'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import Select from '../../Forms/Select'

/**
 * @param {import('../../Forms/Select').SelectProps} props
 */
const FormikSelect = ({ onChange, ...props }) => {
	const [field, meta, helpers] = useField(props)
	const { error, touched } = meta
	const hasError = error && touched

	const handleChange = useCallback(
		value => {
			if (onChange) onChange(value)
			helpers.setValue(value)
		},
		[helpers, onChange]
	)

	return (
		<Field
			as={Select}
			{...props}
			{...field}
			onChange={handleChange}
			onBlur={() => helpers.setTouched(true)}
			invalid={hasError}
			errorMessage={error}
		/>
	)
}

FormikSelect.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.node,
	onChange: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any,
			label: PropTypes.string
		})
	),
	placeholder: PropTypes.string,
	disabled: PropTypes.bool
}

FormikSelect.defaultProps = {}

export default FormikSelect
