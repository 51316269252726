import IgbModelBase from './base/IgbModelBase';

/**
 * @class IgbModel
 * @extends IgbModelBase
 */
class IgbModel extends IgbModelBase {

}

export default IgbModel;
