import PersonBase from './base/PersonBase';

/**
 * @class Person
 * @extends PersonBase
 */
class Person extends PersonBase {

	getEtiUserRole() {
		const etiTenantMembership = this.userDetails?.getEtiTenantMembership()
		if (etiTenantMembership) return etiTenantMembership.adminFlag ? 'TI Admin' : 'TI Staff'
		// not a TI user
		else return null
	}

	getTenantMembership(tenantId) {
		return this.userDetails?.tenantMemberships.find(t => t.tenant.id === tenantId)
	}

	getTenantActiveFlag(tenantId) {
		return this.getTenantMembership(tenantId)?.activeFlag
	}
}

export default Person;
