import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import VgtPerformanceMetric from "../VgtPerformanceMetric";
import ModelProxyClass from "./ModelProxyClass";


/**
 * @class VgtPerformanceMetricBase
 * @extends ModelBaseClass
 * @property {Date} dateLogged (date only) if this performance metric is for a single day, then that day is specified her
 * @property {Date} dateFrom (date only) if this performance metric is for an aggregate over a date range, then this is the start date for that range
 * @property {Date} dateTo (date only) if this performance metric is for an aggregate over a date range, then this is the end date for that range
 * @property {number} metricCount (integer) if this performance metric is for an aggregate over a date range, then the count of dates within that range that have metrics is set here
 * @property {number} locationId (int64) if performance metric is for a Location, the locationId is specified here
 * @property {number} reportedVgtMachineId (int64) if performance metric is for a ReportedVgtMachine, the reportedVgtMachineId is specified here
 * @property {number} fundsIn
 * @property {number} fundsOut
 * @property {number} netTerminalIncome
 * @property {number} amountPlayed
 * @property {number} amountWon
 * @property {number} locationRevenue
 */
class VgtPerformanceMetricBase extends ModelBaseClass {
	/**
	 * if this performance metric is for a single day, then that day is specified her
	 * @type {Date} dateLogged (date only)
	 */
	dateLogged;
	/**
	 * if this performance metric is for an aggregate over a date range, then this is the start date for that range
	 * @type {Date} dateFrom (date only)
	 */
	dateFrom;
	/**
	 * if this performance metric is for an aggregate over a date range, then this is the end date for that range
	 * @type {Date} dateTo (date only)
	 */
	dateTo;
	/**
	 * if this performance metric is for an aggregate over a date range, then the count of dates within that range that have metrics is set here
	 * @type {number} metricCount (integer)
	 */
	metricCount;
	/**
	 * if performance metric is for a Location, the locationId is specified here
	 * @type {number} locationId (int64)
	 */
	locationId;
	/**
	 * if performance metric is for a ReportedVgtMachine, the reportedVgtMachineId is specified here
	 * @type {number} reportedVgtMachineId (int64)
	 */
	reportedVgtMachineId;
	/**
	 * @type {number} fundsIn
	 */
	fundsIn;
	/**
	 * @type {number} fundsOut
	 */
	fundsOut;
	/**
	 * @type {number} netTerminalIncome
	 */
	netTerminalIncome;
	/**
	 * @type {number} amountPlayed
	 */
	amountPlayed;
	/**
	 * @type {number} amountWon
	 */
	amountWon;
	/**
	 * @type {number} locationRevenue
	 */
	locationRevenue;

	/**
	 * Instantiates a new instance of VgtPerformanceMetric based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {VgtPerformanceMetric}
	 */
	static create(genericObject) {
		const newVgtPerformanceMetric = new VgtPerformanceMetric();
		newVgtPerformanceMetric.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newVgtPerformanceMetric;
	}

	/**
	 * Instantiates a new array of VgtPerformanceMetric based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[VgtPerformanceMetric]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newVgtPerformanceMetricArray = [];
		genericArray.forEach(genericObject => {
			newVgtPerformanceMetricArray.push(VgtPerformanceMetric.create(genericObject));
		});
		return newVgtPerformanceMetricArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('dateLogged', 'datetime'),
	ModelBaseClass.createModelProperty('dateFrom', 'datetime'),
	ModelBaseClass.createModelProperty('dateTo', 'datetime'),
	ModelBaseClass.createModelProperty('metricCount', 'integer'),
	ModelBaseClass.createModelProperty('locationId', 'integer'),
	ModelBaseClass.createModelProperty('reportedVgtMachineId', 'integer'),
	ModelBaseClass.createModelProperty('fundsIn', 'float'),
	ModelBaseClass.createModelProperty('fundsOut', 'float'),
	ModelBaseClass.createModelProperty('netTerminalIncome', 'float'),
	ModelBaseClass.createModelProperty('amountPlayed', 'float'),
	ModelBaseClass.createModelProperty('amountWon', 'float'),
	ModelBaseClass.createModelProperty('locationRevenue', 'float'),
];

export default VgtPerformanceMetricBase;
