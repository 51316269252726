import ResultParameter from "../ResultParameter";
import SystemSettings from "../SystemSettings";
import Session from "../Session";
import LoginRequest from "../LoginRequest";
import ForgotPasswordRequest from "../ForgotPasswordRequest";
import UpdatePasswordRequest from "../UpdatePasswordRequest";
import IgbModelSearchRequest from "../IgbModelSearchRequest";
import IgbModelSearchResponse from "../IgbModelSearchResponse";
import PersonSearchRequest from "../PersonSearchRequest";
import PersonSearchResponse from "../PersonSearchResponse";
import TenantSearchRequest from "../TenantSearchRequest";
import TenantSearchResponse from "../TenantSearchResponse";
import LocationSearchRequest from "../LocationSearchRequest";
import LocationSearchResponse from "../LocationSearchResponse";
import LocationGroupSearchRequest from "../LocationGroupSearchRequest";
import LocationGroupSearchResponse from "../LocationGroupSearchResponse";
import RevenuePaymentSearchRequest from "../RevenuePaymentSearchRequest";
import RevenuePaymentSearchResponse from "../RevenuePaymentSearchResponse";
import RevenuePayPeriodSearchRequest from "../RevenuePayPeriodSearchRequest";
import RevenuePayPeriodSearchResponse from "../RevenuePayPeriodSearchResponse";
import RevenueReimbursementSearchRequest from "../RevenueReimbursementSearchRequest";
import RevenueReimbursementSearchResponse from "../RevenueReimbursementSearchResponse";
import IgbModel from "../IgbModel";
import County from "../County";
import RevenuePerformanceOverTimeResponse from "../RevenuePerformanceOverTimeResponse";
import RevenueSummaryResponse from "../RevenueSummaryResponse";
import VgtPerformanceMetric from "../VgtPerformanceMetric";
import LineGraph from "../LineGraph";
import GraphMetric from "../GraphMetric";
import GraphItem from "../GraphItem";
import Location from "../Location";
import LocationGroup from "../LocationGroup";
import ReportedVgtMachine from "../ReportedVgtMachine";
import PayPeriod from "../PayPeriod";
import Payment from "../Payment";
import Reimbursement from "../Reimbursement";
import Tenant from "../Tenant";
import TenantPreferences from "../TenantPreferences";
import TenantMembership from "../TenantMembership";
import Person from "../Person";
import OwnerDetails from "../OwnerDetails";
import UserDetails from "../UserDetails";
import FileAsset from "../FileAsset";

class ModelProxyClass {
	/**
	 * Constructs a model-based BaseClass subclass based on the className
	 * @param {string} className
	 * @param {object} genericObject
	 * @return {ModelBaseClass}
	 */
	static createByClassName(className, genericObject) {
		switch (className) {
		case 'ResultParameter':
			return ResultParameter.create(genericObject);
		case 'SystemSettings':
			return SystemSettings.create(genericObject);
		case 'Session':
			return Session.create(genericObject);
		case 'LoginRequest':
			return LoginRequest.create(genericObject);
		case 'ForgotPasswordRequest':
			return ForgotPasswordRequest.create(genericObject);
		case 'UpdatePasswordRequest':
			return UpdatePasswordRequest.create(genericObject);
		case 'IgbModelSearchRequest':
			return IgbModelSearchRequest.create(genericObject);
		case 'IgbModelSearchResponse':
			return IgbModelSearchResponse.create(genericObject);
		case 'PersonSearchRequest':
			return PersonSearchRequest.create(genericObject);
		case 'PersonSearchResponse':
			return PersonSearchResponse.create(genericObject);
		case 'TenantSearchRequest':
			return TenantSearchRequest.create(genericObject);
		case 'TenantSearchResponse':
			return TenantSearchResponse.create(genericObject);
		case 'LocationSearchRequest':
			return LocationSearchRequest.create(genericObject);
		case 'LocationSearchResponse':
			return LocationSearchResponse.create(genericObject);
		case 'LocationGroupSearchRequest':
			return LocationGroupSearchRequest.create(genericObject);
		case 'LocationGroupSearchResponse':
			return LocationGroupSearchResponse.create(genericObject);
		case 'RevenuePaymentSearchRequest':
			return RevenuePaymentSearchRequest.create(genericObject);
		case 'RevenuePaymentSearchResponse':
			return RevenuePaymentSearchResponse.create(genericObject);
		case 'RevenuePayPeriodSearchRequest':
			return RevenuePayPeriodSearchRequest.create(genericObject);
		case 'RevenuePayPeriodSearchResponse':
			return RevenuePayPeriodSearchResponse.create(genericObject);
		case 'RevenueReimbursementSearchRequest':
			return RevenueReimbursementSearchRequest.create(genericObject);
		case 'RevenueReimbursementSearchResponse':
			return RevenueReimbursementSearchResponse.create(genericObject);
		case 'IgbModel':
			return IgbModel.create(genericObject);
		case 'County':
			return County.create(genericObject);
		case 'RevenuePerformanceOverTimeResponse':
			return RevenuePerformanceOverTimeResponse.create(genericObject);
		case 'RevenueSummaryResponse':
			return RevenueSummaryResponse.create(genericObject);
		case 'VgtPerformanceMetric':
			return VgtPerformanceMetric.create(genericObject);
		case 'LineGraph':
			return LineGraph.create(genericObject);
		case 'GraphMetric':
			return GraphMetric.create(genericObject);
		case 'GraphItem':
			return GraphItem.create(genericObject);
		case 'Location':
			return Location.create(genericObject);
		case 'LocationGroup':
			return LocationGroup.create(genericObject);
		case 'ReportedVgtMachine':
			return ReportedVgtMachine.create(genericObject);
		case 'PayPeriod':
			return PayPeriod.create(genericObject);
		case 'Payment':
			return Payment.create(genericObject);
		case 'Reimbursement':
			return Reimbursement.create(genericObject);
		case 'Tenant':
			return Tenant.create(genericObject);
		case 'TenantPreferences':
			return TenantPreferences.create(genericObject);
		case 'TenantMembership':
			return TenantMembership.create(genericObject);
		case 'Person':
			return Person.create(genericObject);
		case 'OwnerDetails':
			return OwnerDetails.create(genericObject);
		case 'UserDetails':
			return UserDetails.create(genericObject);
		case 'FileAsset':
			return FileAsset.create(genericObject);
		default:
			throw new Error('Undefined model class: ' + className);
		}
	}
}

export default ModelProxyClass;
