import { ArrowDownTrayIcon } from '@heroicons/react/16/solid'
import { Button } from 'shared/catalyst-components/button'

/**
 * @typedef DownloadCsvButtonProps
 * @property {() => void} onClick
 */

/**
 * @param {DownloadCsvButtonProps} props
 */
const DownloadCsvButton = ({ onClick }) => {
	return (
		<Button plain className="!text-xs" onClick={onClick}>
			<ArrowDownTrayIcon />
			Export CSV
		</Button>
	)
}

export default DownloadCsvButton
