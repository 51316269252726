import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import PayPeriod from "../PayPeriod";
import ModelProxyClass from "./ModelProxyClass";


/**
 * @class PayPeriodBase
 * @extends ModelBaseClass
 * @property {number} id (int64)
 * @property {number} tenantId (int64)
 * @property {'Weekly'|'Monthly'} payPeriodType
 * @property {Date} dateStart (date only)
 * @property {Date} dateEnd (date only)
 * @property {number} totalCount (integer)
 * @property {number} paidCount (integer)
 * @property {number} totalAmount
 */
class PayPeriodBase extends ModelBaseClass {
	/**
	 * @type {number} id (int64)
	 */
	id;
	/**
	 * @type {number} tenantId (int64)
	 */
	tenantId;
	/**
	 * @type {'Weekly'|'Monthly'} payPeriodType
	 */
	payPeriodType;
	/**
	 * @type {Date} dateStart (date only)
	 */
	dateStart;
	/**
	 * @type {Date} dateEnd (date only)
	 */
	dateEnd;
	/**
	 * @type {number} totalCount (integer)
	 */
	totalCount;
	/**
	 * @type {number} paidCount (integer)
	 */
	paidCount;
	/**
	 * @type {number} totalAmount
	 */
	totalAmount;

	/**
	 * Instantiates a new instance of PayPeriod based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {PayPeriod}
	 */
	static create(genericObject) {
		const newPayPeriod = new PayPeriod();
		newPayPeriod.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newPayPeriod;
	}

	/**
	 * Instantiates a new array of PayPeriod based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[PayPeriod]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newPayPeriodArray = [];
		genericArray.forEach(genericObject => {
			newPayPeriodArray.push(PayPeriod.create(genericObject));
		});
		return newPayPeriodArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('id', 'integer'),
	ModelBaseClass.createModelProperty('tenantId', 'integer'),
	ModelBaseClass.createModelProperty('payPeriodType', 'string'),
	ModelBaseClass.createModelProperty('dateStart', 'datetime'),
	ModelBaseClass.createModelProperty('dateEnd', 'datetime'),
	ModelBaseClass.createModelProperty('totalCount', 'integer'),
	ModelBaseClass.createModelProperty('paidCount', 'integer'),
	ModelBaseClass.createModelProperty('totalAmount', 'float'),
];

export default PayPeriodBase;
