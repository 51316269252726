import ReportedVgtMachine from 'shared/models/ReportedVgtMachine'
import { displayAmount, displayDate } from 'shared/utilities/helpers'
import { Table, Tooltip } from 'ui-toolkit-tailwind/src/components'

/**
 * @typedef ComponentProps
 * @property {ReportedVgtMachine[]} data
 */

/**
 * @param {ComponentProps} props
 */
const MachineTable = ({ data }) => {
	return (
		<Table
			data={data}
			columns={[
				{ name: 'spotName', label: 'Last Spot #' },
				{ name: 'licenseNumber', label: 'License #' },
				{
					name: 'displayName',
					label: 'Name',
					formatter: name => (
						<Tooltip message={name}>
							<label className="line-clamp-1 w-[150px] md:w-[250px] !whitespace-normal">
								{name}
							</label>
						</Tooltip>
					),
				},
				{ name: 'serialNumber', label: 'Serial #' },
				{ name: 'dateFirstPerformanceLogged', label: 'First Performance', formatter: displayDate },
				{
					name: 'lastMonthNetTerminalIncome',
					label: 'Previous Month NTI',
					formatter: displayAmount,
				},
				{
					name: 'monthToDateNetTerminalIncome',
					label: 'Month-to-Date NTI',
					formatter: displayAmount,
				},
				{ name: 'yesterdayNetTerminalIncome', label: 'Yest. NTI', formatter: displayAmount },
			]}
		/>
	)
}

export default MachineTable
