import { useCore } from 'contexts/core-context'
import { useGlobal } from 'contexts/global-context'
import { Formik } from 'formik'
import { Button } from 'shared/catalyst-components/button'
import Client from 'shared/clients/base/Client'
import SettingPageLayout from 'shared/layout/SettingPageLayout'
import Person from 'shared/models/Person'
import { phoneSchema } from 'shared/yupSchema'
import { FormikCheckbox, FormikText } from 'ui-toolkit-tailwind/src/components'
import { showToast } from 'utilities/showToast'
import * as Yup from 'yup'

const { Form, Col, Actions, Section } = SettingPageLayout

const PersonalInformationForm = () => {
	const { setShowSpinner } = useGlobal()
	const { session, updateSession, person } = useCore()

	const validationSchema = Yup.object().shape({
		firstName: Yup.string().required(),
		lastName: Yup.string().required(),
		email: Yup.string().email('Invalid email address').required(),
		ownerDetails: Yup.object().shape({
			phone: phoneSchema.nullable(),
		}),
	})

	/**
	 * @param {Person} values
	 */
	const handleSubmit = values => {
		const request = Person.create(values)

		Client.PersonApi.edit(
			request,
			{
				status200: response => {
					showToast({
						intent: 'success',
						title: 'Success',
						content: 'Personal information updated successfully',
					})
					updateSession({
						...session,
						person: response,
					})
				},
				status409: error =>
					showToast({
						intent: 'error',
						title: 'Error',
						content: error,
					}),
			},
			{
				onApiCall: () => setShowSpinner(true),
				onApiResponse: () => setShowSpinner(false),
			},
		)
	}

	return (
		<Section title="Personal Information">
			<Formik
				enableReinitialize
				initialValues={person}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				<Form>
					<Col colSpan={3}>
						<FormikText name="firstName" label="First Name" />
					</Col>
					<Col colSpan={3}>
						<FormikText name="lastName" label="Last Name" />
					</Col>
					<Col>
						<FormikText name="email" label="Email" />
					</Col>
					<Col>
						<FormikText name="ownerDetails.phone" label="Phone Number" placeholder="Optional" />
					</Col>
					<Col colSpan={6} className="-mt-8 md:mt-0">
						<FormikCheckbox
							name="ownerDetails.ownerReceivesEmailReportsFlag"
							label="Receive Email Reports"
						/>
					</Col>
					<Actions>
						<Button type="submit" className="text-xs">
							Save
						</Button>
					</Actions>
				</Form>
			</Formik>
		</Section>
	)
}

export default PersonalInformationForm
