import { cva, cx } from 'class-variance-authority'
import PropTypes from 'prop-types'

const colVariants = cva(
	/* base style */
	'',
	{
		variants: {
			span: {
				1: 'sm:col-span-1',
				2: 'sm:col-span-2',
				3: 'sm:col-span-3',
				4: 'sm:col-span-4',
				5: 'sm:col-span-5',
				6: 'col-span-full'
			}
		},
		defaultVariants: {
			span: 6
		}
	}
)

const emptyColVariants = cva(
	/* base style */
	'hidden',
	{
		variants: {
			span: {
				1: 'sm:block sm:col-span-1',
				2: 'sm:block sm:col-span-2',
				3: 'sm:block sm:col-span-3',
				4: 'sm:block sm:col-span-4',
				5: 'sm:block sm:col-span-5',
				6: 'sm:block sm:col-span-full'
			}
		},
		defaultVariants: {
			span: 6
		}
	}
)

/**
 * @typedef ContainerProps
 * @property {string} [className]
 * @property {boolean} [tighten] reduce the space between each row
 */

/**
 * @param {ContainerProps} props
 */
export const Container = ({ className, tighten, children }) => (
	<div className={cx(className, tighten ? 'space-y-3' : 'space-y-12')}>{children}</div>
)
Container.propTypes = {
	className: PropTypes.string,
	tighten: PropTypes.bool,
	children: PropTypes.node
}

/**
 * @typedef SectionProps
 * @property {string} [title]
 * @property {string} [description]
 */

/**
 * @param {SectionProps} props
 */
export const Section = ({ title, description, children }) => (
	<div className="pb-12 space-y-3">
		<div>
			{title && <h2 className="text-base font-semibold leading-7 text-gray-900">{title}</h2>}
			{description && <p className="text-sm leading-6 text-gray-600">{description}</p>}
		</div>
		{children}
	</div>
)
Section.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	children: PropTypes.node
}

/**
 * @typedef GridProps
 * @property {boolean} [tighten] reduce the space between each col
 */

/**
 * @param {GridProps} props
 */
export const Grid = ({ tighten, children }) => (
	<div
		className={cx(
			'grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6',
			tighten && '!gap-x-3 !gap-y-3'
		)}
	>
		{children}
	</div>
)
Grid.propTypes = {
	tighten: PropTypes.bool,
	children: PropTypes.node
}

/**
 * @typedef ColProps
 * @property {string} [className]
 * @property {1 | 2 | 3 | 4 | 5 | 6} [span] span of the col - max = 6, default is 6
 */

/**
 * @param {ColProps} props
 */
export const Col = ({ className, span, children }) => (
	<div className={colVariants({ className, span })}>{children}</div>
)
Col.propTypes = {
	className: PropTypes.string,
	span: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
	children: PropTypes.node
}

/**
 * @param {ColProps} props
 */
export const EmptyCol = ({ span }) => <div className={emptyColVariants({ span })} />
EmptyCol.propTypes = {
	span: PropTypes.oneOf([1, 2, 3, 4, 5, 6])
}

export const ActionButtons = ({ children }) => (
	<div className="flex items-center justify-end pt-6 pb-12 border-t gap-x-6 border-gray-900/10">
		{children}
	</div>
)
ActionButtons.propTypes = {
	children: PropTypes.node
}
