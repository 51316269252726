import { ArrowUturnLeftIcon } from '@heroicons/react/16/solid'
import { useCore } from 'contexts/core-context'
import { useGlobal } from 'contexts/global-context'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import routePaths from 'routes/routePaths'
import Client from 'shared/clients/base/Client'
import ForgotPasswordRequest from 'shared/models/ForgotPasswordRequest'
import { Button, FormikText } from 'ui-toolkit-tailwind/src/components'
import * as yup from 'yup'

const ForgotPassword = () => {
	const navigate = useNavigate()
	const { setShowSpinner } = useGlobal()
	const { tenant } = useCore()
	const [isResetSuccess, setIsResetSuccess] = useState(false)

	const handleSubmit = ({ email }) => {
		const request = ForgotPasswordRequest.create({ email, tenantId: tenant?.id })
		Client.AuthenticationApi.ownerForgotPassword(
			request,
			{
				status200: () => setIsResetSuccess(true),
			},
			{
				onApiCall: () => setShowSpinner(true),
				onApiResponse: () => setShowSpinner(false),
			},
		)
	}

	return (
		<>
			{!isResetSuccess ? (
				<Formik
					initialValues={{ email: '' }}
					validationSchema={yup.object({
						email: yup.string().email('Invalid email').required(),
					})}
					onSubmit={handleSubmit}
				>
					<Form className="space-y-6">
						<FormikText name="email" type="email" label="Email Address" />

						<Button type="submit" fullWidth>
							Reset My Password
						</Button>
					</Form>
				</Formik>
			) : (
				<p className="font-light text-gray-800">
					If there is an account on file with the email you entered, an email will be sent to that
					address with instructions on how to proceed with the Password Reset.
				</p>
			)}

			{!isResetSuccess ? (
				<button
					onClick={() => navigate(routePaths.auth.login)}
					className="!mt-3 text-sm cursor-pointer text-gray-500 hover:text-gray-700 focus:ring-0"
				>
					<div className="flex items-center">
						<ArrowUturnLeftIcon className="w-4 h-4 mr-1" />
						Back to Login
					</div>
				</button>
			) : (
				<Button className="!mt-3 float-right" onClick={() => navigate(routePaths.auth.login)}>
					Back to Login
				</Button>
			)}
		</>
	)
}

export default ForgotPassword
