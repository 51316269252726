import {
	ArrowRightStartOnRectangleIcon,
	BanknotesIcon,
	ChartBarIcon,
	ChartPieIcon,
	CheckIcon,
	ComputerDesktopIcon,
	FingerPrintIcon,
	HomeIcon,
	UserCircleIcon,
} from '@heroicons/react/16/solid'
import {
	GlobeAmericasIcon,
	IdentificationIcon,
	MapPinIcon,
	TagIcon,
} from '@heroicons/react/24/outline'
import { cx } from 'class-variance-authority'
import { useCore } from 'contexts/core-context'
import { Link, Outlet, useLocation } from 'react-router-dom'
import routePaths from 'routes/routePaths'
import { Avatar } from 'shared/catalyst-components/avatar'
import {
	Dropdown,
	DropdownButton,
	DropdownDescription,
	DropdownHeading,
	DropdownItem,
	DropdownLabel,
	DropdownMenu,
	DropdownSection,
} from 'shared/catalyst-components/dropdown'
import { Heading } from 'shared/catalyst-components/heading'
import {
	Navbar,
	NavbarDivider,
	NavbarItem,
	NavbarLabel,
	NavbarSection,
	NavbarSpacer,
} from 'shared/catalyst-components/navbar'
import {
	Sidebar,
	SidebarBody,
	SidebarHeader,
	SidebarItem,
	SidebarLabel,
	SidebarSection,
} from 'shared/catalyst-components/sidebar'

import { StackedLayout } from './components/StackedLayout'

const AccountDropdownMenu = ({ anchor }) => {
	const { executeLogout } = useCore()
	return (
		<DropdownMenu className="min-w-64" anchor={anchor}>
			<DropdownItem routerLink={routePaths.settings.profile}>
				<UserCircleIcon />
				<DropdownLabel>Edit Profile</DropdownLabel>
			</DropdownItem>
			<DropdownItem routerLink={routePaths.settings.password}>
				<FingerPrintIcon />
				<DropdownLabel>Change Password</DropdownLabel>
			</DropdownItem>
			<DropdownItem onClick={executeLogout}>
				<ArrowRightStartOnRectangleIcon />
				<DropdownLabel>Sign out</DropdownLabel>
			</DropdownItem>
		</DropdownMenu>
	)
}

const navBarItems = [
	{ name: 'Dashboard', path: routePaths.dashboard, icon: HomeIcon },
	{ name: 'Financials', path: routePaths.financials, icon: ChartPieIcon },
	{ name: 'Reimbursements', path: routePaths.reimbursements, icon: BanknotesIcon },
	{
		name: 'Performance Details',
		path: routePaths.performanceDetails,
		icon: ChartBarIcon,
	},
	{
		name: 'Machines',
		path: routePaths.machines,
		icon: ComputerDesktopIcon,
	},
]

const OwnerPortalLayout = () => {
	const { pathname } = useLocation()
	const { person, tenant, tenantLogo, locations, location, updateLocationId } = useCore()

	const locationOptions =
		locations.map(l => ({
			label: l.name,
			value: l.id,
			description: l.currentLicenseNumber,
		})) || []

	return (
		<StackedLayout
			navbar={
				<Navbar>
					<Link
						as={NavbarLabel}
						className="flex justify-center flex-1 -mr-16 h-fit lg:flex-initial lg:-mr-0"
						to={routePaths.dashboard}
					>
						<div className="flex items-center h-10">
							<img
								src={tenantLogo?.src}
								className="h-full object-contain aspect-[3/2] "
								alt={tenant?.name}
							/>
						</div>
					</Link>

					<NavbarDivider className="max-lg:hidden" />

					<NavbarSection className="max-lg:hidden">
						{navBarItems.map(({ name, path, icon: Icon }) => {
							const current = pathname.startsWith(path)
							return (
								<NavbarItem key={name} routerLink={path} current={current}>
									{/* <Icon /> */}
									{name}
								</NavbarItem>
							)
						})}
					</NavbarSection>

					<NavbarSpacer className="hidden lg:block" />

					<NavbarSection>
						<Dropdown>
							<DropdownButton as={NavbarItem}>
								<GlobeAmericasIcon className="!fill-transparent" />
								<span className="hidden -ml-2 md:inline">{location?.name}</span>
							</DropdownButton>
							<DropdownMenu anchor="bottom end">
								{/* <DropdownHeader>
									<div className="pr-6 md:hidden">
										<div className="text-xs text-zinc-500">Current location</div>
										<div className="font-semibold text-sm/7 text-zinc-800">{location?.name}</div>
									</div>

									{location && (
										<div className="hidden gap-1 pr-6 md:flex-col md:flex ">
											<div className="flex items-center text-xs text-zinc-800">
												<MapPinIcon className="inline-block mr-2 size-4 text-zinc-500" />
												{location.getFullAddress()}
											</div>
											<div className="flex items-center text-xs text-zinc-800">
												<IdentificationIcon className="inline-block mr-2 size-4 text-zinc-500" />
												{location.currentLicenseNumber}
											</div>
											{location.locationGroup && (
												<div className="flex items-center text-xs text-zinc-800">
													<TagIcon className="inline-block mr-2 size-4 text-zinc-500" />
													{location.locationGroup.name}
												</div>
											)}
										</div>
									)}
								</DropdownHeader> */}
								{/* <DropdownDivider /> */}
								<DropdownSection>
									<DropdownHeading>Switch Location</DropdownHeading>
									{locationOptions.map(option => (
										<DropdownItem key={option.value} onClick={() => updateLocationId(option.value)}>
											<DropdownLabel>{option.label}</DropdownLabel>
											{option.description && (
												<DropdownDescription className="flex items-center">
													<IdentificationIcon className="mr-1 size-4" />
													{option.description}
												</DropdownDescription>
											)}
											<CheckIcon
												className={cx(location?.id === option.value ? 'block' : 'opacity-0')}
											/>
										</DropdownItem>
									))}
								</DropdownSection>
							</DropdownMenu>
						</Dropdown>
						<Dropdown>
							<DropdownButton as={NavbarItem}>
								<Avatar initials={`${person?.firstName?.[0]}${person?.lastName?.[0]}`} square />
							</DropdownButton>
							<AccountDropdownMenu anchor="top start" />
						</Dropdown>
					</NavbarSection>
				</Navbar>
			}
			sidebar={
				<Sidebar>
					{location && (
						<SidebarHeader className="!p-6 flex flex-col gap-1">
							<Heading className="text-lg">{location.name}</Heading>
							<div className="flex items-center text-xs text-zinc-500">
								<MapPinIcon className="inline-block mr-1 size-4" />
								{location.getFullAddress()}
							</div>
							<div className="flex items-center text-xs text-zinc-500">
								<IdentificationIcon className="inline-block mr-1 size-4" />
								{location.currentLicenseNumber}
							</div>
							{location.locationGroup && (
								<div className="flex items-center text-xs text-zinc-500">
									<TagIcon className="inline-block mr-1 size-4" />
									{location.locationGroup.name}
								</div>
							)}
						</SidebarHeader>
					)}
					<SidebarBody>
						<SidebarSection>
							{navBarItems.map(({ name, path, icon: Icon }) => {
								const current = pathname.startsWith(path)
								return (
									<SidebarItem key={name} routerLink={path} current={current}>
										<Icon />
										<SidebarLabel>{name}</SidebarLabel>
									</SidebarItem>
								)
							})}
						</SidebarSection>
					</SidebarBody>
				</Sidebar>
			}
		>
			<Outlet />
		</StackedLayout>
	)
}

export default OwnerPortalLayout
