import FileAssetBase from './base/FileAssetBase';

/**
 * @class FileAsset
 * @extends FileAssetBase
 */
class FileAsset extends FileAssetBase {

}

export default FileAsset;
