import LocationSearchRequestBase from './base/LocationSearchRequestBase';

/**
 * @class LocationSearchRequest
 * @extends LocationSearchRequestBase
 */
class LocationSearchRequest extends LocationSearchRequestBase {

}

export default LocationSearchRequest;
