import dayjs from 'dayjs'
import { FaCaretDown } from 'react-icons/fa6'

/**
 * @typedef PayPeriodProps
 * @property {string} startDate
 * @property {string} endDate
 */

/**
 * @param {PayPeriodProps} props
 */
const PayPeriod = ({ startDate, endDate }) => {
	// Parse the dates
	const start = dayjs(startDate)
	const end = dayjs(endDate)
	const today = dayjs()

	// Calculate the percentage of time passed
	const totalDuration = end.diff(start, 'day')
	const daysPassed = today.diff(start, 'day')
	const progressPercentage = (daysPassed / totalDuration) * 100

	const hideTodayDate = today.isSame(start) || today.isSame(end)

	return (
		<div className="px-4">
			<div className="w-full max-w-4xl mx-auto">
				<div className="relative h-12">
					<div
						className="absolute bottom-0 flex flex-col items-center justify-end gap-1 text-xs"
						style={{ left: `${progressPercentage}%`, transform: 'translateX(-50%)' }}
					>
						<div className="text-nowrap">
							<b className="text-zinc-700">Today</b>
							{!hideTodayDate && (
								<span className="hidden ml-1 md:inline-block">{today.format('MMM D')}</span>
							)}
						</div>
						<FaCaretDown className="fill-zinc-700" />
					</div>
				</div>
				<div>
					{/* Timeline Bar */}
					<div className="relative h-4 bg-gray-100">
						{/* Progress Bar */}
						<div
							className="absolute top-0 left-0 h-4 bg-zinc-500"
							style={{ width: `${progressPercentage}%` }}
						/>
					</div>
					{/* Start and End Dates */}
					<div className="flex justify-between mt-2 text-xs">
						<span>{start.format('MMM D')}</span>
						<span>{end.format('MMM D')}</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PayPeriod
