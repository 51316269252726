import { PlusIcon } from '@heroicons/react/24/outline'
import { useCore } from 'contexts/core-context'
import PageHeader from 'layout/PageHeader'
import { useCallback, useEffect, useState } from 'react'
import { Button } from 'shared/catalyst-components/button'
import Client from 'shared/clients/base/Client'
import { Card } from 'shared/components'
import LocationReimbursements from 'shared/containers/LocationReimbursements'
import ReimbursementFormModal from 'shared/containers/ReimbursementFormModal'
import Reimbursement from 'shared/models/Reimbursement'
import RevenueReimbursementSearchRequest from 'shared/models/RevenueReimbursementSearchRequest'

const { Filter, ReimbursementTable } = LocationReimbursements

const AddReimbursementButton = ({ color, onClick, className }) => (
	<Button color={color} onClick={onClick} className={className}>
		<PlusIcon />
		New Reimbursement Request
	</Button>
)

const Reimbursements = () => {
	const { location, tenant } = useCore()

	const [statuses, setStatuses] = useState([])
	const [reimbursements, setReimbursements] = useState(/** @type {Reimbursement[]} */ (null))

	const [selectedReimbursement, setSelectedReimbursement] = useState(
		/** @type {Reimbursement | null} */ (null),
	)
	const [showReimbursementModal, setShowReimbursementModal] = useState(false)

	const queryReimbursements = useCallback(() => {
		if (!location || !tenant) return

		const request = new RevenueReimbursementSearchRequest()
		request.tenantId = tenant.id
		request.locationId = location.id
		request.filterByStatuses = statuses

		Client.RevenueApi.searchReimbursements(request, {
			status200: response => setReimbursements(response.reimbursements),
		})
	}, [statuses, location, tenant])

	useEffect(queryReimbursements, [queryReimbursements])

	const handleAddReimbursement = () => {
		setSelectedReimbursement(null)
		setShowReimbursementModal(true)
	}

	return (
		<div className="pb-[50px]">
			<PageHeader title="Reimbursements" />
			<div className="space-y-3 lg:space-y-6">
				<div className="flex flex-col-reverse gap-4 sm:items-end sm:justify-between sm:flex-row">
					<Filter onChange={setStatuses} />

					{/* Desktop Add Reimbursement Button */}
					<div className="hidden sm:block">
						<AddReimbursementButton color="white" onClick={handleAddReimbursement} />
					</div>
				</div>

				<Card>
					<ReimbursementTable
						data={reimbursements}
						onReimbursementClick={r => {
							setSelectedReimbursement(r)
							setShowReimbursementModal(true)
						}}
					/>
				</Card>
			</div>

			{/* Mobile Add Reimbursement Button */}
			<div className="fixed left-0 right-0 w-full px-4 bottom-3 sm:hidden">
				<AddReimbursementButton className="w-full" onClick={handleAddReimbursement} />
			</div>

			<ReimbursementFormModal
				visible={showReimbursementModal}
				reimbursement={selectedReimbursement}
				location={location}
				onClose={() => setShowReimbursementModal(false)}
				onFinish={queryReimbursements}
			/>
		</div>
	)
}

export default Reimbursements
