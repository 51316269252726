import { ChevronLeftIcon } from '@heroicons/react/16/solid'
import { Link } from 'react-router-dom'
import { Heading } from 'shared/catalyst-components/heading'
import { Breadcrumbs } from 'ui-toolkit-tailwind/src/components'

/**
 * @typedef PageHeaderProps
 * @property {string} title
 * @property {{ name: string, href: string, current: boolean }[]} [breadcrumbsPages] optional
 * @property {React.ReactNode} [actions] optional
 */

/**
 * @param {PageHeaderProps} props
 */
const PageHeader = ({ title, breadcrumbsPages, actions }) => {
	const getLastPage = () => {
		if (!breadcrumbsPages) return null
		const lastIndex = breadcrumbsPages.findIndex(page => page.current) - 1
		if (breadcrumbsPages[lastIndex]) return breadcrumbsPages[lastIndex]
		return null
	}

	const lastPage = getLastPage()

	return (
		<div className="mb-4 md:mb-6">
			{lastPage && (
				<div className="md:hidden">
					<Link
						to={lastPage.href ? lastPage.href : -1}
						replace
						className="inline-flex items-center gap-2 text-xs text-zinc-500 dark:text-zinc-400"
					>
						<ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
						{lastPage.name}
					</Link>
				</div>
			)}

			{breadcrumbsPages && (
				<div className="hidden md:block">
					<Breadcrumbs pages={breadcrumbsPages} />
				</div>
			)}
			<div className="flex items-start justify-between w-full">
				<Heading>{title}</Heading>
				{actions && <div>{actions}</div>}
			</div>
		</div>
	)
}

export default PageHeader
