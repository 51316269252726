import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import LocationGroupSearchResponse from "../LocationGroupSearchResponse";
import ModelProxyClass from "./ModelProxyClass";
import LocationGroup from "../LocationGroup";


/**
 * @class LocationGroupSearchResponseBase
 * @extends ModelBaseClass
 * @property {[LocationGroup]} locationGroups
 * @property {number} totalItemCount (int64)
 */
class LocationGroupSearchResponseBase extends ModelBaseClass {
	/**
	 * @type {[LocationGroup]} locationGroups
	 */
	locationGroups;
	/**
	 * @type {number} totalItemCount (int64)
	 */
	totalItemCount;

	/**
	 * Instantiates a new instance of LocationGroupSearchResponse based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {LocationGroupSearchResponse}
	 */
	static create(genericObject) {
		const newLocationGroupSearchResponse = new LocationGroupSearchResponse();
		newLocationGroupSearchResponse.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newLocationGroupSearchResponse;
	}

	/**
	 * Instantiates a new array of LocationGroupSearchResponse based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[LocationGroupSearchResponse]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newLocationGroupSearchResponseArray = [];
		genericArray.forEach(genericObject => {
			newLocationGroupSearchResponseArray.push(LocationGroupSearchResponse.create(genericObject));
		});
		return newLocationGroupSearchResponseArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('locationGroups', '[LocationGroup]'),
	ModelBaseClass.createModelProperty('totalItemCount', 'integer'),
];

export default LocationGroupSearchResponseBase;
