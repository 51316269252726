import ClientBaseClass from "./ClientBaseClass";

export default class FileAssetApi extends ClientBaseClass {
	/**
	 * This should never be called directly -- this is merely a placeholder so that the viewUrl in FileAsset will work correctly.
	 * @param {string} type
	 * @param {string} fileAssetIdentifier
	 * @param {string} sessionIdentifier
	 * @param {string} filename
	 * @param {{status200: function(string), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	get(type, fileAssetIdentifier, sessionIdentifier, filename, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/file_asset/' +
			(type ? encodeURI(type) : '') + '/' +
			(fileAssetIdentifier ? encodeURI(fileAssetIdentifier) : '') + '/' +
			(sessionIdentifier ? encodeURI(sessionIdentifier) : '') + '/' +
			(filename ? encodeURI(filename) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.text()
							.then(responseText => {
								responseHandler.status200(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * This should never be called directly -- this is merely a placeholder so that the downloadUrl in FileAsset will work correctly.
	 * @param {string} type
	 * @param {string} fileAssetIdentifier
	 * @param {string} sessionIdentifier
	 * @param {string} filename
	 * @param {{status200: function(string), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	download(type, fileAssetIdentifier, sessionIdentifier, filename, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/file_asset/download/' +
			(type ? encodeURI(type) : '') + '/' +
			(fileAssetIdentifier ? encodeURI(fileAssetIdentifier) : '') + '/' +
			(sessionIdentifier ? encodeURI(sessionIdentifier) : '') + '/' +
			(filename ? encodeURI(filename) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.text()
							.then(responseText => {
								responseHandler.status200(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueUser, OwnerPerson] Downloads / Views the PDF receipt for the payment
	 * @param {string} paymentId
	 * @param {string} sessionToken
	 * @param {string} filename
	 * @param {{status200: function(string), status403: function(string), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	getPaymentPdf(paymentId, sessionToken, filename, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/file_asset/pdf/payment/' +
			(paymentId ? encodeURI(paymentId) : '') + '/' +
			(sessionToken ? encodeURI(sessionToken) : '') + '/' +
			(filename ? encodeURI(filename) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.text()
							.then(responseText => {
								responseHandler.status200(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 403:
					if (responseHandler.status403) {
						response.text()
							.then(responseText => {
								responseHandler.status403(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueUser, OwnerPerson] Downloads / Views the PDF year-end summary for the location
	 * @param {string} year
	 * @param {string} locationId
	 * @param {string} sessionToken
	 * @param {string} filename
	 * @param {{status200: function(string), status403: function(string), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	getYearEndPdf(year, locationId, sessionToken, filename, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/file_asset/pdf/year_end/' +
			(locationId ? encodeURI(locationId) : '') + '/' +
			(year ? encodeURI(year) : '') + '/' +
			(sessionToken ? encodeURI(sessionToken) : '') + '/' +
			(filename ? encodeURI(filename) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.text()
							.then(responseText => {
								responseHandler.status200(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 403:
					if (responseHandler.status403) {
						response.text()
							.then(responseText => {
								responseHandler.status403(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueAdmin] Downloads / Views the Excel CSV report for VGT Performance for the date range
	 * @param {string} locationId
	 * @param {string} dateFrom
	 * @param {string} dateTo
	 * @param {string} sessionToken
	 * @param {string} filename
	 * @param {{status200: function(string), status403: function(string), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	getVgtPerformanceCsv(locationId, dateFrom, dateTo, sessionToken, filename, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/file_asset/csv/vgt_performance/' +
			(locationId ? encodeURI(locationId) : '') + '/' +
			(dateFrom ? encodeURI(dateFrom) : '') + '/' +
			(dateTo ? encodeURI(dateTo) : '') + '/' +
			(sessionToken ? encodeURI(sessionToken) : '') + '/' +
			(filename ? encodeURI(filename) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.text()
							.then(responseText => {
								responseHandler.status200(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 403:
					if (responseHandler.status403) {
						response.text()
							.then(responseText => {
								responseHandler.status403(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [RevenueAdmin] Downloads / Views the Excel CSV report for the pay period
	 * @param {string} payPeriodId
	 * @param {string} sessionToken
	 * @param {string} filename
	 * @param {{status200: function(string), status403: function(string), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	getPayPeriodCsv(payPeriodId, sessionToken, filename, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/file_asset/csv/pay_period/' +
			(payPeriodId ? encodeURI(payPeriodId) : '') + '/' +
			(sessionToken ? encodeURI(sessionToken) : '') + '/' +
			(filename ? encodeURI(filename) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.text()
							.then(responseText => {
								responseHandler.status200(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 403:
					if (responseHandler.status403) {
						response.text()
							.then(responseText => {
								responseHandler.status403(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

}
