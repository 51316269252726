import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import TenantPreferences from "../TenantPreferences";
import ModelProxyClass from "./ModelProxyClass";


/**
 * @class TenantPreferencesBase
 * @extends ModelBaseClass
 * @property {string} lnwUsername
 * @property {string} lnwPassword
 * @property {string} igbUsername
 * @property {string} igbPassword
 */
class TenantPreferencesBase extends ModelBaseClass {
	/**
	 * @type {string} lnwUsername
	 */
	lnwUsername;
	/**
	 * @type {string} lnwPassword
	 */
	lnwPassword;
	/**
	 * @type {string} igbUsername
	 */
	igbUsername;
	/**
	 * @type {string} igbPassword
	 */
	igbPassword;

	/**
	 * Instantiates a new instance of TenantPreferences based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {TenantPreferences}
	 */
	static create(genericObject) {
		const newTenantPreferences = new TenantPreferences();
		newTenantPreferences.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newTenantPreferences;
	}

	/**
	 * Instantiates a new array of TenantPreferences based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[TenantPreferences]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newTenantPreferencesArray = [];
		genericArray.forEach(genericObject => {
			newTenantPreferencesArray.push(TenantPreferences.create(genericObject));
		});
		return newTenantPreferencesArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('lnwUsername', 'string'),
	ModelBaseClass.createModelProperty('lnwPassword', 'string'),
	ModelBaseClass.createModelProperty('igbUsername', 'string'),
	ModelBaseClass.createModelProperty('igbPassword', 'string'),
];

export default TenantPreferencesBase;
