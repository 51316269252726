import { useCore } from 'contexts/core-context'

/**
 * @typedef LogoLayoutProps
 * @property {string} title
 */

/**
 * @param {LogoLayoutProps} props
 */
const LogoLayout = ({ title, children }) => {
	const { tenant, tenantLogo } = useCore()
	const { ownersPortalName } = tenant ?? {}
	return (
		<div className="flex flex-col justify-center flex-1 min-h-full px-6 py-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<div className="flex flex-col items-center h-full">
					<img
						className="object-contain w-full rounded-lg aspect-[3/2]"
						src={tenantLogo?.src}
						alt={ownersPortalName}
					/>
					<h3 className="mt-2 text-2xl tracking-wide text-center font-leGrand">
						{ownersPortalName}
					</h3>
				</div>

				<h2 className="mt-6 text-xl font-light leading-9 tracking-tight text-center text-primary-700">
					{title}
				</h2>
			</div>

			<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">{children}</div>
		</div>
	)
}

export default LogoLayout
