import RevenuePaymentSearchResponseBase from './base/RevenuePaymentSearchResponseBase';

/**
 * @class RevenuePaymentSearchResponse
 * @extends RevenuePaymentSearchResponseBase
 */
class RevenuePaymentSearchResponse extends RevenuePaymentSearchResponseBase {

}

export default RevenuePaymentSearchResponse;
