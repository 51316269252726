import { ChangePasswordForm } from 'shared/containers/SettingForms'

const Password = () => {
	return (
		<div>
			<ChangePasswordForm />
		</div>
	)
}

export default Password
