import { cx } from 'class-variance-authority'
import PropTypes from 'prop-types'

/**
 * @typedef RowProps
 * @property {React.ReactNode} label
 */

/**
 * @param {RowProps} props
 */
const Row = ({ label, children }) => (
	<div className="px-4 py-6 border-t border-gray-100 sm:col-span-1 sm:px-0">
		<dt className="text-sm font-medium leading-6 text-gray-900">{label}</dt>
		<dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{children}</dd>
	</div>
)

Row.propTypes = {
	label: PropTypes.node,
	children: PropTypes.node
}

/**
 * @typedef DescriptionListProps
 * @property {React.ReactNode} [header]
 * @property {React.ReactNode} [description]
 * @property {number} [colSpan] - Number of columns to span, default is 2
 * @property {string} [containerClassName]
 */

/**
 * @param {DescriptionListProps} props
 */
const DescriptionList = ({ header, description, colSpan = 2, containerClassName, children }) => {
	return (
		<div>
			{(header || description) && (
				<div className="px-4 sm:px-0">
					<h3 className="text-base font-semibold leading-7 text-gray-900">{header}</h3>
					<p className="max-w-2xl mt-1 text-sm leading-6 text-gray-500">{description}</p>
				</div>
			)}
			<div className="mt-6">
				<dl className={cx(`grid grid-cols-1 sm:grid-cols-${colSpan}`, containerClassName)}>
					{children}
				</dl>
			</div>
		</div>
	)
}

DescriptionList.propTypes = {
	header: PropTypes.node,
	description: PropTypes.node,
	colSpan: PropTypes.number,
	containerClassName: PropTypes.string,
	children: PropTypes.node
}

DescriptionList.defaultProps = {
	colSpan: 2
}

DescriptionList.Row = Row

export default DescriptionList
