import LoginRequestBase from './base/LoginRequestBase';

/**
 * @class LoginRequest
 * @extends LoginRequestBase
 */
class LoginRequest extends LoginRequestBase {

}

export default LoginRequest;
