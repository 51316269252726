import { cva } from 'class-variance-authority'
import PropTypes from 'prop-types'

const variants = cva(
	/* base style */
	'absolute p-2 text-xs text-white transition-all scale-0 bg-gray-800 rounded group-hover:scale-100 min-w-max',
	{
		variants: {
			position: {
				top: 'bottom-6',
				bottom: 'top-6'
			}
		},
		defaultVariants: {}
	}
)

/**
 * @typedef TooltipProps
 * @property {string} [className]
 * @property {'top' | 'bottom'} [position] default is 'top'
 */

/**
 * @param {TooltipProps} props
 */
const Tooltip = ({ className, position, message, children }) => {
	if (!message) return children

	return (
		<div className="relative flex group w-fit">
			{children}
			<span className={variants({ className, position })}>{message}</span>
		</div>
	)
}

Tooltip.propTypes = {
	className: PropTypes.string,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	position: PropTypes.oneOf(['top', 'bottom']),
	children: PropTypes.node
}

Tooltip.defaultProps = {
	position: 'top'
}

export default Tooltip
