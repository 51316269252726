import { useCore } from 'contexts/core-context'
import PageHeader from 'layout/PageHeader'
import { Subheading } from 'shared/catalyst-components/heading'
import { Card } from 'shared/components'
import LocationFinancials from 'shared/containers/LocationFinancials'

const { PreviousPayPeriods, YearEndStatements } = LocationFinancials

const Financials = () => {
	const { location, tenant } = useCore()

	return (
		<div>
			<PageHeader title="Financials" />
			<div className="space-y-3 lg:space-y-6">
				<Card>
					<Subheading className="mb-4">Previous Pay Periods</Subheading>
					<PreviousPayPeriods locationId={location?.id} tenantId={tenant?.id} />
				</Card>

				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
					<Card>
						<Subheading className="mb-4">Year End Statements</Subheading>
						<YearEndStatements statements={location?.yearEndReports} locationId={location?.id} />
					</Card>
				</div>
			</div>
		</div>
	)
}

export default Financials
