import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import Payment from "../Payment";
import ModelProxyClass from "./ModelProxyClass";
import PayPeriod from "../PayPeriod";
import Location from "../Location";
import Person from "../Person";


/**
 * @class PaymentBase
 * @extends ModelBaseClass
 * @property {number} id (int64)
 * @property {PayPeriod} payPeriod
 * @property {Location} location
 * @property {number} fundsIn
 * @property {number} fundsOut
 * @property {number} netTerminalIncome
 * @property {number} adminFee
 * @property {number} stateTax
 * @property {number} netFunds
 * @property {number} subtotalRevenue
 * @property {number} subtotalReimbursements
 * @property {number} amount this is the total amount due, essentially (subtotalRevenue + subtotalReimbursements)
 * @property {Date} datePaid (date only)
 * @property {Person} paidByPerson
 * @property {Date} dateCreated (date and time)
 */
class PaymentBase extends ModelBaseClass {
	/**
	 * @type {number} id (int64)
	 */
	id;
	/**
	 * @type {PayPeriod} payPeriod
	 */
	payPeriod;
	/**
	 * @type {Location} location
	 */
	location;
	/**
	 * @type {number} fundsIn
	 */
	fundsIn;
	/**
	 * @type {number} fundsOut
	 */
	fundsOut;
	/**
	 * @type {number} netTerminalIncome
	 */
	netTerminalIncome;
	/**
	 * @type {number} adminFee
	 */
	adminFee;
	/**
	 * @type {number} stateTax
	 */
	stateTax;
	/**
	 * @type {number} netFunds
	 */
	netFunds;
	/**
	 * @type {number} subtotalRevenue
	 */
	subtotalRevenue;
	/**
	 * @type {number} subtotalReimbursements
	 */
	subtotalReimbursements;
	/**
	 * this is the total amount due, essentially (subtotalRevenue + subtotalReimbursements)
	 * @type {number} amount
	 */
	amount;
	/**
	 * @type {Date} datePaid (date only)
	 */
	datePaid;
	/**
	 * @type {Person} paidByPerson
	 */
	paidByPerson;
	/**
	 * @type {Date} dateCreated (date and time)
	 */
	dateCreated;

	/**
	 * Instantiates a new instance of Payment based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {Payment}
	 */
	static create(genericObject) {
		const newPayment = new Payment();
		newPayment.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newPayment;
	}

	/**
	 * Instantiates a new array of Payment based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[Payment]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newPaymentArray = [];
		genericArray.forEach(genericObject => {
			newPaymentArray.push(Payment.create(genericObject));
		});
		return newPaymentArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('id', 'integer'),
	ModelBaseClass.createModelProperty('payPeriod', 'PayPeriod'),
	ModelBaseClass.createModelProperty('location', 'Location'),
	ModelBaseClass.createModelProperty('fundsIn', 'float'),
	ModelBaseClass.createModelProperty('fundsOut', 'float'),
	ModelBaseClass.createModelProperty('netTerminalIncome', 'float'),
	ModelBaseClass.createModelProperty('adminFee', 'float'),
	ModelBaseClass.createModelProperty('stateTax', 'float'),
	ModelBaseClass.createModelProperty('netFunds', 'float'),
	ModelBaseClass.createModelProperty('subtotalRevenue', 'float'),
	ModelBaseClass.createModelProperty('subtotalReimbursements', 'float'),
	ModelBaseClass.createModelProperty('amount', 'float'),
	ModelBaseClass.createModelProperty('datePaid', 'datetime'),
	ModelBaseClass.createModelProperty('paidByPerson', 'Person'),
	ModelBaseClass.createModelProperty('dateCreated', 'datetime'),
];

export default PaymentBase;
