import { ArrowUturnLeftIcon } from '@heroicons/react/16/solid'
import { useCore } from 'contexts/core-context'
import { useGlobal } from 'contexts/global-context'
import { Form, Formik, FormikHelpers } from 'formik'
import useEffectOnce from 'hooks/useEffectOnce'
import { useNavigate, useParams } from 'react-router-dom'
import routePaths from 'routes/routePaths'
import Client from 'shared/clients/base/Client'
import UpdatePasswordRequest from 'shared/models/UpdatePasswordRequest'
import { Button, FormikText } from 'ui-toolkit-tailwind/src/components'
import { showToast } from 'utilities/showToast'
import * as yup from 'yup'

const ChangePassword = () => {
	const navigate = useNavigate()
	const { setShowSpinner } = useGlobal()
	const { updateSession, executeLogout } = useCore()
	// route params
	const { id, hash, checksum } = useParams()

	const validationSchema = yup.object({
		password: yup.string().required(),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref('password'), null], 'Passwords must match')
			.required(),
	})

	useEffectOnce(() => {
		executeLogout()
		if (id && hash && checksum) {
			Client.AuthenticationApi.createSessionForAlternativeLoginWorkflow(
				id,
				hash,
				checksum,
				{
					status200: session => updateSession(session),
					status404: () => navigate(routePaths.auth.noPermission, { replace: true }),
				},
				{
					onApiCall: () => setShowSpinner(true),
					onApiResponse: () => setShowSpinner(false),
				},
			)
		}
	})

	/**
	 *
	 * @param {{ password: string }} values
	 * @param {FormikHelpers<{ password: string }>} formikHelpers
	 */
	const handleSubmit = ({ password }, { setErrors }) => {
		const request = UpdatePasswordRequest.create({
			newPassword: password,
			changePasswordChecksum: checksum,
		})
		Client.PersonApi.updatePassword(
			request,
			{
				status200: () => {
					showToast({
						intent: 'success',
						title: 'Change Password',
						content: 'Password updated successfully',
					})
					navigate(routePaths.dashboard, { replace: true })
				},
				status409: () => {
					setErrors({ password: ' ', confirmPassword: 'New password is invalid' })
				},
			},
			{
				onApiCall: () => setShowSpinner(true),
				onApiResponse: () => setShowSpinner(false),
			},
		)
	}

	return (
		<>
			<Formik
				initialValues={{
					password: '',
					confirmPassword: '',
				}}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				<Form className="space-y-6">
					<FormikText
						label="New Password"
						name="password"
						type="password"
						id="password"
						autoComplete="new-password"
					/>
					<FormikText
						label="Confirm Password"
						name="confirmPassword"
						type="password"
						id="confirmPassword"
						autoComplete="new-password"
					/>
					<Button type="submit" fullWidth>
						Update Password
					</Button>
				</Form>
			</Formik>

			<button
				onClick={() => navigate(routePaths.auth.login)}
				className="!mt-3 text-sm cursor-pointer text-gray-500 hover:text-gray-700 focus:ring-0"
			>
				<div className="flex items-center">
					<ArrowUturnLeftIcon className="w-4 h-4 mr-1" />
					Back to Login
				</div>
			</button>
		</>
	)
}

export default ChangePassword
