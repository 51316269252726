export default function downloadFile(link, filename) {
	fetch(link)
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob)
			const a = document.createElement('a')
			a.href = url
			a.download = filename
			a.click()
			URL.revokeObjectURL(url)
		})
		.catch(error => {
			console.error('File download failed:', error)
		})
}
