import { cva, cx } from 'class-variance-authority'
import PropTypes from 'prop-types'
import { useId } from 'react'

const variants = cva(
	/* base style */
	'block w-full rounded-md border-0 pb-1.5 text-gray-600 sm:text-sm sm:leading-6',
	{
		variants: {},
		defaultVariants: {}
	}
)

/**
 * @typedef ReadOnlyFieldProps
 * @property {string} [className]
 * @property {string} [label]
 * @property {React.ReactNode} [helperText] Helper text to display below the label (hide when there's error message)
 * @property {string} [cornerHint] Hint text to display next to the label
 * @property {boolean} [pill] Apply pill style to the input
 */

/**
 * @param {ReadOnlyFieldProps & React.InputHTMLAttributes} props
 */
const ReadOnlyField = ({ className, label, value, helperText, cornerHint, pill, ...props }) => {
	const randomId = useId()
	const descriptionId = `${randomId}-description`

	const hasLabel = !!label

	return (
		<div className="w-full">
			{hasLabel && (
				<div className={cx('flex justify-between', pill && 'px-4')}>
					<label className="block text-sm font-medium leading-6 text-gray-900">{label}</label>
					{cornerHint && (
						<span className="text-xs leading-6 text-gray-500" id={descriptionId}>
							{cornerHint}
						</span>
					)}
				</div>
			)}

			<div className={cx('relative rounded-md', hasLabel && 'mt-2')}>
				<label
					className={variants({ className, pill })}
					aria-describedby={descriptionId}
					{...props}
				>
					{value}
				</label>
			</div>
		</div>
	)
}

ReadOnlyField.propTypes = {
	className: PropTypes.string,
	label: PropTypes.node,
	value: PropTypes.node,
	helperText: PropTypes.string,
	cornerHint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	pill: PropTypes.bool
}

ReadOnlyField.defaultProps = {}

export default ReadOnlyField
