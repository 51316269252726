import AuthenticationApi from "./AuthenticationApi";
import UtilityApi from "./UtilityApi";
import PersonApi from "./PersonApi";
import TenantApi from "./TenantApi";
import LocationApi from "./LocationApi";
import LocationGroupApi from "./LocationGroupApi";
import RevenueApi from "./RevenueApi";
import FileAssetApi from "./FileAssetApi";

/**
 * Use globally to access any of the API Client Methods for the WebService
 */
export default class Client {
}

/**
 * Use in a responseHandler if you want to ignore a given/specific response
 */
export function ignoreResponse() {
}

Client.AuthenticationApi = new AuthenticationApi();
Client.UtilityApi = new UtilityApi();
Client.PersonApi = new PersonApi();
Client.TenantApi = new TenantApi();
Client.LocationApi = new LocationApi();
Client.LocationGroupApi = new LocationGroupApi();
Client.RevenueApi = new RevenueApi();
Client.FileAssetApi = new FileAssetApi();
