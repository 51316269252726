import SessionBase from './base/SessionBase';

/**
 * @class Session
 * @extends SessionBase
 */
class Session extends SessionBase {
  getSessionToken() {
    return `${this.id}-${this.hash}`
  }
}

export default Session;
