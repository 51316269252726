import * as Headless from '@headlessui/react'
import { forwardRef } from 'react'
import { Link as RouterDomLink } from 'react-router-dom'

export const Link = forwardRef(({ routerLink, ...props }, ref) => {
	return (
		<Headless.DataInteractive>
			{routerLink ? (
				<RouterDomLink to={routerLink} {...props} ref={ref}>
					{props.children}
				</RouterDomLink>
			) : (
				<a {...props} ref={ref}>
					{props.children}
				</a>
			)}
		</Headless.DataInteractive>
	)
})
