import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import Person from "../Person";
import ModelProxyClass from "./ModelProxyClass";
import OwnerDetails from "../OwnerDetails";
import UserDetails from "../UserDetails";


/**
 * @class PersonBase
 * @extends ModelBaseClass
 * @property {number} id (int64)
 * @property {'User'|'Owner'} type
 * @property {string} name
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {Date} dateLastAccess (date and time) can be null
 * @property {Date} dateRegistered (date and time) can be null
 * @property {OwnerDetails} ownerDetails set if type is Owner
 * @property {UserDetails} userDetails set if type is User
 * @property {boolean} activeFlag this is whether the ENTIRE PERSON RECORD is considered active.  This is USUALLY true.  It is only meant to be deactivated when deactivating a ETI User, either EtiAdmin or EtiStaff
 * @property {Date} dateCreated (date and time)
 */
class PersonBase extends ModelBaseClass {
	/**
	 * @type {number} id (int64)
	 */
	id;
	/**
	 * @type {'User'|'Owner'} type
	 */
	type;
	/**
	 * @type {string} name
	 */
	name;
	/**
	 * @type {string} firstName
	 */
	firstName;
	/**
	 * @type {string} lastName
	 */
	lastName;
	/**
	 * @type {string} email
	 */
	email;
	/**
	 * can be null
	 * @type {Date} dateLastAccess (date and time)
	 */
	dateLastAccess;
	/**
	 * can be null
	 * @type {Date} dateRegistered (date and time)
	 */
	dateRegistered;
	/**
	 * set if type is Owner
	 * @type {OwnerDetails} ownerDetails
	 */
	ownerDetails;
	/**
	 * set if type is User
	 * @type {UserDetails} userDetails
	 */
	userDetails;
	/**
	 * this is whether the ENTIRE PERSON RECORD is considered active.  This is USUALLY true.  It is only meant to be deactivated when deactivating a ETI User, either EtiAdmin or EtiStaff
	 * @type {boolean} activeFlag
	 */
	activeFlag;
	/**
	 * @type {Date} dateCreated (date and time)
	 */
	dateCreated;

	/**
	 * Instantiates a new instance of Person based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {Person}
	 */
	static create(genericObject) {
		const newPerson = new Person();
		newPerson.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newPerson;
	}

	/**
	 * Instantiates a new array of Person based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[Person]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newPersonArray = [];
		genericArray.forEach(genericObject => {
			newPersonArray.push(Person.create(genericObject));
		});
		return newPersonArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('id', 'integer'),
	ModelBaseClass.createModelProperty('type', 'string'),
	ModelBaseClass.createModelProperty('name', 'string'),
	ModelBaseClass.createModelProperty('firstName', 'string'),
	ModelBaseClass.createModelProperty('lastName', 'string'),
	ModelBaseClass.createModelProperty('email', 'string'),
	ModelBaseClass.createModelProperty('dateLastAccess', 'datetime'),
	ModelBaseClass.createModelProperty('dateRegistered', 'datetime'),
	ModelBaseClass.createModelProperty('ownerDetails', 'OwnerDetails'),
	ModelBaseClass.createModelProperty('userDetails', 'UserDetails'),
	ModelBaseClass.createModelProperty('activeFlag', 'boolean'),
	ModelBaseClass.createModelProperty('dateCreated', 'datetime'),
];

export default PersonBase;
