import { BsFileEarmarkPdf } from 'react-icons/bs'
import { Button } from 'shared/catalyst-components/button'
import { downloadYearEndFile } from 'shared/utilities/downloadFileAssets'

/**
 * @typedef ComponentProps
 * @property {number[]} years
 * @property {number} locationId
 */

/**
 * @param {ComponentProps} props
 */
const YearEndStatements = ({ years, locationId }) => {
	return (
		<div>
			{years?.map((year, index) => (
				<div
					key={index}
					className="flex items-center justify-between py-3 border-t border-zinc-950/5 first:border-none"
				>
					<label className="text-zinc-500">{year}</label>
					<Button plain onClick={() => downloadYearEndFile(locationId, year)}>
						<BsFileEarmarkPdf />
						Download PDF
					</Button>
				</div>
			))}

			{(!years || years.length === 0) && (
				<div className="text-sm text-zinc-500">No statements found</div>
			)}
		</div>
	)
}

export default YearEndStatements
