import VgtPerformanceMetricBase from './base/VgtPerformanceMetricBase';

/**
 * @class VgtPerformanceMetric
 * @extends VgtPerformanceMetricBase
 */
class VgtPerformanceMetric extends VgtPerformanceMetricBase {

}

export default VgtPerformanceMetric;
