import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import IgbModel from "../IgbModel";
import ModelProxyClass from "./ModelProxyClass";


/**
 * @class IgbModelBase
 * @extends ModelBaseClass
 * @property {number} id (int64)
 * @property {string} code
 * @property {string} name
 */
class IgbModelBase extends ModelBaseClass {
	/**
	 * @type {number} id (int64)
	 */
	id;
	/**
	 * @type {string} code
	 */
	code;
	/**
	 * @type {string} name
	 */
	name;

	/**
	 * Instantiates a new instance of IgbModel based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {IgbModel}
	 */
	static create(genericObject) {
		const newIgbModel = new IgbModel();
		newIgbModel.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newIgbModel;
	}

	/**
	 * Instantiates a new array of IgbModel based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[IgbModel]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newIgbModelArray = [];
		genericArray.forEach(genericObject => {
			newIgbModelArray.push(IgbModel.create(genericObject));
		});
		return newIgbModelArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('id', 'integer'),
	ModelBaseClass.createModelProperty('code', 'string'),
	ModelBaseClass.createModelProperty('name', 'string'),
];

export default IgbModelBase;
