import { useCallback } from 'react'
import Client from 'shared/clients/base/Client'
import { ActionButton } from 'shared/components'
import Payment from 'shared/models/Payment'
import ResultParameter from 'shared/models/ResultParameter'
import RevenuePaymentSearchRequest from 'shared/models/RevenuePaymentSearchRequest'
import { downloadPaymentFile } from 'shared/utilities/downloadFileAssets'
import { displayAmount, displayDate } from 'shared/utilities/helpers'
import { DataGrid } from 'ui-toolkit-tailwind/src/components'

/**
 * @typedef PreviousPayPeriodsProps
 * @property {number} locationId
 * @property {number} tenantId
 */

/**
 * @param {PreviousPayPeriodsProps} props
 */
const PreviousPayPeriods = ({ locationId, tenantId }) => {
	const queryPayPeriods = useCallback(
		table => {
			if (!locationId || !tenantId) return

			const request = new RevenuePaymentSearchRequest()
			request.locationId = locationId
			request.resultParameter = ResultParameter.create(table.getResultParameter())

			Client.RevenueApi.searchPayments(request, {
				status200: response => table.bindData(response.payments, response.totalItemCount),
			})
		},
		[locationId],
	)

	/**
	 * @param {Payment} payment
	 */
	const renderDownloadButton = payment => (
		<ActionButton.Download tooltipMessage="Download" onClick={() => downloadPaymentFile(payment)} />
	)

	return (
		<DataGrid
			queryData={queryPayPeriods}
			columns={[
				{
					name: 'payPeriod',
					label: 'Start Date',
					formatter: payPeriod => displayDate(payPeriod.dateStart),
				},
				{
					name: 'payPeriod',
					label: 'End Date',
					formatter: payPeriod => displayDate(payPeriod.dateEnd),
				},
				{ name: 'payPeriod', label: 'Type', formatter: payPeriod => payPeriod.payPeriodType },
				{
					name: 'datePaid',
					label: 'Date Paid',
					formatter: datePaid => (datePaid ? displayDate(datePaid) : 'Unpaid'),
				},
				{ name: 'subtotalRevenue', label: 'Location Revenue', formatter: displayAmount },
				{ name: 'subtotalReimbursements', label: 'Reimbursements', formatter: displayAmount },
				{ name: 'amount', label: 'Total', formatter: displayAmount },
				{ rowData: true, headerClassName: 'w-[40px]', formatter: renderDownloadButton },
			]}
			defaultOrderBy={RevenuePaymentSearchRequest.OrderByDatePeriodStart}
			defaultOrderAscending={false}
		/>
	)
}

export default PreviousPayPeriods
