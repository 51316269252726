import { cx } from 'class-variance-authority'
import PropTypes from 'prop-types'
import { useEffect, useId, useRef, useState } from 'react'

import Button from '../../Elements/Button'
import placeholderUrl from './placeholder.svg'

/**
 * @typedef ImageUploadProps
 * @property {function} onChange
 * @property {string} [className]
 * @property {string} [name]
 * @property {string} [label]
 * @property {React.ReactNode} [helperText] Helper text to display below the label (hide when there's error message)
 * @property {boolean} [disabled]
 * @property {boolean} [invalid]
 * @property {string} [errorMessage]
 * @property {string} [cornerHint] Hint text to display on the right side of the label
 * @property {string} [accept] File types to accept
 * @property {string} [initialImageUrl] URL of the initial image
 * @property {string} [buttonLabel] Label of the button
 * @property {string} [overwriteImageClassName] Class name to overwrite the image class name
 */

/**
 * @param {ImageUploadProps & React.InputHTMLAttributes} props
 */
const ImageUpload = ({
	onChange,
	className,
	name,
	label,
	helperText,
	cornerHint,
	disabled,
	invalid,
	errorMessage,
	accept,
	initialImageUrl,
	buttonLabel,
	overwriteImageClassName,
	...props
}) => {
	const inputRef = useRef(/** @type {React.InputHTMLAttributes} */ (null))
	const randomId = useId()
	const descriptionId = `${name || randomId}-description`

	const [imageUrl, setImageUrl] = useState(initialImageUrl)

	const hasLabel = !!label
	const hasError = invalid && errorMessage

	useEffect(() => {
		setImageUrl(initialImageUrl)
	}, [initialImageUrl])

	const handleButtonClick = () => {
		const inputElement = inputRef.current
		if (inputElement) {
			inputElement.click()
		}
	}

	const handleFileChange = event => {
		const file = event.target.files[0]
		if (onChange) onChange(file)
		if (file) {
			const reader = new FileReader()
			reader.onload = e => {
				setImageUrl(e.target.result)
			}
			reader.readAsDataURL(file)
		}
	}

	return (
		<div className="w-full">
			{hasLabel && (
				<div className={cx('flex justify-between')}>
					<label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
						{label}
					</label>
					{cornerHint && (
						<span className="text-xs leading-6 text-gray-500" id={descriptionId}>
							{cornerHint}
						</span>
					)}
				</div>
			)}

			{/** hidden input to handle File type value */}
			<input className="hidden" {...props} name={name} />

			{/** hidden input to handle file upload */}
			<input
				ref={inputRef}
				type="file"
				accept={accept || 'image/*'}
				disabled={disabled}
				onChange={handleFileChange}
				className="hidden"
				alt="Upload image"
			/>

			<div
				className={cx(
					'flex flex-col gap-y-6 sm:flex-row items-center sm:items-start sm:gap-x-8',
					hasLabel && 'mt-2',
					!!helperText && '!items-center'
				)}
			>
				<img
					alt="img-preview"
					src={imageUrl || placeholderUrl}
					className={cx(
						'flex-none object-fill w-full bg-transparent rounded-lg sm:w-auto sm:h-24 max-h-48 max-w-48',
						overwriteImageClassName
					)}
				/>
				<div className="text-center sm:text-left">
					<Button onClick={handleButtonClick}>
						{imageUrl ? 'Change' : 'Upload'} {buttonLabel || 'Image'}
					</Button>
					{helperText && <p className="mt-2 text-xs leading-5 text-gray-500">{helperText}</p>}
				</div>
			</div>

			{hasError && (
				<p className={cx('mt-2 text-sm text-danger-600')} id={descriptionId}>
					{errorMessage}
				</p>
			)}
		</div>
	)
}

ImageUpload.propTypes = {
	onChange: PropTypes.func,
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.node,
	helperText: PropTypes.string,
	cornerHint: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	disabled: PropTypes.bool,
	invalid: PropTypes.bool,
	errorMessage: PropTypes.string,
	accept: PropTypes.string,
	initialImageUrl: PropTypes.string,
	buttonLabel: PropTypes.string,
	overwriteImageClassName: PropTypes.string
}

ImageUpload.defaultProps = {}

export default ImageUpload
