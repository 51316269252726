import { useEffect, useState } from 'react'
import ReimbursementStatusEnum from 'shared/models/enum/ReimbursementStatusEnum'
import { Select } from 'ui-toolkit-tailwind/src/components'

export const statusEnum = Object.freeze({
	ALL: '@all',
	NOT_YET_PAID: 'notYetPaid',
	REQUESTED: 'requested',
	DECLINED: 'declined',
	APPROVED: 'approved',
	PAID: 'paid',
})

const statusOptions = [
	{ label: 'All', value: statusEnum.ALL },
	{ label: 'Not Yet Paid', value: statusEnum.NOT_YET_PAID },
	{ label: 'Requested', value: statusEnum.REQUESTED },
	{ label: 'Declined', value: statusEnum.DECLINED },
	{ label: 'Approved', value: statusEnum.APPROVED },
	{ label: 'Paid', value: statusEnum.PAID },
]

/**
 * @typedef FilterProps
 * @property {function(statuses: string[])} onChange
 */

/**
 * @param {FilterProps} props
 */
const Filter = ({ onChange }) => {
	const [value, setValue] = useState(statusEnum.NOT_YET_PAID)

	useEffect(() => {
		switch (value) {
			case statusEnum.ALL:
				onChange([
					ReimbursementStatusEnum.REQUESTED,
					ReimbursementStatusEnum.APPROVED,
					ReimbursementStatusEnum.DECLINED,
					ReimbursementStatusEnum.PAID,
				])
				break
			case statusEnum.NOT_YET_PAID:
				onChange([
					ReimbursementStatusEnum.REQUESTED,
					ReimbursementStatusEnum.APPROVED,
					ReimbursementStatusEnum.DECLINED,
				])
				break
			case statusEnum.REQUESTED:
				onChange([ReimbursementStatusEnum.REQUESTED])
				break
			case statusEnum.DECLINED:
				onChange([ReimbursementStatusEnum.DECLINED])
				break
			case statusEnum.APPROVED:
				onChange([ReimbursementStatusEnum.APPROVED])
				break
			case statusEnum.PAID:
				onChange([ReimbursementStatusEnum.PAID])
				break
			default:
				onChange([])
				break
		}
	}, [value, onChange])

	return (
		<div>
			<h3 className="mb-1 ml-1 text-xs font-semibold text-gray-600">Filter By Status</h3>
			<Select
				className="w-full sm:!w-[250px]"
				options={statusOptions}
				value={value}
				onChange={setValue}
			/>
		</div>
	)
}

export default Filter
