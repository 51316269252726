import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import RevenuePaymentSearchResponse from "../RevenuePaymentSearchResponse";
import ModelProxyClass from "./ModelProxyClass";
import Payment from "../Payment";


/**
 * @class RevenuePaymentSearchResponseBase
 * @extends ModelBaseClass
 * @property {[Payment]} payments
 * @property {number} totalItemCount (int64)
 */
class RevenuePaymentSearchResponseBase extends ModelBaseClass {
	/**
	 * @type {[Payment]} payments
	 */
	payments;
	/**
	 * @type {number} totalItemCount (int64)
	 */
	totalItemCount;

	/**
	 * Instantiates a new instance of RevenuePaymentSearchResponse based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {RevenuePaymentSearchResponse}
	 */
	static create(genericObject) {
		const newRevenuePaymentSearchResponse = new RevenuePaymentSearchResponse();
		newRevenuePaymentSearchResponse.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newRevenuePaymentSearchResponse;
	}

	/**
	 * Instantiates a new array of RevenuePaymentSearchResponse based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[RevenuePaymentSearchResponse]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newRevenuePaymentSearchResponseArray = [];
		genericArray.forEach(genericObject => {
			newRevenuePaymentSearchResponseArray.push(RevenuePaymentSearchResponse.create(genericObject));
		});
		return newRevenuePaymentSearchResponseArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('payments', '[Payment]'),
	ModelBaseClass.createModelProperty('totalItemCount', 'integer'),
];

export default RevenuePaymentSearchResponseBase;
