import SystemSettingsBase from './base/SystemSettingsBase';

/**
 * @class SystemSettings
 * @extends SystemSettingsBase
 */
class SystemSettings extends SystemSettingsBase {

}

export default SystemSettings;
