import { useCore } from 'contexts/core-context'
import { useGlobal } from 'contexts/global-context'
import useEffectOnce from 'hooks/useEffectOnce'
import { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Toaster } from 'react-hot-toast'
import AppRoutes from 'routes'
import { Spinner } from 'ui-toolkit-tailwind/src/components'

function App() {
	const env = process.env.REACT_APP_ENVIRONMENT

	const { isAppReady, initialize, tenant, tenantLogo } = useCore()
	const { showSpinner, setShowSpinner } = useGlobal()

	useEffectOnce(initialize)

	useEffect(() => {
		setShowSpinner(!isAppReady)
	}, [isAppReady, setShowSpinner])

	return (
		<HelmetProvider>
			<Helmet>
				<title>{tenant?.ownersPortalName}</title>
				<link rel="icon" href={tenantLogo?.src} />
				<link rel="apple-touch-icon" href={tenantLogo?.src} />
			</Helmet>

			<div className="h-full">
				{env !== 'production' && <div className="z-50 env-mode">env: {env}</div>}
				{isAppReady && <AppRoutes />}

				<Spinner visible={showSpinner} />
				<Toaster />
			</div>
		</HelmetProvider>
	)
}

export default App
