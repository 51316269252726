import { useMemo } from 'react'
import ReportedVgtMachine from 'shared/models/ReportedVgtMachine'
import VgtPerformanceMetric from 'shared/models/VgtPerformanceMetric'
import { displayAmount } from 'shared/utilities/helpers'
import { Table, Tooltip } from 'ui-toolkit-tailwind/src/components'

/**
 * @typedef ComponentProps
 * @property {VgtPerformanceMetric[]} performanceData
 * @property {ReportedVgtMachine[]} machines
 */

/**
 * @param {ComponentProps} props
 */
const PerformanceTable = ({ performanceData, machines }) => {
	const data = useMemo(() => {
		if (!performanceData || !machines) return null

		return performanceData.map(performance => {
			const machine = machines.find(machine => machine.id === performance.reportedVgtMachineId)
			return {
				...performance,
				machine,
			}
		})
	}, [performanceData, machines])

	return (
		<Table
			data={data}
			columns={[
				{
					name: 'machine',
					label: 'Machine',
					formatter: machine => (
						<Tooltip message={machine?.displayName}>
							<label className="line-clamp-1 w-[150px] md:w-[250px] !whitespace-normal">
								{machine?.displayName}
							</label>
						</Tooltip>
					),
				},
				{ name: 'machine', label: 'License #', formatter: machine => machine?.licenseNumber },
				{ name: 'fundsIn', label: 'Funds In', formatter: displayAmount },
				{ name: 'amountPlayed', label: 'Amount Played', formatter: displayAmount },
				{ name: 'netTerminalIncome', label: 'Net Terminal Income', formatter: displayAmount },
				{ name: 'locationRevenue', label: 'Location Revenue', formatter: displayAmount },
			]}
		/>
	)
}

export default PerformanceTable
