import {
	ChevronDoubleLeftIcon,
	ChevronDoubleRightIcon,
	ChevronLeftIcon,
	ChevronRightIcon
} from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import Button from '../../Elements/Button'

/**
 * @typedef PaginationProps
 * @property {number} [currentPage]
 * @property {(nextPage: number) => void} onPageChange
 * @property {number} [totalCount]
 * @property {number} itemsPerPage
 */

/**
 * @param {PaginationProps} props
 */
const Pagination = ({ currentPage, onPageChange, totalCount, itemsPerPage }) => {
	const [pageInputValue, setPageInputValue] = useState(String(currentPage))
	const totalPages = Math.ceil((totalCount || 0) / itemsPerPage)
	const startingIndex = (currentPage - 1) * itemsPerPage + 1
	const endIndex = Math.min(currentPage * itemsPerPage, totalCount)

	const canGoNext = currentPage < totalPages
	const canGoPrevious = currentPage > 1
	const canGoFirst = currentPage !== 1
	const canGoLast = currentPage !== totalPages

	useEffect(() => {
		setPageInputValue(String(currentPage))
	}, [currentPage])

	const handleNextPage = () => {
		if (canGoNext) onPageChange(currentPage + 1)
	}
	const handlePreviousPage = () => {
		if (canGoPrevious) onPageChange(currentPage - 1)
	}

	/**
	 * @param {KeyboardEvent} e
	 */
	const handlePageInputKeyDown = e => {
		if (e.key === 'Enter') {
			const value = Number(e.target.value)
			let newPage = 1
			if (Number.isInteger(value)) {
				if (value < 1) {
					newPage = 1
				} else if (value > totalPages) {
					newPage = totalPages
				} else {
					newPage = value
				}
			} else newPage = currentPage

			setPageInputValue(newPage)
			if (currentPage !== newPage) onPageChange(newPage)
		}
	}

	const handlePageInputBlur = () => {
		let newPage = 1
		if (Number.isInteger(pageInputValue)) {
			if (pageInputValue < 1) {
				newPage = 1
			} else if (pageInputValue > totalPages) {
				newPage = totalPages
			} else {
				newPage = pageInputValue
			}
		} else newPage = currentPage

		setPageInputValue(newPage)
		if (currentPage !== newPage) onPageChange(newPage)
	}

	return (
		<div className="flex items-center justify-between px-4 py-3 sm:px-3">
			<div className="flex justify-between flex-1 sm:hidden">
				<Button
					intent="secondary"
					className="w-[100px]"
					onClick={handlePreviousPage}
					disabled={!canGoPrevious}
				>
					Previous
				</Button>
				<Button
					intent="secondary"
					className="w-[100px]"
					onClick={handleNextPage}
					disabled={!canGoNext}
				>
					Next
				</Button>
			</div>
			<div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
				<div>
					<p className="text-sm text-gray-700">
						Showing <span className="font-medium">{startingIndex}</span> to{' '}
						<span className="font-medium">{endIndex}</span> of{' '}
						<span className="font-medium">{totalCount}</span> results
					</p>
				</div>
				<div>
					<nav
						className="inline-flex -space-x-px rounded-md shadow-sm isolate"
						aria-label="Pagination"
					>
						<button
							className="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-l-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
							onClick={() => onPageChange(1)}
							disabled={!canGoFirst}
						>
							<span className="sr-only">Previous</span>
							<ChevronDoubleLeftIcon className="w-5 h-5" aria-hidden="true" />
						</button>
						<button
							className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
							onClick={handlePreviousPage}
							disabled={!canGoPrevious}
						>
							<span className="sr-only">Previous</span>
							<ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
						</button>

						<span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
							<input
								className="w-[2rem] text-center p-0 border-0 font-normal outline-none bg-inherit focus:ring-0"
								value={pageInputValue}
								onChange={e => setPageInputValue(e.target.value)}
								onKeyDown={handlePageInputKeyDown}
								onBlur={handlePageInputBlur}
							/>
						</span>

						<button
							className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
							onClick={handleNextPage}
							disabled={!canGoNext}
						>
							<span className="sr-only">Next</span>
							<ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
						</button>
						<button
							className="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-r-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
							onClick={() => onPageChange(totalPages)}
							disabled={!canGoLast}
						>
							<span className="sr-only">Next</span>
							<ChevronDoubleRightIcon className="w-5 h-5" aria-hidden="true" />
						</button>
					</nav>
				</div>
			</div>
		</div>
	)
}

Pagination.propTypes = {
	currentPage: PropTypes.number,
	onPageChange: PropTypes.func,
	totalCount: PropTypes.number.isRequired,
	itemsPerPage: PropTypes.number.isRequired
}

Pagination.defaultProps = {
	currentPage: 1
}

export default Pagination
