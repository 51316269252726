import PersonalInformationForm from './components/PersonalInformationForm'

const Profile = () => {
	return (
		<div>
			<PersonalInformationForm />
		</div>
	)
}

export default Profile
