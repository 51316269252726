import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import UpdatePasswordRequest from "../UpdatePasswordRequest";
import ModelProxyClass from "./ModelProxyClass";


/**
 * @class UpdatePasswordRequestBase
 * @extends ModelBaseClass
 * @property {string} newPassword
 * @property {string} currentPassword ignored if changing password immediately after alternative login workflow (new registration or forgot password).  otherwise this is required.
 * @property {string} changePasswordChecksum required if changing password immediately after alternative login workflow (new registration or forgot password).  otherwise this is ignored.
 */
class UpdatePasswordRequestBase extends ModelBaseClass {
	/**
	 * @type {string} newPassword
	 */
	newPassword;
	/**
	 * ignored if changing password immediately after alternative login workflow (new registration or forgot password).  otherwise this is required.
	 * @type {string} currentPassword
	 */
	currentPassword;
	/**
	 * required if changing password immediately after alternative login workflow (new registration or forgot password).  otherwise this is ignored.
	 * @type {string} changePasswordChecksum
	 */
	changePasswordChecksum;

	/**
	 * Instantiates a new instance of UpdatePasswordRequest based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {UpdatePasswordRequest}
	 */
	static create(genericObject) {
		const newUpdatePasswordRequest = new UpdatePasswordRequest();
		newUpdatePasswordRequest.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newUpdatePasswordRequest;
	}

	/**
	 * Instantiates a new array of UpdatePasswordRequest based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[UpdatePasswordRequest]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newUpdatePasswordRequestArray = [];
		genericArray.forEach(genericObject => {
			newUpdatePasswordRequestArray.push(UpdatePasswordRequest.create(genericObject));
		});
		return newUpdatePasswordRequestArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('newPassword', 'string'),
	ModelBaseClass.createModelProperty('currentPassword', 'string'),
	ModelBaseClass.createModelProperty('changePasswordChecksum', 'string'),
];

export default UpdatePasswordRequestBase;
