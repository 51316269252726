import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { cva, cx } from 'class-variance-authority'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const variants = cva(
	/* base style */
	'',
	{
		variants: {},
		defaultVariants: {}
	}
)

/**
 * @typedef BreadcrumbsProps
 * @property {{ name: string, href: string }[]} pages
 */

/**
 * @param {BreadcrumbsProps} props
 */
const Breadcrumbs = ({ pages }) => {
	return (
		<nav aria-label="Breadcrumb" className="flex">
			<ol className="flex items-center space-x-1">
				{pages?.map((page, index) => {
					const { name, href } = page
					if (index === 0)
						return (
							<li key={index}>
								<div>
									<Link
										to={href}
										className={cx(
											'text-xs font-medium cursor-default text-gray-500 ',
											href && 'cursor-pointer hover:text-primary-700'
										)}
									>
										{name}
									</Link>
								</div>
							</li>
						)

					return (
						<li key={index}>
							<div className="flex items-center">
								<ChevronRightIcon
									aria-hidden="true"
									className="flex-shrink-0 w-5 h-5 text-gray-400"
								/>
								<Link
									to={href}
									className={cx(
										'ml-1 text-xs font-medium cursor-default text-gray-500 ',
										href && 'cursor-pointer hover:text-primary-700'
									)}
								>
									{name}
								</Link>
							</div>
						</li>
					)
				})}
			</ol>
		</nav>
	)
}

Breadcrumbs.propTypes = {
	pages: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			href: PropTypes.string,
			current: PropTypes.bool
		})
	)
}

Breadcrumbs.defaultProps = {}

export default Breadcrumbs
