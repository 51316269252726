import RevenuePayPeriodSearchResponseBase from './base/RevenuePayPeriodSearchResponseBase';

/**
 * @class RevenuePayPeriodSearchResponse
 * @extends RevenuePayPeriodSearchResponseBase
 */
class RevenuePayPeriodSearchResponse extends RevenuePayPeriodSearchResponseBase {

}

export default RevenuePayPeriodSearchResponse;
