import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import Location from "../Location";
import ModelProxyClass from "./ModelProxyClass";
import ReportedVgtMachine from "../ReportedVgtMachine";
import Person from "../Person";
import LocationGroup from "../LocationGroup";


/**
 * @class LocationBase
 * @extends ModelBaseClass
 * @property {number} id (int64)
 * @property {number} tenantId (int64)
 * @property {number} locationGroupId (int64)
 * @property {string} name
 * @property {string} currentLicenseNumber
 * @property {boolean} licenseNumberConfirmedFlag once a license number is confirmed, it can no longer be edited.  only a new license number can be assigned to it.
 * @property {string} address1
 * @property {string} address2
 * @property {string} city
 * @property {number} countyId (int64)
 * @property {string} state
 * @property {string} zip
 * @property {string} phone
 * @property {number} latitude
 * @property {number} longitude
 * @property {'Weekly'|'Monthly'} payPeriodType
 * @property {Date} dateFirstPerformanceLogged (date only)
 * @property {Date} dateLastPerformanceLogged (date only)
 * @property {number} lastFundsIn
 * @property {number} lastNti
 * @property {number} ownerCount (integer)
 * @property {[string]} previousLicenseNumbers only returned when calling LocationApi::get -- note, once a currentLicenseNumber is confirmed, that licenseNumber _will_ show up in this array
 * @property {[ReportedVgtMachine]} reportedVgtMachines only returned when calling LocationApi::get
 * @property {[number]} yearEndReports only returned when calling LocationApi::get
 * @property {[Person]} owners only returned when NOT part of a LocationGroup, and when calling LocationApi::get() and ::search().  If not part of a LocationGroup, this can be optionally set with owners to setup when calling LocationApi::edit().
 * @property {LocationGroup} locationGroup can be NULL if not part of a group.  only returned when calling LocationApi::get() and ::search().  This can be optionally set/updated when calling LocationApi::edit().  (.locationGroup.id should be NULL if setting to a NEW locationGroup).  When part of a LocationGroup (either existing or new), the .locationGroup.owners[] can also be optionall set with owners to setup when calling LocationApi::edit()
 * @property {boolean} activeFlag
 * @property {Date} dateCreated (date and time)
 */
class LocationBase extends ModelBaseClass {
	/**
	 * @type {number} id (int64)
	 */
	id;
	/**
	 * @type {number} tenantId (int64)
	 */
	tenantId;
	/**
	 * @type {number} locationGroupId (int64)
	 */
	locationGroupId;
	/**
	 * @type {string} name
	 */
	name;
	/**
	 * @type {string} currentLicenseNumber
	 */
	currentLicenseNumber;
	/**
	 * once a license number is confirmed, it can no longer be edited.  only a new license number can be assigned to it.
	 * @type {boolean} licenseNumberConfirmedFlag
	 */
	licenseNumberConfirmedFlag;
	/**
	 * @type {string} address1
	 */
	address1;
	/**
	 * @type {string} address2
	 */
	address2;
	/**
	 * @type {string} city
	 */
	city;
	/**
	 * @type {number} countyId (int64)
	 */
	countyId;
	/**
	 * @type {string} state
	 */
	state;
	/**
	 * @type {string} zip
	 */
	zip;
	/**
	 * @type {string} phone
	 */
	phone;
	/**
	 * @type {number} latitude
	 */
	latitude;
	/**
	 * @type {number} longitude
	 */
	longitude;
	/**
	 * @type {'Weekly'|'Monthly'} payPeriodType
	 */
	payPeriodType;
	/**
	 * @type {Date} dateFirstPerformanceLogged (date only)
	 */
	dateFirstPerformanceLogged;
	/**
	 * @type {Date} dateLastPerformanceLogged (date only)
	 */
	dateLastPerformanceLogged;
	/**
	 * @type {number} lastFundsIn
	 */
	lastFundsIn;
	/**
	 * @type {number} lastNti
	 */
	lastNti;
	/**
	 * @type {number} ownerCount (integer)
	 */
	ownerCount;
	/**
	 * only returned when calling LocationApi::get -- note, once a currentLicenseNumber is confirmed, that licenseNumber _will_ show up in this array
	 * @type {[string]} previousLicenseNumbers
	 */
	previousLicenseNumbers;
	/**
	 * only returned when calling LocationApi::get
	 * @type {[ReportedVgtMachine]} reportedVgtMachines
	 */
	reportedVgtMachines;
	/**
	 * only returned when calling LocationApi::get
	 * @type {[number]} yearEndReports
	 */
	yearEndReports;
	/**
	 * only returned when NOT part of a LocationGroup, and when calling LocationApi::get() and ::search().  If not part of a LocationGroup, this can be optionally set with owners to setup when calling LocationApi::edit().
	 * @type {[Person]} owners
	 */
	owners;
	/**
	 * can be NULL if not part of a group.  only returned when calling LocationApi::get() and ::search().  This can be optionally set/updated when calling LocationApi::edit().  (.locationGroup.id should be NULL if setting to a NEW locationGroup).  When part of a LocationGroup (either existing or new), the .locationGroup.owners[] can also be optionall set with owners to setup when calling LocationApi::edit()
	 * @type {LocationGroup} locationGroup
	 */
	locationGroup;
	/**
	 * @type {boolean} activeFlag
	 */
	activeFlag;
	/**
	 * @type {Date} dateCreated (date and time)
	 */
	dateCreated;

	/**
	 * Instantiates a new instance of Location based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {Location}
	 */
	static create(genericObject) {
		const newLocation = new Location();
		newLocation.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newLocation;
	}

	/**
	 * Instantiates a new array of Location based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[Location]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newLocationArray = [];
		genericArray.forEach(genericObject => {
			newLocationArray.push(Location.create(genericObject));
		});
		return newLocationArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('id', 'integer'),
	ModelBaseClass.createModelProperty('tenantId', 'integer'),
	ModelBaseClass.createModelProperty('locationGroupId', 'integer'),
	ModelBaseClass.createModelProperty('name', 'string'),
	ModelBaseClass.createModelProperty('currentLicenseNumber', 'string'),
	ModelBaseClass.createModelProperty('licenseNumberConfirmedFlag', 'boolean'),
	ModelBaseClass.createModelProperty('address1', 'string'),
	ModelBaseClass.createModelProperty('address2', 'string'),
	ModelBaseClass.createModelProperty('city', 'string'),
	ModelBaseClass.createModelProperty('countyId', 'integer'),
	ModelBaseClass.createModelProperty('state', 'string'),
	ModelBaseClass.createModelProperty('zip', 'string'),
	ModelBaseClass.createModelProperty('phone', 'string'),
	ModelBaseClass.createModelProperty('latitude', 'float'),
	ModelBaseClass.createModelProperty('longitude', 'float'),
	ModelBaseClass.createModelProperty('payPeriodType', 'string'),
	ModelBaseClass.createModelProperty('dateFirstPerformanceLogged', 'datetime'),
	ModelBaseClass.createModelProperty('dateLastPerformanceLogged', 'datetime'),
	ModelBaseClass.createModelProperty('lastFundsIn', 'float'),
	ModelBaseClass.createModelProperty('lastNti', 'float'),
	ModelBaseClass.createModelProperty('ownerCount', 'integer'),
	ModelBaseClass.createModelProperty('previousLicenseNumbers', '[string]'),
	ModelBaseClass.createModelProperty('reportedVgtMachines', '[ReportedVgtMachine]'),
	ModelBaseClass.createModelProperty('yearEndReports', '[integer]'),
	ModelBaseClass.createModelProperty('owners', '[Person]'),
	ModelBaseClass.createModelProperty('locationGroup', 'LocationGroup'),
	ModelBaseClass.createModelProperty('activeFlag', 'boolean'),
	ModelBaseClass.createModelProperty('dateCreated', 'datetime'),
];

export default LocationBase;
