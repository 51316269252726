import { useCore } from 'contexts/core-context'
import { useGlobal } from 'contexts/global-context'
import { Form, Formik, FormikHelpers } from 'formik'
import useEffectOnce from 'hooks/useEffectOnce'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import routePaths from 'routes/routePaths'
import Client from 'shared/clients/base/Client'
import Person from 'shared/models/Person'
import UpdatePasswordRequest from 'shared/models/UpdatePasswordRequest'
import { phoneSchema } from 'shared/yupSchema'
import {
	Button,
	FormCol,
	FormContainer,
	FormGrid,
	FormikCheckbox,
	FormikText,
	FormSection,
} from 'ui-toolkit-tailwind/src/components'
import { showToast } from 'utilities/showToast'
import * as yup from 'yup'

/** @type {Person & { password: string, confirmPassword: string }} */
const defaultValues = {
	email: '',
	firstName: '',
	lastName: '',
	ownerDetails: {
		phone: '',
		ownerReceivesEmailReportsFlag: false,
	},
	// password fields
	password: '',
	confirmPassword: '',
}

const Registration = () => {
	const navigate = useNavigate()
	const { setShowSpinner } = useGlobal()
	const { updateSession, person, session, executeLogout } = useCore()
	// route params
	const { id, hash, checksum } = useParams()

	const [isUpdateProfileApiFetching, setIsUpdateProfileApiFetching] = useState(false)
	const [updateProfileSuccess, setUpdateProfileSuccess] = useState(false)
	const [isUpdatePasswordApiFetching, setIsUpdatePasswordApiFetching] = useState(false)
	const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false)

	const validationSchema = yup.object({
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		password: yup.string().required(),
		ownerDetails: yup.object({
			phone: phoneSchema.nullable(),
		}),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref('password'), null], 'Passwords must match')
			.required(),
	})

	useEffectOnce(() => {
		executeLogout()
		if (id && hash && checksum) {
			Client.AuthenticationApi.createSessionForAlternativeLoginWorkflow(
				id,
				hash,
				checksum,
				{
					status200: response => updateSession(response),
					status404: () => navigate(routePaths.auth.noPermission, { replace: true }),
				},
				{
					onApiCall: () => setShowSpinner(true),
					onApiResponse: () => setShowSpinner(false),
				},
			)
		}
	})

	// show spinner
	useEffect(() => {
		if (isUpdatePasswordApiFetching || isUpdateProfileApiFetching) setShowSpinner(true)
		else setShowSpinner(false)
	}, [isUpdatePasswordApiFetching, isUpdateProfileApiFetching, setShowSpinner])

	// action after successful registration
	useEffect(() => {
		if (updatePasswordSuccess && updateProfileSuccess) {
			showToast({ intent: 'success', title: 'Registration', content: 'Success!' })
			executeLogout()
			navigate(routePaths.auth.login, { replace: true })
		}
	}, [updateProfileSuccess, updatePasswordSuccess, executeLogout, navigate])

	/**
	 * @param {Person & { password: string }} values
	 * @param {FormikHelpers} FormikHelpers
	 */
	const handleSubmit = (values, { setErrors }) => {
		const { email, firstName, lastName, password, ownerDetails } = values
		const profileRequest = Person.create({
			id: person.id,
			firstName,
			lastName,
			email,
			ownerDetails,
		})

		Client.PersonApi.edit(
			profileRequest,
			{
				status200: response => {
					setUpdateProfileSuccess(true)
					updateSession({
						...session,
						person: response,
					})

					const passwordRequest = UpdatePasswordRequest.create({
						newPassword: password,
						changePasswordChecksum: checksum,
					})
					Client.PersonApi.updatePassword(
						passwordRequest,
						{
							status200: () => setUpdatePasswordSuccess(true),
						},
						{
							onApiCall: () => setIsUpdatePasswordApiFetching(true),
							onApiResponse: () => setIsUpdatePasswordApiFetching(false),
						},
					)
				},
				status403: () => {
					showToast({ intent: 'error', title: 'Registration', content: 'Not authorized' })
					navigate(routePaths.auth.login, { replace: true })
				},
				status409: () => setErrors({ email: 'Email is already used' }),
			},
			{
				onApiCall: () => setIsUpdateProfileApiFetching(true),
				onApiResponse: () => setIsUpdateProfileApiFetching(false),
			},
		)
	}

	return (
		<Formik
			enableReinitialize
			initialValues={
				person
					? {
							...person,
							password: '',
							confirmPassword: '',
					  }
					: defaultValues
			}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			<Form className="mx-auto">
				<FormContainer tighten>
					<FormSection>
						<FormGrid>
							<FormCol span={6}>
								<FormikText name="email" label="Email Address" disabled />
							</FormCol>
						</FormGrid>

						<FormGrid tighten>
							<FormCol span={3}>
								<FormikText name="firstName" label="First Name" />
							</FormCol>
							<FormCol span={3}>
								<FormikText name="lastName" label="Last Name" />
							</FormCol>
						</FormGrid>

						<FormGrid>
							<FormCol span={6}>
								<FormikText name="ownerDetails.phone" label="Phone Number" placeholder="Optional" />
							</FormCol>
						</FormGrid>

						<FormGrid>
							<FormCol span={6} className="my-3">
								<FormikCheckbox
									name="ownerDetails.ownerReceivesEmailReportsFlag"
									label="I want to receive payment cycle email reports"
								/>
							</FormCol>
						</FormGrid>
					</FormSection>

					<FormSection description="Set the password for your account">
						<FormGrid>
							<FormCol>
								<FormikText
									name="password"
									type="password"
									autoComplete="new-password"
									label="Password"
								/>
							</FormCol>
						</FormGrid>
						<FormGrid>
							<FormCol>
								<FormikText
									name="confirmPassword"
									type="password"
									autoComplete="new-password"
									label="Confirm Password"
								/>
							</FormCol>
						</FormGrid>
					</FormSection>

					<Button type="submit" fullWidth>
						Register
					</Button>
				</FormContainer>
			</Form>
		</Formik>
	)
}

export default Registration
