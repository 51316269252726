import LineGraphBase from './base/LineGraphBase';

/**
 * @class LineGraph
 * @extends LineGraphBase
 */
class LineGraph extends LineGraphBase {

}

export default LineGraph;
