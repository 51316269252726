import * as yup from 'yup'

const phoneSchema = yup
	.string()
	.nullable()
	.test('phone', 'Invalid phone number', val => {
		if (!val) return true
		const phoneRegex = new RegExp('^[0-9 x()-]+', 'g')
		if (!phoneRegex.test(val)) return false

		const phoneStrRegex = new RegExp('[^0-9x]', 'g')
		const phoneStr = val.replace(phoneStrRegex, '')
		const parts = phoneStr.split('x')
		if (parts.length > 2) return false
		let [phone, extension] = parts
		if (phone.length < 10 || phone.length > 11) return false
		if (phone.length === 11) {
			if (phone[0] !== '1') return false
			phone = phone.substring(1)
		}
		if (phone.substring(0, 3) === '123' || phone[0] === '0') return false
		return true
	})

export default phoneSchema
