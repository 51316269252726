import { Heading } from 'shared/catalyst-components/heading'

const PageHeader = ({ title }) => {
	return (
		<div className="flex flex-wrap items-end justify-between w-full gap-4 pb-6 lg:hidden">
			<Heading>{title}</Heading>
			{/* <div className="flex gap-4">{actions}</div> */}
		</div>
	)
}

export default PageHeader
