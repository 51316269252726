import UpdatePasswordRequestBase from './base/UpdatePasswordRequestBase';

/**
 * @class UpdatePasswordRequest
 * @extends UpdatePasswordRequestBase
 */
class UpdatePasswordRequest extends UpdatePasswordRequestBase {

}

export default UpdatePasswordRequest;
