import TenantSearchResponseBase from './base/TenantSearchResponseBase';

/**
 * @class TenantSearchResponse
 * @extends TenantSearchResponseBase
 */
class TenantSearchResponse extends TenantSearchResponseBase {

}

export default TenantSearchResponse;
