import { useCore } from 'contexts/core-context'
import { useGlobal } from 'contexts/global-context'
import PageHeader from 'layout/PageHeader'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Client from 'shared/clients/base/Client'
import { Card } from 'shared/components'
import LocationMachines from 'shared/containers/LocationMachines'
import ReportedVgtMachine from 'shared/models/ReportedVgtMachine'
import VgtPerformanceMetric from 'shared/models/VgtPerformanceMetric'
import { downloadMachinePerformanceFile } from 'shared/utilities/downloadFileAssets'

const { Filter, MachineTable, DownloadCsvButton, MachineDoughnutChart, MetricEnums } =
	LocationMachines

const Machines = () => {
	const { setShowSpinner } = useGlobal()
	const { location } = useCore()

	const [machinePerformanceData, setMachinePerformanceData] = useState(
		/** @type {VgtPerformanceMetric[]} */ ([]),
	)

	const [filter, setFilter] = useState(
		/** @type {{date: {from: String, to: String}, metric: keyof typeof MetricEnums }} */ ({
			date: { from: '', to: '' },
			metric: '',
		}),
	)

	useEffect(() => {
		// reset data when location changes
		setMachinePerformanceData([])
	}, [location])

	const fetchSummary = useCallback(() => {
		const { from, to } = filter?.date ?? {}
		if (!from || !to || !location) return

		Client.RevenueApi.getRevenueSummary(
			String(location.id),
			from,
			to,
			{
				status200: response => {
					setMachinePerformanceData(response.perVgtPerformanceMetrics)
				},
			},
			{
				onApiCall: () => setShowSpinner(true),
				onApiResponse: () => setShowSpinner(false),
			},
		)
	}, [filter?.date, location, setShowSpinner])

	useEffect(fetchSummary, [fetchSummary])

	const chartData = useMemo(() => {
		if (!machinePerformanceData || !location) return []

		const machines = location?.reportedVgtMachines || []
		const performanceDataMap = machinePerformanceData.reduce((acc, metric) => {
			acc[metric.reportedVgtMachineId] = metric
			return acc
		}, {})

		return machines
			.map(machine => {
				/** @type {ReportedVgtMachine} */
				const value = performanceDataMap[machine.id]?.[filter.metric]
				return {
					label: machine?.displayName,
					value,
				}
			})
			.filter(v => v.value !== undefined && v.value !== null)
	}, [machinePerformanceData, filter.metric, location])

	const downloadCsv = () => {
		const { from, to } = filter.date
		downloadMachinePerformanceFile(location, from, to)
	}

	return (
		<div>
			<PageHeader title="Machines" />
			<div className="space-y-3 lg:space-y-6">
				<Filter onChange={setFilter} />

				<Card className="flex flex-col-reverse md:flex-col">
					<div className="mt-3 text-center md:-mt-3 lg:-mt-6 md:text-right">
						<DownloadCsvButton onClick={downloadCsv} />
					</div>
					<MachineDoughnutChart data={chartData} />
				</Card>

				<Card>
					<MachineTable data={location?.reportedVgtMachines || []} />
				</Card>
			</div>
		</div>
	)
}

export default Machines
