import GraphMetricBase from './base/GraphMetricBase';

/**
 * @class GraphMetric
 * @extends GraphMetricBase
 */
class GraphMetric extends GraphMetricBase {

}

export default GraphMetric;
