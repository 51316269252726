import RevenueReimbursementSearchResponseBase from './base/RevenueReimbursementSearchResponseBase';

/**
 * @class RevenueReimbursementSearchResponse
 * @extends RevenueReimbursementSearchResponseBase
 */
class RevenueReimbursementSearchResponse extends RevenueReimbursementSearchResponseBase {

}

export default RevenueReimbursementSearchResponse;
