import { useCore } from 'contexts/core-context'
import { useGlobal } from 'contexts/global-context'
import PageHeader from 'layout/PageHeader'
import { useCallback, useEffect, useState } from 'react'
import { Subheading } from 'shared/catalyst-components/heading'
import Client from 'shared/clients/base/Client'
import { Card, DateFilter, PerformanceOverTimeChart, SummaryStatsBlock } from 'shared/components'
import LocationPerformanceDetails from 'shared/containers/LocationPerformanceDetails'
import LineGraph from 'shared/models/LineGraph'
import VgtPerformanceMetric from 'shared/models/VgtPerformanceMetric'
import { displayDate } from 'shared/utilities/helpers'

const { PerformanceTable } = LocationPerformanceDetails

const PerformanceDetails = () => {
	const { setShowSpinner } = useGlobal()
	const { location } = useCore()

	const [dateFilter, setDateFilter] = useState({ from: '', to: '' })

	const [performanceOverTimeData, setPerformanceOverTimeData] = useState(
		/** @type {LineGraph} */ (null),
	)
	const [summaryData, setSummaryData] = useState(/** @type {VgtPerformanceMetric} */ (null))
	const [machinePerformanceData, setMachinePerformanceData] = useState(
		/** @type {VgtPerformanceMetric[]} */ (null),
	)

	const [isSummaryLoading, setIsSummaryLoading] = useState(false)
	const [isPerformanceOverTimeLoading, setIsPerformanceOverTimeLoading] = useState(false)

	const fetchPerformanceOverTime = useCallback(() => {
		if (!dateFilter.from || !dateFilter.to || !location) return
		Client.RevenueApi.getPerformanceOverTime(
			String(location.id),
			dateFilter.from,
			dateFilter.to,
			{
				status200: response => setPerformanceOverTimeData(response.performanceOverTime),
			},
			{
				onApiCall: () => setIsPerformanceOverTimeLoading(true),
				onApiResponse: () => setIsPerformanceOverTimeLoading(false),
			},
		)
	}, [dateFilter.from, dateFilter.to, location])

	useEffect(fetchPerformanceOverTime, [fetchPerformanceOverTime])

	const fetchSummary = useCallback(() => {
		if (!dateFilter.from || !dateFilter.to || !location) return

		Client.RevenueApi.getRevenueSummary(
			String(location.id),
			dateFilter.from,
			dateFilter.to,
			{
				status200: response => {
					setSummaryData(response.totalPerformanceMetric)
					setMachinePerformanceData(response.perVgtPerformanceMetrics)
				},
			},
			{
				onApiCall: () => setIsSummaryLoading(true),
				onApiResponse: () => setIsSummaryLoading(false),
			},
		)
	}, [dateFilter.from, dateFilter.to, location])

	useEffect(fetchSummary, [fetchSummary])

	useEffect(() => {
		if (isPerformanceOverTimeLoading || isSummaryLoading) setShowSpinner(true)
		else setShowSpinner(false)
	}, [isPerformanceOverTimeLoading, isSummaryLoading, setShowSpinner])

	return (
		<div>
			<PageHeader title="Performance Details" />
			<div className="space-y-3 lg:space-y-6">
				<div>
					<DateFilter onChange={setDateFilter} />
				</div>

				<Card>
					<Subheading className="flex flex-col">
						Location Performance
						<label className="text-xs font-normal text-gray-500">
							{displayDate(dateFilter.from)} to {displayDate(dateFilter.to)}
						</label>
					</Subheading>

					{summaryData && <SummaryStatsBlock summaryData={summaryData} border />}
				</Card>

				<Card>
					<PerformanceTable
						machines={location?.reportedVgtMachines || []}
						performanceData={machinePerformanceData}
					/>
				</Card>

				{performanceOverTimeData && (
					<Card>
						<Subheading>Performance Over Time</Subheading>
						<PerformanceOverTimeChart performanceOverTimeData={performanceOverTimeData} />
					</Card>
				)}
			</div>
		</div>
	)
}

export default PerformanceDetails
