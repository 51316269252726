import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { toast } from 'react-hot-toast'

/**
 * The parameters for the showToast function.
 * @typedef {Object} showToastParam
 * @property {'success'|'error'} intent - The intent of the toast notification (success or error).
 * @property {string} title - The title of the toast notification.
 * @property {string} content - The content of the toast notification.
 */

/**
 * Show a global toast notification.
 * @param {showToastParam} options - The options for the toast notification.
 */
export function showToast({ intent = 'success', title, content }) {
	toast.custom(
		t => (
			<Transition
				show={t.visible}
				as={Fragment}
				enter="transform ease-out duration-300 transition"
				enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
				enterTo="translate-y-0 opacity-100 sm:translate-x-0"
				leave="transition ease-in duration-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
					<div className="p-4">
						<div className="flex items-start">
							<div className="flex-shrink-0">
								{intent === 'success' && (
									<CheckCircleIcon className="w-6 h-6 text-success-600" aria-hidden="true" />
								)}
								{intent === 'error' && (
									<ExclamationTriangleIcon className="w-6 h-6 text-danger-600" aria-hidden="true" />
								)}
							</div>
							<div className="ml-3 w-0 flex-1 pt-0.5">
								<p className="text-sm font-medium text-gray-900">{title}</p>
								<p className="mt-1 text-sm text-gray-500">{content}</p>
							</div>
							<div className="flex flex-shrink-0 ml-4">
								<button
									type="button"
									className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									onClick={() => toast.dismiss(t.id)}
								>
									<span className="sr-only">Close</span>
									<XMarkIcon className="w-5 h-5" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		),
		{ position: 'top-right', duration: 10 * 1000 },
	)
}
