import LocationGroupSearchResponseBase from './base/LocationGroupSearchResponseBase';

/**
 * @class LocationGroupSearchResponse
 * @extends LocationGroupSearchResponseBase
 */
class LocationGroupSearchResponse extends LocationGroupSearchResponseBase {

}

export default LocationGroupSearchResponse;
