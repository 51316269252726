import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import PersonSearchRequest from "../PersonSearchRequest";
import ModelProxyClass from "./ModelProxyClass";
import ResultParameter from "../ResultParameter";


/**
 * @class PersonSearchRequestBase
 * @extends ModelBaseClass
 * @property {string} smartFilter
 * @property {number} tenantId (int64) required.  (set to 0 for Manage ETI Users search results)
 * @property {'User'|'Owner'} type required
 * @property {string} includePersonInactiveFlag optional.  rarely used.  (only useful for Manage ETI Users search results and Owners search results)
 * @property {['EtiAdmin'|'EtiStaff'|'ToAdmin'|'RevenueAdmin'|'RevenueStaff']} filterByPermissions optional for User, ignored for Owner
 * @property {string} includeTenantMembershipInactiveFlag optional for User search.  ignored for Owner search.  Usually used instead of includePersonActiveFlag
 * @property {ResultParameter} resultParameter [FirstName, LastName, Email, PersonActiveFlag, TenantMembershipActiveFlag]
 */
class PersonSearchRequestBase extends ModelBaseClass {
	/**
	 * @type {string} smartFilter
	 */
	smartFilter;
	/**
	 * required.  (set to 0 for Manage ETI Users search results)
	 * @type {number} tenantId (int64)
	 */
	tenantId;
	/**
	 * required
	 * @type {'User'|'Owner'} type
	 */
	type;
	/**
	 * optional.  rarely used.  (only useful for Manage ETI Users search results and Owners search results)
	 * @type {string} includePersonInactiveFlag
	 */
	includePersonInactiveFlag;
	/**
	 * optional for User, ignored for Owner
	 * @type {['EtiAdmin'|'EtiStaff'|'ToAdmin'|'RevenueAdmin'|'RevenueStaff']} filterByPermissions
	 */
	filterByPermissions;
	/**
	 * optional for User search.  ignored for Owner search.  Usually used instead of includePersonActiveFlag
	 * @type {string} includeTenantMembershipInactiveFlag
	 */
	includeTenantMembershipInactiveFlag;
	/**
	 * [FirstName, LastName, Email, PersonActiveFlag, TenantMembershipActiveFlag]
	 * @type {ResultParameter} resultParameter
	 */
	resultParameter;

	/**
	 * Instantiates a new instance of PersonSearchRequest based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {PersonSearchRequest}
	 */
	static create(genericObject) {
		const newPersonSearchRequest = new PersonSearchRequest();
		newPersonSearchRequest.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newPersonSearchRequest;
	}

	/**
	 * Instantiates a new array of PersonSearchRequest based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[PersonSearchRequest]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newPersonSearchRequestArray = [];
		genericArray.forEach(genericObject => {
			newPersonSearchRequestArray.push(PersonSearchRequest.create(genericObject));
		});
		return newPersonSearchRequestArray;
	}
}

/**
 * @type {string} OrderByFirstName
 */
PersonSearchRequestBase.OrderByFirstName = 'firstname';

/**
 * @type {string} OrderByLastName
 */
PersonSearchRequestBase.OrderByLastName = 'lastname';

/**
 * @type {string} OrderByEmail
 */
PersonSearchRequestBase.OrderByEmail = 'email';

/**
 * @type {string} OrderByPersonActiveFlag
 */
PersonSearchRequestBase.OrderByPersonActiveFlag = 'personactiveflag';

/**
 * @type {string} OrderByTenantMembershipActiveFlag
 */
PersonSearchRequestBase.OrderByTenantMembershipActiveFlag = 'tenantmembershipactiveflag';

const _modelDefinition = [
	ModelBaseClass.createModelProperty('smartFilter', 'string'),
	ModelBaseClass.createModelProperty('tenantId', 'integer'),
	ModelBaseClass.createModelProperty('type', 'string'),
	ModelBaseClass.createModelProperty('includePersonInactiveFlag', 'string'),
	ModelBaseClass.createModelProperty('filterByPermissions', '[string]'),
	ModelBaseClass.createModelProperty('includeTenantMembershipInactiveFlag', 'string'),
	ModelBaseClass.createModelProperty('resultParameter', 'ResultParameter'),
];

export default PersonSearchRequestBase;
