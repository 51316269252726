import LocationGroupBase from './base/LocationGroupBase';

/**
 * @class LocationGroup
 * @extends LocationGroupBase
 */
class LocationGroup extends LocationGroupBase {

}

export default LocationGroup;
