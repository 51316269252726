import {
	BanknotesIcon,
	ChartBarIcon,
	CurrencyDollarIcon,
	HomeModernIcon,
} from '@heroicons/react/24/outline'
import { cx } from 'class-variance-authority'
import VgtPerformanceMetric from 'shared/models/VgtPerformanceMetric'
import { displayAmount } from 'shared/utilities/helpers'

/**
 * @typedef SummaryStatsBlockProps
 * @property {VgtPerformanceMetric} summaryData
 * @property {boolean} [shadow]
 * @property {boolean} [border]
 */

/**
 * @param {SummaryStatsBlockProps} props
 */
const SummaryStatsBlock = ({ summaryData, shadow, border }) => {
	const { fundsIn, netTerminalIncome, locationRevenue, amountPlayed } = summaryData ?? {}

	const stats = [
		{ name: 'Funds In', value: fundsIn, icon: CurrencyDollarIcon },
		{ name: 'Amount Played', value: amountPlayed, icon: ChartBarIcon },
		{ name: 'Net Terminal Income', value: netTerminalIncome, icon: BanknotesIcon },
		{ name: 'Location Revenue', value: locationRevenue, icon: HomeModernIcon },
	]

	return (
		<dl
			className={cx(
				'grid grid-cols-1 mt-5 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg md:grid-cols-4 md:divide-x md:divide-y-0',
				shadow && 'shadow',
				border && 'border',
			)}
		>
			{stats.map(item => (
				<div key={item.name} className="px-4 py-5 sm:p-6">
					<dt className="text-base font-normal text-gray-500 sm:text-xs lg:text-base">
						{item.name}
					</dt>
					<dd className="relative flex items-baseline justify-between mt-1 md:block lg:flex">
						<div className="flex items-baseline text-2xl font-semibold sm:text-xl lg:text-2xl text-primary-600">
							{displayAmount(item.value)}
						</div>
						<div>
							<item.icon className="absolute bottom-0 right-0 opacity-50 size-12 lg:size-16 text-primary-400" />
						</div>
					</dd>
				</div>
			))}
		</dl>
	)
}

export default SummaryStatsBlock
