import LocationBase from './base/LocationBase';

/**
 * @class Location
 * @extends LocationBase
 */
class Location extends LocationBase {
  getFullAddress() {
    return [this.address1, this.address2].filter(Boolean).join(' ') + `, ${this.city}, ${this.zip}`
  }
}

export default Location;
