import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import RevenueSummaryResponse from "../RevenueSummaryResponse";
import ModelProxyClass from "./ModelProxyClass";
import VgtPerformanceMetric from "../VgtPerformanceMetric";


/**
 * @class RevenueSummaryResponseBase
 * @extends ModelBaseClass
 * @property {VgtPerformanceMetric} totalPerformanceMetric this is the aggregate performance metric across all VGTs (across all Location(s)) during this entire time period
 * @property {[VgtPerformanceMetric]} perDayPerformanceMetrics this is the aggregate performance metrics PER DAY across all VGTs (across all Location(s)) for each day of the time period
 * @property {[VgtPerformanceMetric]} perVgtPerformanceMetrics if querying for a *single* location, then this is the aggregate performance metrics across each VGT during this entire time period.  (Otherwise this is null/undefined)
 */
class RevenueSummaryResponseBase extends ModelBaseClass {
	/**
	 * this is the aggregate performance metric across all VGTs (across all Location(s)) during this entire time period
	 * @type {VgtPerformanceMetric} totalPerformanceMetric
	 */
	totalPerformanceMetric;
	/**
	 * this is the aggregate performance metrics PER DAY across all VGTs (across all Location(s)) for each day of the time period
	 * @type {[VgtPerformanceMetric]} perDayPerformanceMetrics
	 */
	perDayPerformanceMetrics;
	/**
	 * if querying for a *single* location, then this is the aggregate performance metrics across each VGT during this entire time period.  (Otherwise this is null/undefined)
	 * @type {[VgtPerformanceMetric]} perVgtPerformanceMetrics
	 */
	perVgtPerformanceMetrics;

	/**
	 * Instantiates a new instance of RevenueSummaryResponse based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {RevenueSummaryResponse}
	 */
	static create(genericObject) {
		const newRevenueSummaryResponse = new RevenueSummaryResponse();
		newRevenueSummaryResponse.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newRevenueSummaryResponse;
	}

	/**
	 * Instantiates a new array of RevenueSummaryResponse based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[RevenueSummaryResponse]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newRevenueSummaryResponseArray = [];
		genericArray.forEach(genericObject => {
			newRevenueSummaryResponseArray.push(RevenueSummaryResponse.create(genericObject));
		});
		return newRevenueSummaryResponseArray;
	}
}

const _modelDefinition = [
	ModelBaseClass.createModelProperty('totalPerformanceMetric', 'VgtPerformanceMetric'),
	ModelBaseClass.createModelProperty('perDayPerformanceMetrics', '[VgtPerformanceMetric]'),
	ModelBaseClass.createModelProperty('perVgtPerformanceMetrics', '[VgtPerformanceMetric]'),
];

export default RevenueSummaryResponseBase;
