import ModelBaseClass from "@quasidea/oas-client-react/lib/ModelBaseClass";
import RevenuePaymentSearchRequest from "../RevenuePaymentSearchRequest";
import ModelProxyClass from "./ModelProxyClass";
import ResultParameter from "../ResultParameter";


/**
 * @class RevenuePaymentSearchRequestBase
 * @extends ModelBaseClass
 * @property {number} locationId (int64) either locationId or payPeriodId is required.
 * @property {number} payPeriodId (int64) either locationId or payPeriodId is required.  Only Operators are allowed to specify payPeriodId.
 * @property {ResultParameter} resultParameter [DatePeriodStart, LocationName, SubtotalRevenue, SubtotalReimbursements, TotalAmount, DatePaid]
 */
class RevenuePaymentSearchRequestBase extends ModelBaseClass {
	/**
	 * either locationId or payPeriodId is required.
	 * @type {number} locationId (int64)
	 */
	locationId;
	/**
	 * either locationId or payPeriodId is required.  Only Operators are allowed to specify payPeriodId.
	 * @type {number} payPeriodId (int64)
	 */
	payPeriodId;
	/**
	 * [DatePeriodStart, LocationName, SubtotalRevenue, SubtotalReimbursements, TotalAmount, DatePaid]
	 * @type {ResultParameter} resultParameter
	 */
	resultParameter;

	/**
	 * Instantiates a new instance of RevenuePaymentSearchRequest based on the generic object being passed in (typically from a JSON object)
	 * @param {object} genericObject
	 * @return {RevenuePaymentSearchRequest}
	 */
	static create(genericObject) {
		const newRevenuePaymentSearchRequest = new RevenuePaymentSearchRequest();
		newRevenuePaymentSearchRequest.instantiate(_modelDefinition, genericObject, ModelProxyClass.createByClassName);
		return newRevenuePaymentSearchRequest;
	}

	/**
	 * Instantiates a new array of RevenuePaymentSearchRequest based on the generic array being passed in (typically from a JSON array)
	 * @param {[object]} genericArray
	 * @return {[RevenuePaymentSearchRequest]}
	 */
	static createArray(genericArray) {
		if (genericArray === null) {
			return null;
		}

		const newRevenuePaymentSearchRequestArray = [];
		genericArray.forEach(genericObject => {
			newRevenuePaymentSearchRequestArray.push(RevenuePaymentSearchRequest.create(genericObject));
		});
		return newRevenuePaymentSearchRequestArray;
	}
}

/**
 * @type {string} OrderByDatePeriodStart
 */
RevenuePaymentSearchRequestBase.OrderByDatePeriodStart = 'dateperiodstart';

/**
 * @type {string} OrderByLocationName
 */
RevenuePaymentSearchRequestBase.OrderByLocationName = 'locationname';

/**
 * @type {string} OrderBySubtotalRevenue
 */
RevenuePaymentSearchRequestBase.OrderBySubtotalRevenue = 'subtotalrevenue';

/**
 * @type {string} OrderBySubtotalReimbursements
 */
RevenuePaymentSearchRequestBase.OrderBySubtotalReimbursements = 'subtotalreimbursements';

/**
 * @type {string} OrderByTotalAmount
 */
RevenuePaymentSearchRequestBase.OrderByTotalAmount = 'totalamount';

/**
 * @type {string} OrderByDatePaid
 */
RevenuePaymentSearchRequestBase.OrderByDatePaid = 'datepaid';

const _modelDefinition = [
	ModelBaseClass.createModelProperty('locationId', 'integer'),
	ModelBaseClass.createModelProperty('payPeriodId', 'integer'),
	ModelBaseClass.createModelProperty('resultParameter', 'ResultParameter'),
];

export default RevenuePaymentSearchRequestBase;
