import ReimbursementBase from './base/ReimbursementBase';

/**
 * @class Reimbursement
 * @extends ReimbursementBase
 */
class Reimbursement extends ReimbursementBase {

}

export default Reimbursement;
