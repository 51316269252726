import IgbModelSearchResponseBase from './base/IgbModelSearchResponseBase';

/**
 * @class IgbModelSearchResponse
 * @extends IgbModelSearchResponseBase
 */
class IgbModelSearchResponse extends IgbModelSearchResponseBase {

}

export default IgbModelSearchResponse;
