import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { Button } from 'shared/catalyst-components/button'
import { ActionButton, ButtonTooltip, ReimbursementStatusBadge } from 'shared/components'
import ReimbursementStatusEnum from 'shared/models/enum/ReimbursementStatusEnum'
import TenantModulesEnum from 'shared/models/enum/TenantModulesEnum'
import Reimbursement from 'shared/models/Reimbursement'
import { displayAmount, displayDate } from 'shared/utilities/helpers'
import { Table } from 'ui-toolkit-tailwind/src/components'

/**
 * @typedef ReimbursementTableProps
 * @property {Reimbursement[]} data
 * @property {(reimbursement: Reimbursement) => void} onReimbursementClick
 * @property {boolean} [isRevenueAdmin] some actions are only available to revenue admins
 */

/**
 * @param {ReimbursementTableProps} props
 */
const ReimbursementTable = ({ data, onReimbursementClick, isRevenueAdmin }) => {
	const isOwnerPortal = process.env.REACT_APP_MODULE === TenantModulesEnum.OWNERS_PORTAL
	const isRevenueModule = process.env.REACT_APP_MODULE === TenantModulesEnum.REVENUE

	/**
	 * @param {Reimbursement} reimbursement
	 */
	const renderActionCol = reimbursement => {
		const { status } = reimbursement
		if (isOwnerPortal) {
			if ([ReimbursementStatusEnum.REQUESTED, ReimbursementStatusEnum.DECLINED].includes(status)) {
				return (
					<ActionButton.Edit
						tooltipMessage="Edit"
						onClick={() => onReimbursementClick(reimbursement)}
					/>
				)
			}
			if ([ReimbursementStatusEnum.APPROVED, ReimbursementStatusEnum.PAID].includes(status)) {
				return (
					<ActionButton.View
						tooltipMessage="View"
						onClick={() => onReimbursementClick(reimbursement)}
					/>
				)
			}
		}

		if (isRevenueModule) {
			// only revenue admins can do actions
			if (!isRevenueAdmin) return null

			if (
				[
					ReimbursementStatusEnum.APPROVED,
					ReimbursementStatusEnum.DECLINED,
					ReimbursementStatusEnum.PAID,
				].includes(status)
			) {
				return (
					<ActionButton.View
						tooltipMessage="View"
						onClick={() => onReimbursementClick(reimbursement)}
					/>
				)
			}
			if (status === ReimbursementStatusEnum.REQUESTED) {
				return (
					<ButtonTooltip message="Review">
						<Button plain onClick={() => onReimbursementClick(reimbursement)}>
							<PencilSquareIcon />
						</Button>
					</ButtonTooltip>
				)
			}
		}

		return null
	}

	return (
		<Table
			data={data}
			columns={[
				// hide it when it's revenue module and not revenue admin
				...(!(isRevenueModule && !isRevenueAdmin)
					? [{ rowData: true, headerClassName: 'w-[40px]', formatter: renderActionCol }]
					: []),
				{
					name: 'dateRequested',
					label: 'Date Requested',
					formatter: date => (date ? displayDate(date) : '-'),
				},
				{ name: 'description', label: 'Description' },
				{
					name: 'status',
					label: 'Status',
					formatter: status => <ReimbursementStatusBadge status={status} />,
				},
				{ name: 'amount', label: 'Amount', formatter: displayAmount },
				{
					name: 'dateEvaluated',
					label: 'Date Approved / Declined',
					formatter: date => (date ? displayDate(date) : '-'),
				},
				{
					name: 'datePaid',
					label: 'Date Paid',
					formatter: date => (date ? displayDate(date) : '-'),
				},
			]}
		/>
	)
}

export default ReimbursementTable
