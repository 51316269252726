import { cx } from 'class-variance-authority'
import { Form as FormikForm } from 'formik'
import { Link, Outlet, useLocation } from 'react-router-dom'
import PageHeader from '../PageHeader'

/**
 * @typedef SettingsProps
 * @property {Array<{name: string, path: string, icon: React.ElementType}>} navItems
 */

/**
 * @param {SettingsProps} props
 */
const SettingPageLayout = ({ navItems }) => {
	const { pathname } = useLocation()

	return (
		<div>
			<PageHeader title="Settings" />

			{/* Nav bar */}
			<header className="-mt-3 border-b md:-mt-6 border-primary/5">
				<nav className="flex py-2 overflow-x-auto">
					<ul className="flex flex-none min-w-full text-sm font-semibold leading-6 text-gray-400 whitespace-nowrap gap-x-6">
						{navItems?.length > 1 &&
							navItems.map((item, index) => (
								<li key={item.name}>
									<Link
										to={item.path}
										className={cx(
											'flex py-2 pl-2 pr-3 leading-6 rounded-md hover:text-primary-600 group gap-x-1 items-center',
											pathname === item.path ? 'text-primary-700' : '',
											index === 0 && 'pl-0',
										)}
									>
										{item.icon && <item.icon className="w-4 h-4 shrink-0" aria-hidden="true" />}
										{item.name}
									</Link>
								</li>
							))}
					</ul>
				</nav>
			</header>

			<div className="divide-y divide-white/5">
				<Outlet />
			</div>
		</div>
	)
}

const Section = ({ title, subTitle, children }) => {
	return (
		<div className="grid grid-cols-1 px-4 py-3 md:py-8 lg:py-16 max-w-7xl gap-x-8 gap-y-10 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7">{title}</h2>
				<p className="mt-1 text-sm leading-6 text-gray-500">{subTitle}</p>
			</div>
			<div className="md:col-span-2">{children}</div>
		</div>
	)
}

const Form = ({ children }) => (
	<FormikForm className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
		{children}
	</FormikForm>
)

/**
 * @typedef ColProps
 * @property {1 | 2 | 3 | 4 | 5 | 6} [colSpan]
 * @property {string} [className]
 */

/**
 * @param {ColProps} props
 */
const Col = ({ colSpan = 6, className, children }) => {
	return (
		<div
			className={cx(
				colSpan === 1 && 'sm:col-span-1',
				colSpan === 2 && 'sm:col-span-2',
				colSpan === 3 && 'sm:col-span-3',
				colSpan === 4 && 'sm:col-span-4',
				colSpan === 5 && 'sm:col-span-5',
				colSpan === 6 && 'sm:col-span-6',
				className,
			)}
		>
			{children}
		</div>
	)
}

const Actions = ({ children }) => <Col className="flex justify-end gap-1">{children}</Col>

SettingPageLayout.Section = Section
SettingPageLayout.Form = Form
SettingPageLayout.Col = Col
SettingPageLayout.Actions = Actions

export default SettingPageLayout
