import UserDetailsBase from './base/UserDetailsBase';

export const ETI_TENANT_ID = 0

/**
 * @class UserDetails
 * @extends UserDetailsBase
 */
class UserDetails extends UserDetailsBase {
	getEtiTenantMembership() {
		return this.tenantMemberships.find(tenantMembership => tenantMembership.tenant.id === ETI_TENANT_ID)
	}
}

export default UserDetails;
