// import Client from 'clients/base/Client'
import localStorageKeys from 'constants/localStorageKeys'
import { useCore } from 'contexts/core-context'
import { useGlobal } from 'contexts/global-context'
import { Form, Formik, FormikHelpers } from 'formik'
import useLocalStorage from 'hooks/useLocalStorage'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import routePaths from 'routes/routePaths'
import Client from 'shared/clients/base/Client'
import LoginRequest from 'shared/models/LoginRequest'
import { Button, Checkbox, FormikText } from 'ui-toolkit-tailwind/src/components'
import * as yup from 'yup'

const Login = () => {
	const navigate = useNavigate()
	const { state } = useLocation()
	const { updateSession, tenant } = useCore()
	const { setShowSpinner } = useGlobal()
	const [storedEmail, setStoredEmail, resetStoredEmail] = useLocalStorage(
		localStorageKeys.storedEmail,
	)

	const [rememberMe, setRememberMe] = useState(!!storedEmail)

	/**
	 * @param {LoginRequest} values
	 * @param {FormikHelpers} formikHelpers
	 */
	const handleLoginSubmit = ({ email, password }, { setErrors }) => {
		/** @type {Location | null} */
		const from = state?.from

		const request = LoginRequest.create({ email, password, tenantId: tenant?.id })
		Client.AuthenticationApi.ownerLogin(
			request,
			{
				status200: session => {
					// update stored email
					if (rememberMe) setStoredEmail(email)
					else resetStoredEmail()
					updateSession(session)
					navigate(routePaths.dashboard, { replace: true })
				},
				status404: () => {
					setErrors({
						email: ' ',
						password: 'You have entered an invalid username or password',
					})
				},
			},
			{
				onApiCall: () => setShowSpinner(true),
				onApiResponse: () => setShowSpinner(false),
			},
		)
	}

	return (
		<>
			<Formik
				initialValues={{
					email: storedEmail || '',
					password: '',
				}}
				validationSchema={yup.object({
					email: yup.string().email('Invalid email').required(),
					password: yup.string().required(),
				})}
				onSubmit={handleLoginSubmit}
			>
				<Form className="space-y-6">
					<FormikText
						name="email"
						id="email"
						type="email"
						autoComplete="username"
						label="Email Address"
					/>
					<FormikText
						name="password"
						id="password"
						type="password"
						autoComplete="current-password"
						label="Password"
						cornerHint={
							<button
								type="button"
								onClick={() => navigate(routePaths.auth.forgotPassword)}
								className="font-semibold cursor-pointer text-primary-600 hover:text-primary-500 focus:ring-0"
							>
								Forgot password?
							</button>
						}
					/>

					<Checkbox
						name="rememberMe"
						className="!mt-3"
						label="Remember Me"
						checked={rememberMe}
						onChange={e => setRememberMe(e.target.checked)}
					/>

					<Button type="submit" fullWidth>
						Sign in
					</Button>
				</Form>
			</Formik>

			{/* <p className="mt-10 text-sm text-center text-gray-500">
				Don&apos;t have an account?{' '}
				<Link
					to={routePaths.auth.requestRegistration}
					className="font-semibold leading-6 text-primary-600 hover:text-primary-500"
				>
					Register here
				</Link>
			</p> */}
		</>
	)
}

export default Login
