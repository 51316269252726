import * as yup from 'yup'

const zipCodeSchema = yup
	.number()
	.typeError('Must be a number')
	.test(
		'is-digits-only',
		'Must be a number with exactly 5 digits',
		value => value && /^\d{5}$/.test(value),
	)

export default zipCodeSchema
