import ClientBaseClass from "./ClientBaseClass";
import Session from "../../models/Session";

export default class AuthenticationApi extends ClientBaseClass {
	/**
	 * Retrieves the session object associated with the specified sessionToken.  (This will rarely need to be used.)
	 * @param {'eti'|'system_management'|'to_management'|'revenue'|'owners_portal'} module
	 * @param {string} sessionIdentifier
	 * @param {{status200: function(Session), status403: function(string), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	getSession(module, sessionIdentifier, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/authentication/session/' +
			(module ? encodeURI(module) : '') + '/' +
			(sessionIdentifier ? encodeURI(sessionIdentifier) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(Session.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 403:
					if (responseHandler.status403) {
						response.text()
							.then(responseText => {
								responseHandler.status403(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [UserPerson] Allows any module to check the central ETI API to see if this user is already authenticated on to ETI, and if so, it will check to see if they are authorized for the requested module.  It will return the module-specific session if the user is authenticated and authorized.  In general, if 401, then app should redirect the user to the ETI login screen.  NOT to be used for Owners module.
	 * @param {'eti'|'system_management'|'to_management'|'revenue'} module
	 * @param {{status200: function(Session), status403: function(string), status404: function(string), status409: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	userGetSessionForModule(module, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/authentication/session/' +
			(module ? encodeURI(module) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(Session.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 403:
					if (responseHandler.status403) {
						response.text()
							.then(responseText => {
								responseHandler.status403(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 409:
					if (responseHandler.status409) {
						response.text()
							.then(responseText => {
								responseHandler.status409(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [UserPerson] Logs user into the Root module (for User-based persons, NOT to be used for Owners).  tenantId is ignored.  Tenant will be auto-selected if User is only associated with one tenant.  If user is associated with more than one tenant, no tenant will be selected.  Caller will be expected to call userSetTenant() next.
	 * @param {LoginRequest} loginrequest
	 * @param {{status200: function(Session), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	userLogin(loginrequest, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/authentication/login/user';

		// noinspection Duplicates
		this.executeApiCall(url, 'post', loginrequest, 'json', options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(Session.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [OwnerPerson] Logs user into the Owners module (for Owner-based persons, NOT to be used for User-based persons).  tenantId must be specified.
	 * @param {LoginRequest} loginrequest
	 * @param {{status200: function(Session), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	ownerLogin(loginrequest, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/authentication/login/owner';

		// noinspection Duplicates
		this.executeApiCall(url, 'post', loginrequest, 'json', options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(Session.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [ETI Module, UserPerson] Switches an existing tenant on the current session to a different tenant, assuming this UserPerson has access to it.  Can only be used for User-based persons.  NOT to be used for Owners module.
	 * @param {string} tenantId
	 * @param {{status200: function(Session), status401: function(string), status403: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	userSetTenantId(tenantId, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/authentication/switch_tenant/' +
			(tenantId ? encodeURI(tenantId) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(Session.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 401:
					if (responseHandler.status401) {
						response.text()
							.then(responseText => {
								responseHandler.status401(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 403:
					if (responseHandler.status403) {
						response.text()
							.then(responseText => {
								responseHandler.status403(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * Logs the user out.  If logging out of a User-based module, this will also log the user out of the root session (if applicable)
	 * @param {{status200: function(string), status403: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	logout(responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/authentication/logout';

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.text()
							.then(responseText => {
								responseHandler.status200(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 403:
					if (responseHandler.status403) {
						response.text()
							.then(responseText => {
								responseHandler.status403(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [UserPerson] Attempts to trigger a Forgot / Reset Password workflow.  IF the Email exists, this will send out an email with instructions on how to reset their password.  Otherwise, this is a no-op.  Either way, this respond 200, so that this cannot be used to try and reverse-engineer for email address registrations. (Only for User-based Persons.  tenantId is ignored.)
	 * @param {ForgotPasswordRequest} request
	 * @param {{status200: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	userForgotPassword(request, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/authentication/forgot_password/user';

		// noinspection Duplicates
		this.executeApiCall(url, 'post', request, 'json', options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.text()
							.then(responseText => {
								responseHandler.status200(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * [OwnerPerson] Attempts to trigger a Forgot / Reset Password workflow.  IF the Email exists, this will send out an email with instructions on how to reset their password.  Otherwise, this is a no-op.  Either way, this respond 200, so that this cannot be used to try and reverse-engineer for email address registrations. (Only for Owner-based Persons.  tenantId is required.)
	 * @param {ForgotPasswordRequest} request
	 * @param {{status200: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	ownerForgotPassword(request, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/authentication/forgot_password/owner';

		// noinspection Duplicates
		this.executeApiCall(url, 'post', request, 'json', options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.text()
							.then(responseText => {
								responseHandler.status200(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

	/**
	 * Creates a NEW session object for the alternative login workflow (e.g. Forgot Password or Welcome).  This ID, HASH and CHECKSUM are generally created by the server, and sent via email to the user in a specially formulated link.
	 * @param {string} id
	 * @param {string} hash
	 * @param {string} checksum
	 * @param {{status200: function(Session), status404: function(string), error: function(error), else: function(integer, string)}} responseHandler
	 * @param {ClientOptions|null} options optional overrides on the DefaultClientOptions
	 */
	createSessionForAlternativeLoginWorkflow(id, hash, checksum, responseHandler, options) {
		responseHandler = this.generateResponseHandler(responseHandler, options);

		const url = '/authentication/create_session/' +
			(id ? encodeURI(id) : '') + '/' +
			(hash ? encodeURI(hash) : '') + '/' +
			(checksum ? encodeURI(checksum) : '');

		// noinspection Duplicates
		this.executeApiCall(url, 'get', null, null, options)
			.then(response => {
				switch (response.status) {
				case 200:
					if (responseHandler.status200) {
						response.json()
							.then(responseJson => {
								responseHandler.status200(Session.create(responseJson));
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				case 404:
					if (responseHandler.status404) {
						response.text()
							.then(responseText => {
								responseHandler.status404(responseText);
							})
							.catch(responseHandler.error);
						return;
					}
					break;
				}

				// If we are here, we basically have a response statusCode that we were npt expecting or are not set to handle
				// Go ahead and fall back to the catch-all
				this.handleUnhandledResponse(response, responseHandler);
			})
			.catch(error => {
				responseHandler.error(error);
			});
	}

}
