import dayjs from 'dayjs'
import DefaultClientOptions from 'shared/clients/DefaultClientOptions'
import Payment from 'shared/models/Payment'
import PayPeriod from 'shared/models/PayPeriod'
import downloadFile from 'shared/utilities/downloadFile'

const getSessionToken = () => {
	const sessionString = localStorage.getItem('session')
	const session = sessionString ? JSON.parse(sessionString) : null
	return session ? `${session.id}-${session.hash}` : ''
}

/**
 * @param {Payment} payment
 */
export function downloadPaymentFile(payment) {
	const sessionToken = getSessionToken()
	if (!payment || !sessionToken) return

	const { dateStart, dateEnd } = payment?.payPeriod

	const filename = `payment_${dayjs(dateStart).format('MM-DD-YYYY')}_${dayjs(dateEnd).format(
		'MM-DD-YYYY',
	)}.pdf`
	const url = `${DefaultClientOptions.getEndpointUrl()}/file_asset/pdf/payment/${
		payment.id
	}/${sessionToken}/${filename}`
	downloadFile(url, filename)
}

/**
 * @param {PayPeriod} payPeriod
 */
export function downloadPayPeriodFile(payPeriod) {
	const sessionToken = getSessionToken()
	if (!payPeriod || !sessionToken) return

	const { dateStart, dateEnd } = payPeriod

	const filename = `pay_period_${dayjs(dateStart).format('MM-DD-YYYY')}_${dayjs(dateEnd).format(
		'MM-DD-YYYY',
	)}.csv`
	const url = `${DefaultClientOptions.getEndpointUrl()}/file_asset/csv/pay_period/${
		payPeriod.id
	}/${sessionToken}/${filename}`
	downloadFile(url, filename)
}

/**
 * @param {Location} location
 * @param {string} from
 * @param {string} to
 */
export function downloadMachinePerformanceFile(location, from, to) {
	const sessionToken = getSessionToken()
	if (!location || !sessionToken) return

	const stringFrom = dayjs(from).format('YYYY-MM-DD')
	const stringTo = dayjs(to).format('YYYY-MM-DD')
	const filename = `machine_performance_${stringFrom}_${stringTo}.csv`
	const url = `${DefaultClientOptions.getEndpointUrl()}/file_asset/csv/vgt_performance/${
		location.id
	}/${stringFrom}/${stringTo}/${sessionToken}/${filename}`
	downloadFile(url, filename)
}

/**
 * @param {number} locationId
 * @param {string} year
 */
export function downloadYearEndFile(locationId, year) {
	const sessionToken = getSessionToken()
	if (!locationId || !sessionToken) return

	const filename = `year_end_statement_${year}.pdf`
	const url = `${DefaultClientOptions.getEndpointUrl()}/file_asset/pdf/year_end/${locationId}/${year}/${sessionToken}/${filename}`
	downloadFile(url, filename)
}
