import { FingerPrintIcon, UserCircleIcon } from '@heroicons/react/16/solid'
import { useCore } from 'contexts/core-context'
import Layout from 'layout'
import LogoLayout from 'layout/LogoLayout'
import ChangePassword from 'pages/ChangePassword'
import Dashboard from 'pages/Dashboard'
import Financials from 'pages/Financials'
import ForgotPassword from 'pages/ForgotPassword'
import Login from 'pages/Login'
import Machines from 'pages/Machines'
import PerformanceDetails from 'pages/PerformanceDetails'
import Registration from 'pages/Registration'
import Reimbursements from 'pages/Reimbursements'
import Password from 'pages/Settings/Password'
import Profile from 'pages/Settings/Profile'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import routePaths from 'routes/routePaths'
import { Card } from 'shared/components'
import SettingPageLayout from 'shared/layout/SettingPageLayout'
import PageNoPermission from 'shared/pages/PageNoPermission'
import PageNotFound from 'shared/pages/PageNotFound'

/**
 * react-router-dom v6.4
 * @see https://reactrouter.com/en/main
 */
const AppRoutes = () => {
	const settingsNavItems = [
		{
			name: 'Profile',
			path: routePaths.settings.profile,
			icon: UserCircleIcon,
		},
		{
			name: 'Password',
			path: routePaths.settings.password,
			icon: FingerPrintIcon,
		},
	]

	return (
		<Routes>
			<Route path="/" element={<Navigate to={routePaths.dashboard} replace />} />
			<Route path="*" element={<PageNotFound />} />

			{/* === unauthenticated routes === */}

			<Route
				path={routePaths.auth.login}
				element={
					<LogoLayout title="Sign in to your account">
						<Login />
					</LogoLayout>
				}
			/>

			<Route
				path={routePaths.auth.forgotPassword}
				element={
					<LogoLayout title="Forgot Password">
						<ForgotPassword />
					</LogoLayout>
				}
			/>

			<Route
				path={routePaths.auth.changePassword}
				element={
					<LogoLayout title="Change Password">
						<ChangePassword />
					</LogoLayout>
				}
			/>

			<Route
				path={routePaths.auth.registration}
				element={
					<LogoLayout title="New User Registration">
						<Registration />
					</LogoLayout>
				}
			/>

			{/* === authenticated routes === */}

			<Route
				element={
					<AuthRoute>
						<Layout />
					</AuthRoute>
				}
			>
				<Route
					path={routePaths.settings.base}
					element={
						<Card>
							<SettingPageLayout navItems={settingsNavItems} />
						</Card>
					}
				>
					<Route index element={<Navigate to={routePaths.settings.profile} replace />} />
					<Route path={routePaths.settings.profile} element={<Profile />} />
					<Route path={routePaths.settings.password} element={<Password />} />
				</Route>

				<Route path={routePaths.dashboard} element={<Dashboard />} />
				<Route path={routePaths.financials} element={<Financials />} />
				<Route path={routePaths.reimbursements} element={<Reimbursements />} />
				<Route path={routePaths.performanceDetails} element={<PerformanceDetails />} />
				<Route path={routePaths.machines} element={<Machines />} />
			</Route>

			<Route path={routePaths.auth.noPermission} element={<PageNoPermission />} />
		</Routes>
	)
}

// auth-required routes wrapper
const AuthRoute = ({ children }) => {
	const { isLoggedIn } = useCore()
	const location = useLocation()

	if (!isLoggedIn) {
		return <Navigate to={routePaths.auth.login} state={{ from: location }} replace />
	}

	return children
}

export default AppRoutes
